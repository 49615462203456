// Core
import { useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const AttentionModal = ({handle, isOpen, onOpen, onClose, variant}) => {
  return (
    	<> 
        { variant === 'remove' && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="modal__simple" maxW={{ sm: '288px' }}>
            <ModalHeader display="flex" alignItems="left" justifyContent="left">Attention</ModalHeader>
              <Text variant="text-md" mt="4px" mb="12px">Important Notice</Text>
              <ModalCloseButton />
              <ModalBody>
                <Text mt="4px" variant="text-md">Are you sure of your choice? Once deleted, it will be impossible to return the object to the active list.</Text>
                <Button w="100%" mt="24px" variant="submit-md" onClick={() => { handle(); onClose(); }}>
                  Confirm action
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal> 
        )}

        { variant === 'sessions' && (
        	<Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="modal__simple" maxW={{ sm: '288px' }}>
            <ModalHeader display="flex" alignItems="left" justifyContent="left">Attention</ModalHeader>
              <Text variant="text-md" mt="4px" mb="12px">Important Notice</Text>
              <ModalCloseButton />
              <ModalBody>
                <Text mt="4px" variant="text-md">Are you sure of your choice? Once deleted, it will not be possible to return the device to the active list.</Text>
                <Button w="100%" mt="24px" variant="submit-md" onClick={handle}>
                  Confirm action
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal> 
        )}

        { variant === 'twoFA' && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="modal__simple" maxW={{ sm: '445px' }}>
              <ModalCloseButton />
              <Text variant="title" fontSize="24px">Two-Factor Authentication</Text>
              <Text variant="text-md" mt="4px" >Important Notice</Text>
              <ModalBody>
                <Text mt="12px" variant="text-md" fontWeight="600">Are you sure you want to disable two-factor authentication?</Text>
                <Text mt="4px" variant="text-md">This could be a bit risky, but we won&apos;t judge.</Text>
                <Flex justifyContent="flex-end">
                  <Button maxW="164px" mt="24px" variant="submit-md" onClick={() => { handle(); onClose(); }}>
                    Yes, Disable
                  </Button>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal> 
        )}
      </>
    
  );
};

export default AttentionModal;
