// Chakra imports
import { 
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Divider,
  Text,
  useColorModeValue,
  Flex
} from '@chakra-ui/react';

function Breadcrumbs(props) {
  const {target, url, subTarget, subUrl, current, icon: Icon, variant} = props;

  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'blue.900');

  let mt = '-124px';
  let pt = '10px';

  if(variant === 'standart'){
    mt = '9px';
  } 

  return (
    <Box bg={bgColor} mt={mt} pt={pt}>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href={url}>
            <Flex alignItems="center">
              <Icon color='gray.500' />
              <span style={{ marginLeft: '8px' }}>{target}</span>
            </Flex>
          </BreadcrumbLink>
        </BreadcrumbItem>

        { subTarget && (
          <BreadcrumbItem>
            <BreadcrumbLink href={url + '/' + subUrl}>
              <Flex alignItems="center">
                <span style={{ marginLeft: '8px', paddingLeft: '0px' }}>{subTarget}</span>
              </Flex>
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}

        <BreadcrumbItem isCurrentPage className="active">
          <BreadcrumbLink href='#'>{current}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='0' >{current}</Text>
      <Divider opacity="1" mr="2px" borderColor='rgba(255, 255, 255, 0.10)' borderTopWidth="1px" borderBottomWidth="0" mb='32px'/>
    </Box>
  );
}

export default Breadcrumbs;