import { mode } from '@chakra-ui/theme-tools';

export const linkStyles = {
  components: {
    Link: {
      decoration: 'none',
      baseStyle: {
        color: 'blue.200',
        transition: '0.3s all',
        fontSize: '16px',
        fontWeight: '600',
        _hover: {
          textDecoration: 'underline'
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      variants: {
        'standart': (props) => ({
          color: mode('blue.800', 'gray.50')(props),
          fontWeight: '400',
        })
      }
    },
  },
};
