// Core
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';

// Chakra Imports
import {
  Avatar,
  Box,
  Badge,
  Button,
  Divider,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Assets
import avatarDefault from 'assets/svg/avatars/gradients-1.svg';
import routes, { sidebar} from 'routes.js';
import { apiService } from 'services/ApiService';
import { getInitials, user } from 'services/Utils';
import { BiSolidBell } from 'react-icons/bi';
import { ReactComponent as AnalyticsIcon } from 'assets/svg/analytics.svg';
import { ReactComponent as UserMode } from 'assets/svg/userMode.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { ReactComponent as LogoutIcon } from 'assets/svg/logout.svg';

// Components
import { ItemContent } from 'components/Menu/ItemContent';
import SidebarResponsive from 'components/Sidebar/SidebarResponsive';

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, dataUser, ...rest } = props;

  // Color nav buttons
  let navbarIcon = useColorModeValue('#1870EC', '#1870EC');
  if (secondary) {
    navbarIcon = 'white';
  }
  const navbarBorderColor = useColorModeValue('blue.25', 'blue.25');
  const navbarBorderWidth = '0.1em';
  const userSite = process.env.REACT_APP_MAIN_SITE || '#';
  const iconMenuColor = useColorModeValue('blue.25', '#9FABCB');
  const emailColor = useColorModeValue('gray.75', 'white');
  const textColor = useColorModeValue('blue.900', 'white');

  // Set avatar in header
  let userAvatar = avatarDefault;
  if(dataUser?.image) {
    try {
      userAvatar = require(`assets/svg/avatars/gradients-${dataUser?.image || 1}.svg`);
    } catch (e) {}
  }

  const handleLogout = async () => {
    try {
      await apiService.logout();
    } catch (error) {}
    localStorage.removeItem('auth');
    window.location.href = '/#/auth/signin';
  };
  
  return (
    <Flex
      pe={{ sm: '0px', md: '0px' }}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
    >
      {user() ? (
        <>
          <Menu variant="profile">
              <MenuButton >
                <Flex p="5px" bg="#508DFF"
                    borderRadius="30px" >
                  <Flex 
                    overflow="hidden" 
                    w={{ sm: '32px' }} 
                    h={{ sm: '32px' }} 
                    position="relative">
                    <Text 
                      variant="title"
                      position="absolute" 
                      w="100%" 
                      pt="4px">{getInitials(dataUser.firstName, dataUser.lastName)}</Text>
                  </Flex>
                </Flex>
              </MenuButton>
              <MenuList>
                <Flex flexDirection="column">
                  <Text 
                    fontSize='14px' 
                    fontStyle='normal' 
                    fontWeight='400' 
                    color={emailColor}
                    p='0px 12px 11px 12px'
                  >
                    {dataUser?.email}
                  </Text>

                  <Divider w="calc(100% - 12px)" ml="6px" opacity='0.1' borderColor={navbarBorderColor} borderTopWidth="0.05em" borderBottomWidth="0"/>

                  <MenuItem>
                    <Link 
                      as="a" 
                      href="https://lmctplus.com" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{ width: '100%', display: 'flex' }}
                    >
                      <Box me="10px" mt="-2px">
                        <UserMode color={iconMenuColor} />
                      </Box>
                      Main site
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/settings" style={{width: '100%', display: 'flex'}}>
                      <Box me="10px" mt="-2px"><SettingsIcon color={iconMenuColor} /></Box>
                      Settings
                    </NavLink>
                  </MenuItem>
                  
                  <Divider w="calc(100% - 12px)" ml="6px" opacity='0.1' borderColor={navbarBorderColor} borderTopWidth="0.05em" borderBottomWidth="0"/>
                  
                  <MenuItem>
                    <Flex onClick={handleLogout}>
                      <Box me="10px" mt="-2px"><LogoutIcon color={iconMenuColor} /></Box>
                      Log out
                    </Flex>
                  </MenuItem>
                </Flex>
              </MenuList>
            </Menu>
        </>
      ) : (
        <>
          <NavLink to="/auth/signin">
            <Button
              variant="submit-md"
              ms="0px"
              w="120px">
              Sign in
            </Button>
          </NavLink>
        </>
      )}
      <SidebarResponsive
        secondary={props.secondary}
        routes={sidebar}
        {...rest}
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
