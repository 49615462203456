// Core
import { useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { validateEmail } from 'services/Utils';
import controls from 'assets/svg/controls.svg';
import qrCode from 'assets/svg/qr-code.svg';
import smsCode from 'assets/svg/sms.svg';
import emailCode from 'assets/svg/email.svg';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import ChangeEmailModal from './modals/ChangeEmail';
import ChangePhoneModal from './modals/ChangePhone';
import ChangePasswordModal from './modals/ChangePassword';
import TwoFAQRModal from 'components/Modals/TwoFA/QR';
import TwoFAEmailModal from 'components/Modals/TwoFA/Email';
import TwoFAPhoneModal from 'components/Modals/TwoFA/Phone';
import DeleteProfileModal from './modals/DeleteProfile';
import Attention from 'components/Modals/Attention.js';

const Security = ({setNotify}) => {
  // Chakra color mode
  const textColor = useColorModeValue('gray.800', 'white');
  const textColorEdit = useColorModeValue('blue.200', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];
  const [fontSizeData, fontSizeDataWeight] = ['18px', '700'];
  const paddingTd = '16px 24px 55px 24px';
  const borderColor = useColorModeValue('white', 'rgba(255, 255, 255, 0.10)');

  const [seconds, setSeconds] = useState(59);
  const [timerActive, setTimerActive] = useState(false);

  const [dataUser, setDataUser] = useState({ email: '', phone: '' });
  const [data2fa, setData2fa] = useState({ email: false, phone: false, google: false });

  const { isOpen: isOpenEmail, onOpen: onOpenEmail, onClose: onCloseEmail } = useDisclosure();
  const { isOpen: isOpenPhone, onOpen: onOpenPhone, onClose: onClosePhone } = useDisclosure();
  const { isOpen: isOpenPassword, onOpen: onOpenPassword, onClose: onClosePassword } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

  const { isOpen: isOpenTwoFAPhone, onOpen: onOpenTwoFAPhone, onClose: onCloseTwoFAPhone } = useDisclosure();
  const { isOpen: isOpenTwoFAEmail, onOpen: onOpenTwoFAEmail, onClose: onCloseTwoFAEmail } = useDisclosure();
  const { isOpen: isOpenTwoFAQR, onOpen: onOpenTwoFAQR, onClose: onCloseTwoFAQR } = useDisclosure();

  const { isOpen: isOpenAttentionFAPhone, onOpen: onOpenAttentionFAPhone, onClose: onCloseAttentionFAPhone } = useDisclosure();
  const { isOpen: isOpenAttentionFAEmail, onOpen: onOpenAttentionFAEmail, onClose: onCloseAttentionFAEmail } = useDisclosure();
  const { isOpen: isOpenAttentionFAQR, onOpen: onOpenAttentionFAQR, onClose: onCloseAttentionFAQR } = useDisclosure();

  const [stepEmail, setStepEmail] = useState(1);

  // Initial
  useEffect(() => { 
    const fetchData = async () => {
      try {
        const response = await apiService.getMe();
        setDataUser(response.data);
      } catch (error) {}
    };
    fetchData();

    const fetchData2fa = async () => {
      try {
        const response = await apiService.userTwoFAList();
        if(response.statusCode === 200){
        	setData2fa(response.data);	
        }
        
      } catch (error) {}
    };
    fetchData2fa();
  }, []); 

  // Timer for send code
  useEffect(() => {
    let interval = null;
    
    if (timerActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (!timerActive && seconds !== 0) {
      clearInterval(interval);
    } else if (seconds === 0) {
      setTimerActive(false);
    }

    return () => clearInterval(interval);
  }, [timerActive, seconds]);

  const startTimer = () => {
    setTimerActive(true);
    setSeconds(59);  
  };

  const handleLogout = async () => {
    try {
      await apiService.logout();
    } catch (error) {}
    localStorage.removeItem('auth');
    window.location.href = '/#/auth/signin'; 
  };

  const attentionModal = async (modal) => {
  	if(modal === 1){
  		onOpenAttentionFAQR();
  	} else if (modal === 2){	
  		onOpenAttentionFAPhone();
  	} else if (modal === 3){
  		onOpenAttentionFAEmail();
  	}
    
  };

  return (
  	<>
    <Text variant="title-lg" mb="16px">Contact</Text>
    <Card padding='8px 0' my={{ sm: '22px', xl: '0px' }} maxW={{sm: '100%', lg: '840px'}}>
    	<CardBody>
    		<Table>
	          <Tbody>
	            <Tr>
	              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} borderColor={borderColor}>
	                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Email</Text>
	              </Td>
	              <Td verticalAlign="top" p={paddingTd} borderColor={borderColor}>
	                <Text fontSize={fontSizeData} color={textColor} fontWeight={fontSizeDataWeight}>
	                  {dataUser.email}
	                </Text>
	                <Text variant="text-md" mt='6px' mb="-30px">
	                  <Text as="span" variant="text-md" fontWeight="bold" color="white">Verified. </Text>This email is linked to your account.
	                </Text>
	              </Td>
	              <Td verticalAlign="top" borderColor={borderColor}>
	                <Flex justifyContent="flex-end">
	                    <Image src={controls} cursor="pointer" onClick={onOpenEmail}/>
	                </Flex>
	                <ChangeEmailModal 
	                	dataUser={dataUser}
	                	setNotify={setNotify}
	                	seconds={seconds}
	                	startTimer={startTimer}
	                	timerActive={timerActive}
	                	isOpenEmail={isOpenEmail}
	                	onOpenEmail={onOpenEmail}
	                	onCloseEmail={onCloseEmail} />
	              </Td>
	            </Tr>
	            <Tr>
	              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} border="unset">
	                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Phone Number</Text>
	              </Td>
	              <Td verticalAlign="top" p={paddingTd} border="unset">
	                <Text fontSize={fontSizeData} color={textColor} fontWeight={fontSizeDataWeight}>
	                  +{dataUser.phone}
	                </Text>
	                <Text variant="text-md" mt='6px' mb="-30px">
	                  This phone number is linked to your account.
	                </Text>
	              </Td>
	              <Td verticalAlign="top" border="unset">
	                <Flex justifyContent="flex-end">
	                    <Image src={controls} cursor="pointer" onClick={onOpenPhone}/>
	                </Flex>
	                <ChangePhoneModal 
	                	dataUser={dataUser}
	                	seconds={seconds}
	                	startTimer={startTimer}
	                	timerActive={timerActive}
	                	setNotify={setNotify}
	                	isOpenPhone={isOpenPhone}
	                	onOpenPhone={onOpenPhone}
	                	onClosePhone={onClosePhone} />
	              </Td>
	            </Tr>
	          </Tbody>
	        </Table>
    	</CardBody>
    </Card>

    <Text mt='40px' variant="title-lg" mb="16px">Security</Text>
    <Card padding='8px 0' my={{ sm: '22px', xl: '0px' }} maxW={{sm: '100%', lg: '840px'}}>
    	<CardBody>
    		<Table>
	          <Tbody>
	            <Tr>
	              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} borderColor={borderColor}>
	                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Password</Text>
	              </Td>
	              <Td verticalAlign="top" p={paddingTd} borderColor={borderColor}>
	                <Text color={textColorEdit} fontWeight={fontSizeTitleWeight} cursor='pointer' onClick={onOpenPassword}>
	                  Change password
	                </Text>
	                <Text variant="text-md" mt='6px' mb="-30px">
	                  Improve your security with a strong password.
	                </Text>
	                <ChangePasswordModal 
	                	dataUser={dataUser}
	                	setNotify={setNotify}
	                	isOpenPassword={isOpenPassword}
	                	onOpenPassword={onOpenPassword}
	                	onClosePassword={onClosePassword} />
	              </Td>
	            </Tr>
	            <Tr opacity="0.1" pointerEvents="none">
	              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} border="unset">
	                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Two-Factor Authentication</Text>
	              </Td>
	              <Td verticalAlign="top" p={paddingTd} border="unset">
	                <Text variant="text-md" w={{sm: '100%', lg: '55%'}}>
	                  Add an extra layer of security to your LMCT+ account by using your password and a code on your mobile phone to log in.
	                </Text>
	                <Text mt='24px' fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Choose the option that suits you</Text>
	                <Box mb="-24px">
	                	<Box mt="24px">
			                <Flex justify="space-between" w="100%">
		                		<Flex>
				                  <Image src={qrCode} />
				                  <Text ml="12px">
				                    <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>QR Code</Text>
				                    <Text mt="4px" variant="text-md">Scan the QR code</Text>
				                  </Text>
				                </Flex>
				                { !data2fa.google ? (
			                		<Button variant="submit-sm" maxW="80px" onClick={onOpenTwoFAQR}>Set Up</Button>
			                	) : (
			                		<Button variant="submit-sm" maxW="80px" w="100%" onClick={() => attentionModal(1)}>Disable</Button>
			                	)}
			                </Flex>
			              </Box>
			              <Box mt="24px">
			                <Flex justify="space-between" w="100%">
				                <Flex>
					                <Image src={smsCode} />
					                <Text ml="12px">
					                    <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>SMS Code</Text>
					                    <Text mt="4px" variant="text-md">Enter the code sent to your phone number</Text>
					                </Text>
					            </Flex>
					            { !data2fa.phone ? (
				                	<Button variant="submit-sm" maxW="80px" onClick={onOpenTwoFAPhone}>Set Up</Button>
				                ) : (
			                		<Button variant="submit-sm" maxW="80px" w="100%" onClick={() => attentionModal(2)}>Disable</Button>
			                	)}
			                </Flex>
			              </Box>
			              <Box mt="24px">
			                <Flex justify="space-between" w="100%">
				                <Flex>
					                <Image src={emailCode} />
					                <Text ml="12px">
					                    <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Email Code</Text>
					                    <Text mt="4px" variant="text-md">Enter the code emailed to you</Text>
					                </Text>
				                </Flex>
				                { !data2fa.email ? (
			                	<Button variant="submit-sm" maxW="80px" onClick={onOpenTwoFAEmail}>Set Up</Button>
			                	) : (
			                		<Button variant="submit-sm" maxW="80px" w="100%" onClick={() => attentionModal(3)}>Disable</Button>
			                	)}
			                </Flex>
			            </Box>
	                </Box>

	                <TwoFAQRModal 
	                	dataUser={dataUser}
	                	seconds={seconds}
	                	startTimer={startTimer}
	                	timerActive={timerActive}
	                	setNotify={setNotify}
	                	data2fa={data2fa}
	                	setData2fa={setData2fa}
	                	isOpenTwoFA={isOpenTwoFAQR}
	                	onOpenTwoFA={onOpenTwoFAQR}
	                	onCloseTwoFA={onCloseTwoFAQR} />

	                <TwoFAPhoneModal 
	                	dataUser={dataUser}
	                	seconds={seconds}
	                	startTimer={startTimer}
	                	timerActive={timerActive}
	                	setNotify={setNotify}
	                	data2fa={data2fa}
	                	setData2fa={setData2fa}
	                	isOpenTwoFA={isOpenTwoFAPhone}
	                	onOpenTwoFA={onOpenTwoFAPhone}
	                	onCloseTwoFA={onCloseTwoFAPhone} />

	                <TwoFAEmailModal 
	                	dataUser={dataUser}
	                	seconds={seconds}
	                	startTimer={startTimer}
	                	timerActive={timerActive}
	                	setNotify={setNotify}
	                	data2fa={data2fa}
	                	setData2fa={setData2fa}
	                	isOpenTwoFA={isOpenTwoFAEmail}
	                	onOpenTwoFA={onOpenTwoFAEmail}
	                	onCloseTwoFA={onCloseTwoFAEmail} />

	                <Attention
	                  variant="twoFA"
			          handle={onOpenTwoFAQR}
			          isOpen={isOpenAttentionFAQR}
			          onOpen={onOpenAttentionFAQR}
			          onClose={onCloseAttentionFAQR}/>

			         <Attention
	                  variant="twoFA"
			          handle={onOpenTwoFAPhone}
			          isOpen={isOpenAttentionFAPhone}
			          onOpen={onOpenAttentionFAPhone}
			          onClose={onCloseAttentionFAPhone}/>

	                <Attention
	                  variant="twoFA"
			          handle={onOpenTwoFAEmail}
			          isOpen={isOpenAttentionFAEmail}
			          onOpen={onOpenAttentionFAEmail}
			          onClose={onCloseAttentionFAEmail}/>
	              </Td>
	            </Tr>
	          </Tbody>
	        </Table>
    	</CardBody>
    </Card>

    <Text mt='40px' variant="title-lg" mb="16px">Account management</Text>
    <Card padding='8px 0' my={{ sm: '22px', xl: '0px' }} maxW={{sm: '100%', lg: '840px'}}>
    	<CardBody>
    		<Table>
	          <Tbody>
	          	<Tr>
	              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} borderColor={borderColor}>
	                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Go out</Text>
	              </Td>
	              <Td verticalAlign="top" p={paddingTd} borderColor={borderColor}>
	                <Text color={textColorEdit} fontWeight={fontSizeTitleWeight} cursor='pointer' onClick={handleLogout}>
	                  Quit now
	                </Text>
	                <Text variant="text-md" mt='6px' mb="-30px" w={{sm: '100%', lg: '55%'}}>
	                  After logging out you will need to enter your details back in to login
	                </Text>
	              </Td>
	            </Tr>
	            <Tr>
	              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} border="unset">
	                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Delete account</Text>
	              </Td>
	              <Td verticalAlign="top" p={paddingTd} border="unset">
	                <Text color={textColorEdit} fontWeight={fontSizeTitleWeight} cursor='pointer' onClick={onOpenDelete}>
	                  Send an email request to delete your account
	                </Text>
	                <Text variant="text-md" mt='6px' mb="-30px" w={{sm: '100%', lg: '55%'}}>
	                  You will have 30 days to restore your account. After the expiration date you will need to register again.
	                </Text>
	                <DeleteProfileModal
	                	dataUser={dataUser}
	                	setNotify={setNotify}
	                	seconds={seconds}
	                	startTimer={startTimer}
	                	timerActive={timerActive}
	                	isOpenDelete={isOpenDelete}
	                	onOpenDelete={onOpenDelete}
	                	onCloseDelete={onCloseDelete} />
	              </Td>
	            </Tr>
	          </Tbody>
	        </Table>
    	</CardBody>
    </Card>
    </>
  );
};

export default Security;
