// Chakra imports
import { 
  Box,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

function NotFound(props) {
  const textColor = useColorModeValue('#757780', '#9FABCB');

  return (
    <Box textAlign="center">
    	<Text variant="text" color={textColor} mt="64px">{props?.text || 'Page not found' }</Text>
    </Box>
  );
}

export default NotFound;