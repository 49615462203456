// Core
import { HiDotsVertical } from 'react-icons/hi';
import {useEffect, useState, useCallback} from 'react';
import { IoTicketSharp } from 'react-icons/io5';
import { NavLink, Link } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { parseDateTime } from 'services/Utils';
import { ReactComponent as InfoControls } from 'assets/svg/controls.svg';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { TicketIcon, EditIcon, DeleteIcon } from 'components/Icons/Icons';

const OffersRow = ({ offer, handleEditOpen, handleDelete }) => {
  const [switched, setSwitched] = useState(offer.hidden);

  const borderColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');

  const handleHidden = (event) => { 
    setSwitched(switched ? false : true);
  };

  useEffect(() => { 
    if(offer && offer.hidden !== switched){
      apiService.updateBanner({...offer, hidden: switched});
    }
  }, [switched]);

  return (
    <Card key={offer.id} padding="24px">
      <CardBody>
        <Box w="100%">
          <Flex justifyContent="space-between" w="100%" mb="10px">
          	<Flex gap="4px">
            	<Text variant="text-md">Created:</Text>
            	<Text variant="text-md" fontWeight="500" color="white">{ parseDateTime(offer.createdAt) }</Text>
            </Flex>
            <Flex justifyContent="space-between" gap="18px">
              <Text variant="title">Active</Text> 
              <Switch
                colorscheme="teal"
                m="2px 0 0 -4px"
                isChecked={!switched}
                onChange={handleHidden} />
            </Flex>
          </Flex>

          <Text 
            variant="title" transition="0.2s all" lineHeight="34px" fontWeight="700" fontSize="28px"
            whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {offer.title}
          </Text>
          <Text variant="text" mt="2px" mb="-4px">{offer.description.length > 60 ? `${offer.description.slice(0, 60)}...` : offer.description}</Text>

          <Divider opacity="1" my="16px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0"/>

          <Flex justifyContent="space-between" w="100%" gap="8px" mt="14px">
            <Button
              variant="secondary-md" 
              onClick={() => handleDelete(offer)}
              leftIcon={<DeleteIcon color="white" fontSize="22px" pl="4px" mr="-4px" />} />

            <Button 
              variant="submit-md" 
              onClick={() => handleEditOpen(offer)}
              leftIcon={<EditIcon color="white" fontSize="24px" />}>
              Change
            </Button>
          </Flex>
        </Box>
      </CardBody>
    </Card>
  );
};

export default OffersRow;