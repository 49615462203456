// Core
import { HiDotsVertical } from 'react-icons/hi';
import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  InputGroup,
  InputLeftElement,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import placeholder from 'assets/img/placeholder.png';

// Components
import {
  ArrowIcon,
  DeleteIcon,
  EditIcon
} from 'components/Icons/Icons';
import EditModal from './CategoryModal';
import Attention from 'components/Modals/Attention.js';

const CategoryRow = ({
  row,
  handleShowOnLeftChange,
  handleFeaturedChange,
  handleDeleteObject,
  handleDataOpen
}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const paddingTd = '8px 0 16px 24px';
  const paddingTdSub = '8px 0 16px 54px';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => {
    setShow(!show);
  });

  const attentionModal = async () => {
    onOpen();
  };

  return (
    <>
      <Tr key={row.id} border="unset">
        <Td w="30%" verticalAlign="center" p={paddingTd}>
          <Flex gap="12px" pl="18px">
            <ArrowIcon 
              onClick={handleShow}
              cursor="pointer"
              opacity={row.subCategories.length > 0 ? '1' : '0.2'}
              key={row.id} mt="7px" transform={!show ? 'rotate(180deg)' : 'rotate(0deg)'} fontSize="24px" color="white"/>
            <Text variant="text" pt="10px" color={textColor}>{row.name}</Text>
          </Flex>
        </Td>
        <Td verticalAlign="center" >
          <Image src={ row.image ? row.image : placeholder } 
            h="40px" w="40px" objectFit="cover" borderRadius="10px" border="1px solid #091931" />
        </Td>
        <Td verticalAlign="center" p={paddingTd}>
          <Text variant="text" pt="10px" color={textColor}>{row.subCategories.length}</Text>
        </Td>
        <Td verticalAlign="center">
              <Switch isChecked={row?.featured} onChange={() => handleFeaturedChange(row)} />
        </Td>
        <Td verticalAlign="center">
          <Switch isChecked={row?.showOnLeft} onChange={() => handleShowOnLeftChange(row)} />
        </Td>
        <Td>
          <Flex gap="36px" justifyContent="flex-end" pr="10px">
            <EditIcon fontSize="24" color="white" onClick={() => handleDataOpen(row)} cursor="pointer" />
            <DeleteIcon fontSize="24" color="white" onClick={attentionModal} cursor="pointer" />

            <Attention
              variant="remove"
              handle={() => handleDeleteObject(row)}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </Flex>
        </Td>
      </Tr>


      { row.subCategories && show && row.subCategories.map((subRow) => (
        <Tr key={subRow.id} border="unset">
          <Td verticalAlign="center" p={paddingTdSub} position="relative">
            <Text p="16px 0 8px 24px" variant="text" color={textColor}>{subRow.name}</Text>
            <Box position="absolute" w="54px" h="3px" left="0" bg="#0e1633" bottom="-2px"></Box>
            <Box position="absolute" w="1px" h="100%" left="54px" bg="#1f2733" top="0px"></Box>
          </Td>
          <Td>
            <Image src={ subRow.image ? subRow.image : placeholder } 
            h="40px" w="40px" objectFit="cover" borderRadius="10px" border="1px solid #091931" />
          </Td>
          <Td verticalAlign="center" p={paddingTd}>
            <Text p="16px 0 8px 0px" variant="text" color={textColor}>{subRow.parentId ? row.name : ''}</Text>
          </Td>
          <Td></Td>
          <Td></Td>
          <Td>
            <Flex gap="36px" justifyContent="flex-end" pr="10px">
            <EditIcon fontSize="24" color="white" cursor="pointer" onClick={() => handleDataOpen(subRow)} />
            <DeleteIcon fontSize="24" color="white" cursor="pointer" onClick={() => handleDeleteObject(subRow)} />
          </Flex>
          </Td>
        </Tr>
      ))}
    </>
  );
};

export default CategoryRow;