// Import Views

// Dashboard
import Dashboard from 'views/Dashboard/Dashboard';
import Giveaways from 'views/Dashboard/Giveaways';
import Users from 'views/Dashboard/Users';
import Partners from 'views/Dashboard/Partners';
import Admins from 'views/Dashboard/Admins';
import Membership from 'views/Dashboard/Membership';
import Receipts from 'views/Dashboard/Receipts';
import Offers from 'views/Dashboard/Offers';
import Banner from 'views/Dashboard/Banners';
import Achievements from 'views/Dashboard/Achievements';
import PublicPages from 'views/Dashboard/PublicPages';
import Support from 'views/Dashboard/Support';
import Settings from 'views/Dashboard/Settings';
import Stream from 'views/Dashboard/Stream';

// Auth
import SignIn from 'views/Auth/SignIn.js';
import Forgot from 'views/Auth/Forgot.js';

import {
  HomeIcon,
  DocumentIcon,
  DashboardIcon,
  DashboardActiveIcon,
  GiveawaysIcon,
  GiveawaysActiveIcon,
  UsersIcon,
  UsersActiveIcon,
  PartnerIcon,
  PartnerActiveIcon,
  AdminsIcon,
  AdminsActiveIcon,
  MembershipIcon,
  MembershipActiveIcon,
  OffersIcon,
  OffersActiveIcon,
  ReceiptsIcon,
  ReceiptsActiveIcon,
  BannersIcon,
  BannersActiveIcon,
  AchievementIcon,
  AchievementActiveIcon,
  PublicPagesIcon,
  PublicPagesActiveIcon,
  SupportIcon,
  SupportActiveIcon,
  StreamIcon,
  StreamActiveIcon
} from 'components/Icons/Icons';

export const sidebar = [
  {
    path: 'dashboard',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: <DashboardIcon color="#8794C2" />,
    iconActive: <DashboardActiveIcon color="#099BFF" />,
    component: Dashboard,
    layout: '/',
  },
  {
    path: 'giveaways',
    name: 'Giveaways',
    rtlName: 'لوحة القيادة',
    icon: <GiveawaysIcon color="#8794C2" />,
    iconActive: <GiveawaysActiveIcon color="#099BFF" />,
    component: Giveaways,
    layout: '/',
  },
  {
    path: 'users',
    name: 'Users',
    rtlName: 'لوحة القيادة',
    icon: <UsersIcon color="#8794C2" />,
    iconActive: <UsersActiveIcon color="#099BFF" />,
    component: Users,
    layout: '/',
  },
  {
    path: 'partners',
    name: 'Partners',
    rtlName: 'لوحة القيادة',
    icon: <PartnerIcon color="#8794C2" />,
    iconActive: <PartnerActiveIcon color="#099BFF" />,
    component: Partners,
    layout: '/',
  },
  {
    path: 'admins',
    name: 'Admins',
    rtlName: 'لوحة القيادة',
    icon: <AdminsIcon color="#8794C2" />,
    iconActive: <AdminsActiveIcon color="#099BFF" />,
    component: Admins,
    layout: '/',
  },
  {
    path: 'membership',
    name: 'Membership',
    rtlName: 'لوحة القيادة',
    icon: <MembershipIcon color="#8794C2" />,
    iconActive: <MembershipActiveIcon color="#099BFF" />,
    component: Membership,
    layout: '/',
  },
  {
    path: 'offers',
    name: 'Special Offers',
    rtlName: 'لوحة القيادة',
    icon: <OffersIcon color="#8794C2" />,
    iconActive: <OffersActiveIcon color="#099BFF" />,
    component: Offers,
    layout: '/',
  },
  {
    path: 'receipts',
    name: 'Receipts',
    rtlName: 'لوحة القيادة',
    icon: <ReceiptsIcon color="#8794C2" />,
    iconActive: <ReceiptsActiveIcon color="#099BFF" />,
    component: Receipts,
    layout: '/',
  },
  {
    path: 'banners',
    name: 'Banners',
    rtlName: 'لوحة القيادة',
    icon: <BannersIcon color="#8794C2" />,
    iconActive: <BannersActiveIcon color="#099BFF" />,
    component: Banner,
    layout: '/',
  },
  {
    path: 'achievements',
    name: 'Achievements',
    rtlName: 'لوحة القيادة',
    icon: <AchievementIcon color="#8794C2" />,
    iconActive: <AchievementActiveIcon color="#099BFF" />,
    component: Achievements,
    layout: '/',
  },
  {
    path: 'public-pages',
    name: 'Public pages',
    rtlName: 'لوحة القيادة',
    icon: <PublicPagesIcon color="#8794C2" />,
    iconActive: <PublicPagesActiveIcon color="#099BFF" />,
    component: PublicPages,
    layout: '/',
  },
  {
    path: 'support',
    name: 'Support',
    rtlName: 'لوحة القيادة',
    icon: <SupportIcon color="#8794C2" />,
    iconActive: <SupportActiveIcon color="#099BFF" />,
    component: Support,
    layout: '/',
  },
  {
    path: 'stream',
    name: 'Stream',
    rtlName: 'لوحة القيادة',
    icon: <StreamIcon color="#8794C2" />,
    iconActive: <StreamActiveIcon color="#099BFF" />,
    component: Stream,
    layout: '/',
  }
];

export const other = [
  {
    path: 'settings',
    name: 'Settings',
    rtlName: 'لوحة القيادة',
    icon: <HomeIcon color="inherit" />,
    component: Settings,
    layout: '/',
  }
];

export const auth = [
  {
    name: 'AUTH',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/signin',
        name: 'Sign In',
        rtlName: 'لوحة القيادة',
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: '/auth',
      },
      {
        path: '/forgot-password',
        name: 'Forgot Password',
        rtlName: 'لوحة القيادة',
        icon: <DocumentIcon color="inherit" />,
        component: Forgot,
        layout: '/auth',
      },
    ],
  },
];

const routes = [...sidebar, ...other, ...auth];
export default routes;