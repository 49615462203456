const disable = {
  _disabled: {
    opacity: '1 !important',
    bg: '#0F1426 !important',
    color: '#4C505C !important',
    _hover: {
      bg: '#0F1426 !important',
    },
    _active: {
      bg: '#0F1426 !important',
    },
    'svg > path': {
      color: '#4C505C !important', 
    }
  },
};

const submitStyles = {
  fontSize: '16px !important',
  bg: 'linear-gradient(180deg, #2ABDFD 0%, #0497FD 100%) !important',
  w: '100% !important',
  h: '54px !important',
  color: 'white !important',
  borderRadius: '16px !important',
  _hover: {
    bg: 'linear-gradient(180deg, #009EEA 0%, #009EEA 100%) !important',
  },
  _active: {
    bg: 'linear-gradient(180deg, #2ABDFD 0%, #0497FD 100%) !important',
  },
  ...disable
};

const secondaryStyles = {
  fontWeight: '600',
  bg: '#0D234D',
  fontSize: '16px !important',
  borderRadius: '16px !important',
  color: 'white !important',
  _hover: {
    bg: '#0d1e3f !important',
  },
  _active: {
    bg: '#0D234D !important',
  },
  ...disable
};

const ghostStyles = {
  fontSize: '16px',
  fontWeight: '600',
  borderRadius: '16px !important',
  h: '54px !important',
  color: '#099BFF',
  _hover: {
    bg: 'transparent !important',
  },
  _active: {
    bg: 'transparent !important',
  },
  ...disable
};

const deleteStyles = {
  bg: '#BA1A1A',
  _hover: {
    bg: '#BA1A1A !important',
  },
  _active: {
    bg: '#BA1A1A !important',
  },
  ...disable
};

export const buttonStyles = {
  components: {
    Button: {
      variants: {
        'submit': submitStyles,
        'submit-md': {
          ...submitStyles, 
          h: '44px',
          p: '12px',
        },
        'submit-ms': {
          ...submitStyles, 
          h: '40px',
          p: '11px'
        },
        'submit-sm': {
          ...submitStyles, 
          h: '36px',
          p: '8px'
        },
        'submit-md-delete': {
          ...submitStyles, 
          ...deleteStyles,
          h: '44px',
          p: '12px'
        },
        'secondary-lg': {
          ...secondaryStyles,
          h: '56px',
          p: '12px',
        },
        'secondary': {
          ...secondaryStyles
        },
        'secondary-md': {
          ...secondaryStyles,
          h: '44px',
          p: '12px',
        },
        'secondary-ms': {
          ...secondaryStyles, 
          h: '40px',
          p: '11px'
        },
        'secondary-sm': {
          ...secondaryStyles, 
          h: '36px',
          p: '8px'
        },
        'ghost': {
          ...ghostStyles,
        },
        'ghost-md': {
          ...ghostStyles,
          h: '44px',
          p: '12px',
        },
        'ghost-ms': {
          ...ghostStyles, 
          h: '40px',
          p: '11px'
        },
        'ghost-sm': {
          ...ghostStyles, 
          h: '36px',
          p: '8px'
        },
        'menuitem': {
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          color: 'blue.900',
          mx: '-5px',
          px: '0',
          mb: '-2px',
          mt: '-2px',
          _focus: {
            bg: 'transparent !important',
          },
          _hover: {
            bg: 'transparent !important',
          },
          _active: {
            bg: 'transparent !important',
          }
        }
      },
      baseStyle: {
        bg: 'unset',
        _focus: {
          boxShadow: 'none',
        },
      },
    }
  }
};
