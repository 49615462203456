import { mode } from '@chakra-ui/theme-tools';

export const menuStyles = {
  components: {
    Menu: {
      baseStyle: (props) => ({
        button: {
          transition: '0s all !important',
          outline: 'unset',
          borderRadius: '10px',
          border: '1px solid rgba(255, 255, 255, 0.20)',
          position: 'relative',
          h: '44px',
          textAlign: 'center',
          'span > div': {
            position: 'relative',
            borderRadius: '9px',
            bg: mode(
              'linear-gradient(0deg, rgba(9, 155, 255, 0.17) 4.55%, rgba(255, 255, 255, 0.00) 100%), #EDF0FF',
              'linear-gradient(17deg, #29498F 12.16%, rgba(22, 43, 90, 0.30) 119.99%), #151A32')(props),
          },
          _hover: {
            'span > div': {
              bg: 'linear-gradient(16deg, rgba(22, 43, 90, 0.30) -13.5%, #29498F 85.98%), rgba(24, 112, 236, 0.05)'
            }
          },
          _active: {
            'span > div': {
              bg: 'linear-gradient(0deg, #29498F 0%, #29498F 100%), rgba(0, 78, 174, 0.10)'
            }
          }
        },
        list: {
          color: mode('gray.800', 'white')(props),
          p: '12px 0px',
          borderRadius: '10px !important',
          borderWidth: '1px', 
          borderColor: mode('rgba(0, 0, 0, 0.10)', '#262D47')(props),
          bg:  mode('white', '#0D234D')(props),
          boxShadow: mode('0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)', '0px 1px 2px 0px rgba(0, 0, 0, 0.06)')(props),
          '> button': {
            p: '9px 14px',
            my: '3px',
            fontSize: '14px', 
            fontStyle: 'normal' ,
            fontWeight: '400',
            color: mode('blue.900', 'white')(props), 
            _focus: {
                bg: '#10337F !important',
            },
            _active: {
                bg: '#10337F !important',
            },
            _hover: {
              bg: '#10337F !important'
            },
          },
          'div > button': {
            p: '9px 14px',
            my: '3px',
            fontSize: '14px', 
            fontStyle: 'normal' ,
            fontWeight: '400',
            color: mode('blue.900', 'white')(props), 
            _focus: {
                bg: '#10337F !important',
            },
            _active: {
                bg: '#10337F !important',
            },
            _hover: {
              bg: '#10337F !important'
            },
          },
        },
      }),
      variants: {
        filter: (props) => ({
          button: {
            transition: '0.3s all',
            outline: 'unset',
            borderRadius: '10px',
            border: '1px solid rgba(255, 255, 255, 0.20)',
            position: 'relative',
            h: '44px',
            textAlign: 'center',
            'span > div': {
              position: 'relative',
              borderRadius: '20px',
              bg: mode(
                'linear-gradient(0deg, rgba(9, 155, 255, 0.17) 4.55%, rgba(255, 255, 255, 0.00) 100%), #EDF0FF',
                'linear-gradient(17deg, #29498F 12.16%, rgba(22, 43, 90, 0.30) 119.99%), #151A32')(props),
            },
            _hover: {
              'span > div': {
                bg: 'linear-gradient(16deg, rgba(22, 43, 90, 0.30) -13.5%, #29498F 85.98%), rgba(24, 112, 236, 0.05)'
              }
            },
            _active: {
              borderColor: 'rgba(255, 255, 255, 0.10)',
              'span > div': {
                bg: '#0279CB',
                'svg > path': {
                  fill: 'white'
                }
              }
            }
          },
          list: {
            borderRadius: '20px !important',
            border: '1px solid rgba(255, 255, 255, 0.10) !important',
            '> button': {
              p: '10px 24px',
              my: '0px',
              _focus: {
                  bg: '#0E1633 !important',
              },
              _active: {
                  bg: '#0E1633 !important',
              },
              _hover: {
                bg: '#0E1633 !important'
              },
            },
            'div > button': {
              my: '3px',
            }
          }
        }),
        profile: (props) => ({
          button: {
            transition: '0.3s all',
            outline: 'unset',
            borderRadius: 'full',
            border: '1px solid rgba(255, 255, 255, 0.20)',
            position: 'relative',
            h: '44px',
            textAlign: 'center',
            bg: '#508DFF !important',
            'span > div': {
              position: 'relative',
              borderRadius: '9px',
              bg: 'unset',
            },
            _hover: {
              'span > div': {
                borderRadius: 'full',
                bg: '#508DFF'
              }
            },
            _active: {
              'span > div': {
                borderRadius: 'full',
                bg: '#508DFF'
              }
            },
            _focus: {
              'span > div': {
                borderRadius: 'full',
                bg: '#508DFF'
              }
            }
          },
        }),
        ghost: (props) => ({
          button: {
            transition: '0.3s all',
            border: 'unset',
            outline: 'unset',
            'span > div': {
              bg: 'unset',
              'p': {
                transition: '0.3s all',
                color: mode('blue.800', '#9FABCB')(props),
                fontWeight: '600',
                fontSize: '16px'
              },
              'svg': {
                mt: '0px',
                ml: '6px',
                'path': {
                  transition: '0.3s all',
                  stroke:  mode('blue.800', '#9FABCB')(props), 
                }
              }
            },
            _hover: {
              border: 'unset',
              'span > div': {
                bg: 'unset',
                'p': {
                  color: '#099BFF',
                },
                'svg': {
                  'path': {
                    stroke:  mode('blue.800', '#099BFF')(props), 
                  }
                }
              }
            },
            _active: {
              border: 'unset',
              'span > div': {
                bg: 'unset',
                'p': {
                  color: '#099BFF',
                },
                'svg': {
                  'path': {
                    stroke:  mode('blue.800', '#099BFF')(props), 
                  }
                }
              }
            }
          }
        }),
      },
    },
  },
};