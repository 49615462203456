// Core
import { HiDotsVertical } from 'react-icons/hi';
import {useEffect, useState, useCallback} from 'react';
import { IoTicketSharp } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Link,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import placeholder from 'assets/img/placeholder.png';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { TicketIcon, AdminsActiveIcon, EditIcon } from 'components/Icons/Icons';

const MembershipRow = ({ data, handleEditOpen }) => {

  const borderColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');

  return (
    <Card key={data.id} padding="24px">
      <CardBody>
        <Box w="100%">
          <Flex justifyContent="space-between" width="100%">
            <Flex gap="19px">
              <Image 
                src={data?.image ? data.image : placeholder} 
                bg="#0D234D"
                borderRadius="12px"
                objectFit="cover"
                p="12px"
                w="78px" 
                h="78px" />
              <Box mt="-6px">
                <Text variant="title" fontSize="28px" fontWeight="700">{data?.name}</Text>
                <Text variant="text-md" fontSize="15px">Monthly cost: ${ data?.price / 100 }</Text>
                <Text variant="text-md" fontSize="15px" mt="4px">Annual cost: ${ (data?.price * 12) / 100 }</Text>
              </Box>
            </Flex>
            <Flex
              float="right" 
              borderRadius="10px"
              bg="rgba(34, 151, 63, 0.20)"
              p="6px 8px"
              h="32px"
              gap="4px">
              <AdminsActiveIcon fontSize="16px" color="#57D175" mt="2px" />
              <Text variant="text-md" fontWeight="500" color="#57D175">{data?.amountOfUsers}</Text>
            </Flex>
          </Flex>

          <Divider opacity="1" my="16px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0"/>

          <Button 
            variant="submit-md" 
            onClick={() => handleEditOpen(data)}
            leftIcon={<EditIcon color="white" fontSize="24px" />}>
            Change
          </Button>
        </Box>
      </CardBody>
    </Card>
  );
};

export default MembershipRow;