const CardHeader = {
  baseStyle: {
    display: 'flex',
    width: '100%'
  },
};

export const CardHeaderComponent = {
  components: {
    CardHeader,
  },
};
