// Core
import { HiDotsVertical } from 'react-icons/hi';
import {useEffect, useState, useCallback} from 'react';
import { IoTicketSharp } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Link,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { parseDateTime } from 'services/Utils';
import { ReactComponent as InfoControls } from 'assets/svg/controls.svg';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { TicketIcon, EditIcon, DeleteIcon } from 'components/Icons/Icons';

const StreamRow = ({ stream, handleEditOpen, handleActiveChange, handleDelete }) => {
  const [switched, setSwitched] = useState(stream.hidden);

  const handleHidden = (event) => { 
    setSwitched(switched ? false : true);
  };

  useEffect(() => { 
    if(stream && stream.hidden !== switched){
      apiService.updateStream({...stream, hidden: switched});
    }
  }, [switched]);

  return (
    <Card key={stream.id} padding="24px">
      <CardBody>
        <Box w="100%">
          <Flex justifyContent="space-between" w="100%">
            <Text variant="text-md" w="100%">{ parseDateTime(stream.startDate) }</Text>
            <Flex justifyContent="space-between" w="100%" gap="12px" maxW="96px">
              <Text variant="title">Active</Text> 
              <Switch
                colorscheme="teal"
                m="2px 0 0 -4px"
                isChecked={!switched}
                onChange={handleHidden} />
            </Flex>
          </Flex>

          <Link textDecoration='none !important' href={stream.url} transition="0.2s all">
            <Text variant="title" transition="0.2s all" fontSize="20px" _hover={{ color: '#099BFF'}}>{stream.name}</Text>
          </Link>
          <Text variant="text" mt="4px" >{stream.description}</Text>

          <Flex justifyContent="space-between" w="100%" gap="8px" mt="14px">
            <Button
              variant="secondary-md" 
              onClick={() => handleDelete(stream)}
              leftIcon={<DeleteIcon color="white" fontSize="20px" pl="4px" mr="-4px" />} />

            <Button 
              variant="submit-md" 
              onClick={() => handleEditOpen(stream)}
              leftIcon={<EditIcon color="white" fontSize="24px" />}>
              Change
            </Button>
          </Flex>
        </Box>
      </CardBody>
    </Card>
  );
};

export default StreamRow;