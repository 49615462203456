import { extendTheme } from '@chakra-ui/react';
import { globalStyles } from './styles';
import { breakpoints } from './foundations/breakpoints';
import { textStyles } from './foundations/text';
import { buttonStyles } from './components/button';
import { badgeStyles } from './components/badge';
import { formStyles } from './components/form';
import { linkStyles } from './components/link';
import { drawerStyles } from './components/drawer';
import { menuStyles } from './components/menu';
import { CardComponent } from './additions/card/Card';
import { CardBodyComponent } from './additions/card/CardBody';
import { CardHeaderComponent } from './additions/card/CardHeader';
import { MainPanelComponent } from './additions/layout/MainPanel';
import { PanelContentComponent } from './additions/layout/PanelContent';
import { PanelContainerComponent } from './additions/layout/PanelContainer';
// import { mode } from "@chakra-ui/theme-tools";
export default extendTheme(
  { 
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    }
  },
  { breakpoints }, // Breakpoints
  globalStyles,
  buttonStyles, // Button styles
  badgeStyles, // Badge styles
  linkStyles, // Link styles
  textStyles, // Text styles
  drawerStyles, // Sidebar variant for Chakra's drawer
  CardComponent, // Card component
  CardBodyComponent, // Card Body component
  CardHeaderComponent, // Card Header component
  MainPanelComponent, // Main Panel component
  PanelContentComponent, // Panel Content component
  PanelContainerComponent, // Panel Container component
  formStyles, // Form styles
  menuStyles
);
