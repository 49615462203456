import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { apiService } from 'services/ApiService';
import { useHistory } from 'react-router-dom';
import StreamEditContent from './components/StreamEditContent';
import NotFound from 'views/Dashboard/404.js';

const StreamEdit = ({Stream}) => {
  const history = useHistory();
  const { id } = useParams();
  const [newStreamErrors, setNewStreamErrors] = useState([]);
  const [newStream, setNewStream] = useState({});

  const { isLoading, isError } = useQuery({
    queryKey: [id],
    refetchOnWindowFocus: false,
    refetchOnError: false,
    queryFn: async () => {
      if (id === 'new') {
        setNewStream({});
      } else {
        const response = await apiService.getStream({id});
        if(response.data.type === 'auto'){
          const period = await apiService.getPeriods({streamId: id});
          if(period?.data?.[0]?.active){
            response.data['startDate'] = period.data?.[0].startDate;
            response.data['endDate'] = period.data?.[0].endDate;
          }
        }
        setNewStream(response.data);
      }
      return true;
    }
  });

  const { data: stream } = useQuery({
    queryKey: ['stream'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getStream();
      return response.data;
    }
  });

  const handleCreateStream = useCallback(async () => {
    if (newStreamErrors.length > 0) {
      return;
    }
    if (newStream.id) {
      try {
        const response = await apiService.updateStream(newStream);
        if(response.data === false || response.statusCode !== 200){
          setNewStreamErrors(['name']);
          return;
        }
      } catch(e){
        setNewStreamErrors(['name']);
        return;
      }
    } else {
      try {
        if(typeof newStream.hidden !== 'boolean'){ newStream.hidden = false; }
  
        const response = await apiService.createStream(newStream);
        if(response.data === false || response.statusCode !== 200){
          setNewStreamErrors(['name']);
          return;
        }
      } catch(e){
        setNewStreamErrors(['name']);
        return;
      }
    }
    setNewStream({});
    history.push('/stream');
  }, [history, newStream, newStreamErrors.length]);

  const handleDeleteStream = useCallback(async (stream) => {
    await apiService.deleteStream(stream);
    setNewStream({});
    history.push('/stream');
  }, [history]);

  return (isError ? 
    <NotFound text="Not Found" /> : 
      isLoading ? 
        <NotFound text="Loading..." /> 
        : 
        <StreamEditContent 
          newStream={newStream} 
          newStreamErrors={newStreamErrors} 
          stream={stream} 
          setNewStream={setNewStream} 
          setNewStreamErrors={setNewStreamErrors} 
          handleDeleteStream={handleDeleteStream} 
          handleCreateStream={handleCreateStream} 
          />);
};

export default StreamEdit;