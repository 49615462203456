// Core
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';

// Chakra imports
import {
  AbsoluteCenter,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  IconButton,
  Link,
  Text,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import logo from 'assets/img/logo.png';
import { ReactComponent as QrCodeIcon } from 'assets/svg/qr-code.svg';
import { ReactComponent as SMSCodeIcon } from 'assets/svg/sms.svg';
import { ReactComponent as EmailCodeIcon } from 'assets/svg/email.svg';
import check from 'assets/svg/check.svg';

// Components
import TwoFAQRModal from 'components/Modals/TwoFA/QR';
import TwoFAEmailModal from 'components/Modals/TwoFA/Email';
import TwoFAPhoneModal from 'components/Modals/TwoFA/Phone';

function SignIn() {
  // Chakra color mode
  const ttlColor = useColorModeValue('ttl.300');
  const borderColor = useColorModeValue('gray.200');

  const textColor = useColorModeValue('gray.800', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];

  const [show, setShow] = React.useState(false);
  
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const [isValid, setIsValid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiError, setIsApiError] = useState(false);

  const [isValidInputLogin, setIsValidInputLogin] = useState(true);  
  const [isValidInputPassword, setIsValidInputPassword] = useState(true);  

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [activedTwoFA, setActivedTwoFA] = useState({ email: true, phone: true, google: false });

  const handleLoginChange = (event) => {
    const inputLogin = event.target.value;
    setLogin(inputLogin);
    setIsValid(inputLogin.length > 0 && password.length > 0 ? true : false);
    setIsValidInputLogin(true);
    setIsApiError(false);
  };
  const handlePasswordChange = (event) => {
    const inputPassword = event.target.value;
    setPassword(inputPassword);
    setIsValid(inputPassword.length > 0 && login.length > 0 ? true : false);
    setIsValidInputPassword(true);
    setIsApiError(false);
  };

  const handlePassClick = () => setShow(!show);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      const response = await apiService.login({login, password});
      if(response.statusCode === 200){
        if(response?.data?.user.role === 'User'){
          setIsError(true);
          setIsLoading(false); 
          setIsApiError(true);
          setIsValidInputLogin(false);
          setIsValidInputPassword(false);
        } else {
          if(response?.data?.token){
            localStorage.setItem('auth', JSON.stringify(response.data));
            setIsLoading(true);
            window.location.replace('/#/partners');
          } else if(response?.data.todo === '2fa_authentication'){ 
            onOpen();
            setActivedTwoFA(response?.data.types);
            setIsLoading(false);
          }
        }
      } else {
        setIsError(true);
        setIsLoading(false); 
        setIsApiError(true);
        setIsValidInputLogin(false);
        setIsValidInputPassword(false);
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      setIsValidInputLogin(false);
      setIsValidInputPassword(false);
      setIsApiError(true);
    }

    if(isError){
      setIsValidInputLogin(false);
      setIsValidInputPassword(false);
    }
  };

  // 2FA
  const [twoFaSelect, setTwoFaSelect] = useState(0);

  const [seconds, setSeconds] = useState(59);
  const [timerActive, setTimerActive] = useState(false);

  const [dataUser, setDataUser] = useState({ email: '', phone: '' });

  const { isOpen: isOpenTwoFAPhone, onOpen: onOpenTwoFAPhone, onClose: onCloseTwoFAPhone } = useDisclosure();
  const { isOpen: isOpenTwoFAEmail, onOpen: onOpenTwoFAEmail, onClose: onCloseTwoFAEmail } = useDisclosure();
  const { isOpen: isOpenTwoFAQR, onOpen: onOpenTwoFAQR, onClose: onCloseTwoFAQR } = useDisclosure();

  const handleSelect2Fa = async (event) => {
    if(twoFaSelect === 1){
      onOpenTwoFAQR();
    } else if (twoFaSelect === 2){
      onOpenTwoFAPhone();
    } else if (twoFaSelect === 3){
      onOpenTwoFAEmail();
    }
  };

  // Timer for send code
  useEffect(() => {
    let interval = null;
    
    if (timerActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (!timerActive && seconds !== 0) {
      clearInterval(interval);
    } else if (seconds === 0) {
      setTimerActive(false);
    }

    return () => clearInterval(interval);
  }, [timerActive, seconds]);

  const startTimer = () => {
    setTimerActive(true);
    setSeconds(59);  
  };

  return (
    <Flex
      direction='column'
      w='100%'
      background='transparent'
      p='0px'
      mt={{ md: '150px', lg: '80px' }}>
      <Box
        mb='64px'
        h='100%'
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <NavLink to="/">
          <Image
            src={logo}
            alt='lmctplus image'
            maxWidth="300px"
          />
        </NavLink>
      </Box>
      <Heading color={ttlColor} fontSize='40px' mb='0px'>
        Sign in to Admin Panel
      </Heading>
      <Text
        mb='30px'
        ms='0px'
        color='#E1E1E1'
        fontWeight='400'
        fontSize='16px'>
        Please enter your details to create an account
      </Text>
      <>
        <FormControl variant="authLabel" id="login">
          <Input
            value={login}
            onChange={handleLoginChange}
            borderColor={borderColor}
            className={isValidInputLogin ? 'input-valid' : 'input-invalid'}
            type='text'
            placeholder=''
            autoComplete="off"
          />
          <FormLabel htmlFor="login" className={isValidInputLogin ? 'input-valid' : 'input-invalid'}>Login</FormLabel>
          <FormHelperText mt='4px' mb='20px' fontSize='12px' mx="10px" color="#E1E1E1" opacity="0.8">* Enter username, email or phone number</FormHelperText>
        </FormControl>
        <FormControl variant="authLabel" id="password">
          <InputGroup size='md'>
            <Input
              value={password}
              onChange={handlePasswordChange}
              borderColor={borderColor}
              className={isValidInputPassword ? 'input-valid' : 'input-invalid'}
              mb='8px'
              placeholder=''
              autoComplete="off"
              type={show ? 'text' : 'password'}
            />
            <FormLabel htmlFor="password" className={isValidInputPassword ? 'input-valid' : 'input-invalid'}>Password</FormLabel>
            <FormHelperText className={isApiError ? 'input-error' : 'hidden'}>User not found or wrong password</FormHelperText>
            <InputRightElement>
              <IconButton
                aria-label={show ? 'Hide password' : 'Show password'}
                icon={show ?  <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                onClick={handlePassClick}
                mt='14px'
                mr='14px'
                color='gray.200'
                fontSize='24px'
                variant='ghost'
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Box display='flex' alignItems='right' justifyContent='right' mb='32px'>
          <Link href="/#/auth/forgot-password" color="#0497FD">
          Recovery password?
          </Link>
        </Box>
        <Button
          onClick={handleSubmit}
          variant="submit"
          disabled={!isValid}
          isLoading={isLoading}
          className={isLoading ? 'freeze' : ''}
          loadingText='Sign in'
          mb='34px'>
        Sign in
        </Button>
        <Box 
          mb='34px'
          ms='0px'
          color='gray.50'
          fontWeight='400'
          position='relative'
          fontSize='16px'>
          <Divider 
            opacity='1'
            borderColor='#9FABCB' 
            borderTopWidth="0.05em" 
            borderBottomWidth="0"/>
          <AbsoluteCenter bg='#050A19' px='10px' my='-2px'>
            or
          </AbsoluteCenter>
        </Box>
      </>
      
      <Box display="flex" justifyContent="space-between">
        <Button 
          h='44px'
          borderRadius='10px'
          leftIcon={<FcGoogle fontSize="24px" />}
          colorScheme='unset'
          variant='outline'
          color='#099BFF'
          borderColor='#8794C2'
          w='calc(100% - 8px)'
          _hover={{
            borderColor: 'blue.200'
          }}
        >
          Google
        </Button>
        <Button 
          h='44px'
          borderRadius='10px'
          leftIcon={<BsFacebook fontSize="24px" />}
          colorScheme='unset'
          variant='outline'
          color='#099BFF'
          borderColor='#8794C2'
          w='100%'
          ml='14px'
          _hover={{
            borderColor: 'blue.200'
          }}
        >
          Facebook
        </Button>
      </Box>

      <> 
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
            <ModalCloseButton />
            <ModalBody>
              <Text variant="title" mb="4px" fontSize="24px" fontWeight="500">Two-Factor Authentication</Text>
              <Text variant="text-md" mb="16px">Examination</Text>
              <Text mt="30px" fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>
                Choose the appropriate option
              </Text>

              <Box mb="-24px">
                <Box mt="12px" className={!activedTwoFA.google ? 'freeze' : ''}>
                  <Flex justify="space-between" w="100%" cursor="pointer"
                    p="6px 0 6px 0"
                    borderRadius="10px"
                    bg={twoFaSelect === 1 ? 'gray.100' : '' }
                    onClick={() => setTwoFaSelect(1)}>
                    <Flex pl={twoFaSelect === 1 ? '16px' : '0' } >
                      <Box mt="6px" className={twoFaSelect === 1 ? 'active-svg' : '' }>
                        <QrCodeIcon/>
                      </Box>
                      <Text ml="12px">
                        <Text fontSize={fontSizeTitle} color={twoFaSelect === 1 ? 'blue.200' : textColor} fontWeight={fontSizeTitleWeight}>QR Code</Text>
                        <Text mt="4px" variant="text-md">Scan the QR code</Text>
                      </Text>
                    </Flex>
                    <Image src={check} me="24px" className={twoFaSelect === 1 ? '': 'hidden' } />
                  </Flex>
                </Box>
                <Box mt="12px" className={!activedTwoFA.phone ? 'freeze' : ''}>
                  <Flex justify="space-between" w="100%" cursor="pointer"
                    p="6px 0 6px 0"
                    borderRadius="10px"
                    bg={twoFaSelect === 2 ? 'gray.100' : '' }
                    onClick={() => setTwoFaSelect(2)}>
                    <Flex pl={twoFaSelect === 2 ? '16px' : '0' } >
                      <Box mt="6px" className={twoFaSelect === 2 ? 'active-svg' : '' }>
                        <SMSCodeIcon />
                      </Box>
                      <Text ml="12px">
                            <Text fontSize={fontSizeTitle} color={twoFaSelect === 2 ? 'blue.200' : textColor} fontWeight={fontSizeTitleWeight}>SMS Code</Text>
                            <Text mt="4px" variant="text-md">Enter the code sent to your phone number</Text>
                      </Text>
                    </Flex>
                    <Image src={check} me="24px" className={twoFaSelect === 2 ? '': 'hidden' } />
                  </Flex>
                </Box>
                <Box mt="14px" className={!activedTwoFA.email ? 'freeze' : ''}>
                  <Flex justify="space-between" w="100%" cursor="pointer"
                    p="6px 0 6px 0"
                    borderRadius="10px"
                    bg={twoFaSelect === 3 ? 'gray.100' : '' }
                    onClick={() => setTwoFaSelect(3)}>
                    <Flex pl={twoFaSelect === 3 ? '16px' : '0' } >
                      <Box mt="6px" className={twoFaSelect === 3 ? 'active-svg' : '' }>
                        <EmailCodeIcon pt="10px" />
                      </Box>
                      <Text ml="12px">
                          <Text fontSize={fontSizeTitle} color={twoFaSelect === 3 ? 'blue.200' : textColor} fontWeight={fontSizeTitleWeight}>Email Code</Text>
                          <Text mt="4px" variant="text-md">Enter the code emailed to you</Text>
                      </Text>
                    </Flex>
                    <Image src={check} me="24px" className={twoFaSelect === 3 ? '': 'hidden' } />
                  </Flex>
                </Box>
              </Box>

              <Flex justify="space-between" mt="64px">
                <Text variant="text-md" fontWeight="500" mt="12px"></Text>
                <Button variant="submit-md" maxW="140px" disabled={twoFaSelect === 0 ? true : false} onClick={handleSelect2Fa}>
                  Next
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal> 

        <TwoFAQRModal 
          authRoute={true}
          login={login}
          password={password} 
          dataUser={dataUser}
          seconds={seconds}
          startTimer={startTimer}
          timerActive={timerActive}
          isOpenTwoFA={isOpenTwoFAQR}
          onOpenTwoFA={onOpenTwoFAQR}
          authRoute={true}
          onCloseTwoFA={onCloseTwoFAQR} />

        <TwoFAPhoneModal 
          authRoute={true}
          login={login}
          password={password} 
          dataUser={dataUser}
          seconds={seconds}
          startTimer={startTimer}
          timerActive={timerActive}
          isOpenTwoFA={isOpenTwoFAPhone}
          onOpenTwoFA={onOpenTwoFAPhone}
          onCloseTwoFA={onCloseTwoFAPhone} />

        <TwoFAEmailModal 
          authRoute={true}
          login={login}
          password={password} 
          dataUser={dataUser}
          seconds={seconds}
          startTimer={startTimer}
          timerActive={timerActive}
          isOpenTwoFA={isOpenTwoFAEmail}
          onOpenTwoFA={onOpenTwoFAEmail}
          onCloseTwoFA={onCloseTwoFAEmail} />
      </>
    </Flex>
  );
}

export default SignIn;
