// Core
import { HiDotsVertical } from 'react-icons/hi';
import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  InputGroup,
  InputLeftElement,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Components
import {
  DeleteIcon,
  EditIcon
} from 'components/Icons/Icons';
import EditModal from './TagsModal';
import Attention from 'components/Modals/Attention.js';

const TagsRow = ({
  row,
  handleDeleteObject,
  handleDataOpen
}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const paddingTd = '8px 0 16px 40px';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => {
    setShow(!show);
  });

  const attentionModal = async () => {
    onOpen();
  };

  return (
    <>
      <Tr key={row.id} border="unset">
        <Td verticalAlign="top" p={paddingTd}>
          <Text variant="text" pt="10px" color={textColor}>{row.name}</Text>
        </Td>
        <Td>
          <Flex gap="36px" justifyContent="flex-end" pr="10px">
            <EditIcon fontSize="24" color="white" onClick={() => handleDataOpen(row)} cursor="pointer" />
            <DeleteIcon fontSize="24" color="white" onClick={attentionModal} cursor="pointer" />

            <Attention
              variant="remove"
              handle={() => handleDeleteObject(row)}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </Flex>
        </Td>
      </Tr>
    </>
  );
};

export default TagsRow;