/*eslint-disable*/
// Core
import React, { useState } from "react";

// Chakra imports
import { Box, useColorModeValue } from "@chakra-ui/react";

// Components
import SidebarContent from "./SidebarContent";

function Sidebar(props) {
  // to check for active links and opened collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";

  const { logoText, routes, sidebarVariant, isSidebarStack, setSidebarStack } = props;

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = useColorModeValue("white", "gray.700");
    sidebarRadius = "16px";
    sidebarMargins = "16px 0px 16px 16px";
  }

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          //transition={variantChange}
          w="100%"
          overflow="scroll"
          maxW={!isSidebarStack ? "50px": "220px" }
          overflow="scroll"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="0px"
          pe="0px"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          <SidebarContent 
            routes={routes}
            display="none"
            sidebarVariant={sidebarVariant}
            isSidebarStack={isSidebarStack}
            setSidebarStack={setSidebarStack}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;
