// Core
import { useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputRightElement,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { validatePassword } from 'services/Utils';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const ChangeEmailModal = ({dataUser, setNotify, isOpenPassword, onOpenPassword, onClosePassword}) => {
  // Chakra color mode
  const textColor = useColorModeValue('gray.800', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];

  const [isLoading, setIsLoading] = useState(false);
  const [isValidInputCode, setIsValidInputCode] = useState(true);
  const [сode, setCode] = useState(''); 

  const [change, setChange] = useState({ old: '', new: '', re: '' });

  const [isValidInputOld, setIsValidInputOld] = useState(true); 
  const [isValidInputNew, setIsValidInputNew] = useState(true); 
  const [isValidInputRe, setIsValidInputRe] = useState(true); 

  const [step, setStep] = useState(1);

  const [isValidSteps, setValidSteps] = useState({step1: false, step2: false}); 

  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showRe, setShowRe] = useState(false);

  const handleShowPassOld = () => setShowOld(!showOld); 
  const handleShowPassNew = () => setShowNew(!showNew); 
  const handleShowPassRe = () => setShowRe(!showRe); 

  // Handles 
  const handleOld = (event) => {
    setChange({...change, old: event.target.value});
    setIsValidInputOld(true);
  };

  const handleNew = (event) => {
    setChange({...change, new: event.target.value});
    setIsValidInputNew(true);
  };

  const handleRe = (event) => {
    setChange({...change, re: event.target.value});
    setIsValidInputRe(true);
  };

  useEffect(() => {
    if (change?.old?.length > 0 
    	&& change?.new?.length > 0 
    	&& change?.re?.length > 0 
    ) {
      setValidSteps({...isValidSteps, step1: true});
    } else {
      setValidSteps({...isValidSteps, step1: false});
      return;
    }
  }, [change]); 

  const handleChange = async (event) => {
    event.preventDefault();

    let error = false;

    if (!validatePassword(change?.new)) {
      setIsValidInputNew(false);
      let error = true;
    }


    if(change?.new !== change?.re){
    	setIsValidInputRe(false);
    	error = true;
    }

    if(change?.new === change?.old){
      setIsValidInputRe(false);
      error = true;
    }

    if(error){
    	return;
    }

    try {
      const response = await apiService.updateUserPassword({ newPassword: change.new, currentPassword: change.old });
      if(response.statusCode === 200 && response.data === true){
        onClosePassword();
        setNotify({success: true, msg: 'Your old password was successfully updated to the new one.'});
        setStep(1);
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    if(error){
      setIsValidInputOld(false);
    }
  };

  return (
  	<> 
  	<Modal isOpen={isOpenPassword} onClose={onClosePassword}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">
        { step === 1 ? 'Password update' : 'Confirmation' }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          { step === 1 ? (
            <>
            <Text variant="text-md" mb="40px">Changing the old password to a new one.</Text>

            <FormControl variant="floating" id="old-password">
	            <Input type={showOld ? 'text' : 'password'} placeholder='' autoComplete="off" mb='40px' onChange={handleOld}  className={isValidInputOld ? 'input-valid' : 'input-invalid'}  />
	            <FormLabel htmlFor="old-password" className={isValidInputOld ? 'input-valid' : 'input-invalid'}>Old password</FormLabel>
	            <FormHelperText className={isValidInputOld ? 'hidden' : 'input-error' }>This is not your password</FormHelperText>
              <InputRightElement>
                <IconButton
                  aria-label={showOld ? 'Hide password' : 'Show password'}
                  icon={showOld ?  <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  onClick={handleShowPassOld}
                  mt='8px'
                  mr='14px'
                  color='gray.200'
                  fontSize='24px'
                  variant='ghost'
                />
              </InputRightElement>
	          </FormControl>

	          <FormControl variant="floating" id="new-password">
	            <Input type={showNew ? 'text' : 'password'} placeholder='' autoComplete="off" mb='40px' onChange={handleNew}  className={isValidInputNew ? 'input-valid' : 'input-invalid'}  />
	            <FormLabel htmlFor="new-password" className={isValidInputNew ? 'input-valid' : 'input-invalid'}>New password</FormLabel>
	            <FormHelperText className={isValidInputNew ? 'hidden' : 'input-error' }>Password needs 8+ chars, upper & lower case, numbers</FormHelperText>
	            <InputRightElement>
                <IconButton
                  aria-label={showNew ? 'Hide password' : 'Show password'}
                  icon={showNew ?  <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  onClick={handleShowPassNew}
                  mt='8px'
                  mr='14px'
                  color='gray.200'
                  fontSize='24px'
                  variant='ghost'
                />
              </InputRightElement>
            </FormControl>

	          <FormControl variant="floating" id="re-password">
	            <Input type={showRe ? 'text' : 'password'} placeholder='' autoComplete="off" mb='32px' onChange={handleRe}  className={isValidInputRe ? 'input-valid' : 'input-invalid'}  />
	            <FormLabel htmlFor="re-password" className={isValidInputRe ? 'input-valid' : 'input-invalid'}>Confirm your new password</FormLabel>
	            <FormHelperText className={isValidInputRe ? 'hidden' : 'input-error' }>Passwords are different or you are using the current password</FormHelperText>
	            <InputRightElement>
                <IconButton
                  aria-label={showRe ? 'Hide password' : 'Show password'}
                  icon={showRe ?  <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  onClick={handleShowPassRe}
                  mt='8px'
                  mr='14px'
                  color='gray.200'
                  fontSize='24px'
                  variant='ghost'
                />
              </InputRightElement>
            </FormControl>

	          <Flex justify="space-between">
	          	<Text variant="text-md" fontWeight="500" mt="12px">Step: 1/2</Text>
	          	<Button variant="submit-md" maxW="140px" disabled={!isValidSteps.step1} onClick={handleChange}>
		          Confirm
		        </Button>
	          </Flex>
	          </>
          ) : (
	          	<Text variant="text-md" mb="15px" maxW={{sm: '80%'}}>Your password has been successfully changed to a new one.</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default ChangeEmailModal;
