// Core
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FaPlus } from 'react-icons/fa6';

// Chakra
import { 
  Button, 
  Divider,
  Flex,
  useColorModeValue, 
  Table, 
  Text, 
  Thead, 
  Tr, 
  Td, 
  Tbody, 
  Select, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody,
  ModalFooter,
  Image
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { validateLoadMore } from 'services/Utils';

// Components
import BannerRow from './components/BannerRow';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import './Banners.css';
import { 
  NewBannerIcon,
  NewBannerActiveIcon,
  NewHeaderIcon,
  NewHeaderActiveIcon 
} from 'components/Icons/Icons';

const Banners = () => {
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');

  const [offset, setOffset] = useState(0);
  const [banners, setBanners] = useState();
  const [bannersMeta, setBannersMeta] = useState(); 
  const [bannerUpdater, setBannerUpdater] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [bannerToDelete, setBannerToDelete] = useState({});
  const [isBannerTypeSelectOpen, setIsBannerTypeSelectOpen] = useState(false);
  const [bannerType, setBannerType] = useState([
    {
      name: 'URL',
      slug: 'url',
      icon: 'NewBannerIcon',
      iconActive: 'NewBannerActiveIcon'
    },
    {
      name: 'Partner',
      slug: 'partner',
      icon: 'NewBannerIcon',
      iconActive: 'NewBannerActiveIcon'
    },
    {
      name: 'Game',
      slug: 'game',
      icon: 'NewBannerIcon',
      iconActive: 'NewBannerActiveIcon'
    },
    {
      name: 'Cashback',
      slug: 'cashback',
      icon: 'NewBannerIcon',
      iconActive: 'NewBannerActiveIcon'
    },
    {
      name: 'Giveaways',
      slug: 'giveaways',
      icon: 'NewBannerIcon',
      iconActive: 'NewBannerActiveIcon'
    }]
  );
  const [bannerTypeSelected, setBannerTypeSelected] = useState('partner');

  const userSite = process.env.REACT_APP_MAIN_SITE || '#';

  const { isLoading: isBannersLoading } = useQuery({
    queryKey: ['banners', offset],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getBanners({limit: 25, offset});
      if(offset === 0){
        setBanners(response.data);
      } else {
        setBanners([...banners, ...response.data]);
      }
      
      setBannersMeta(response.meta);
      return true;
    }
  });

  const onNewBannerButtonClicked = useCallback(() => {
    //setIsBannerTypeSelectOpen(true);
    history.push('/banners/new');
  });

  const onBannerRowClicked = useCallback((banner) => {
    history.push(`/banners/${banner.id}`);
  }, [history]);

  const handleDeleteBanner = useCallback(async (banner) => {
    await apiService.deleteBanner(banner);
    setBannerUpdater(bannerUpdater + 1);
    setIsDeleteModalOpen(false);
  }, [bannerUpdater]);

  const handleDeleteBannerOpen = useCallback((banner) => {
    setBannerToDelete(banner);
    setIsDeleteModalOpen(true);
  }, []);

  const loadMore = async () => {
    setOffset(offset + 25);
  };

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='0'>Banners</Text>
      <Divider opacity="1" mr="2px" borderColor='rgba(255, 255, 255, 0.10)' borderTopWidth="1px" borderBottomWidth="0" mb='32px'/>
      <Button variant="submit-ms" position="absolute" right="31px" top="80px" maxW="150px" onClick={onNewBannerButtonClicked} leftIcon={<FaPlus />}>Create</Button>
      <Card padding="8px 0 0 0" style={{overflow: 'hidden'}}>
        <CardBody>
          <Table color={textColor}>
            <Thead>
              <Tr>
                <Td><Text variant="title-md" p='0 5px 6px 0'>Banner appearance</Text></Td>
                <Td pl="0px"><Text variant="title-md" p='0 0 6px 5px'>Title</Text></Td>
                <Td><Text variant="title-md" p='0 0 6ox 0'>Type</Text></Td>
                <Td><Text variant="title-md" p='0 0 6ox 0'>Status</Text></Td>
                <Td><Text variant="title-md" p='0 0 6ox 0'></Text></Td>
              </Tr>
            </Thead>
            <Tbody>
              {isBannersLoading ? (<Tr border="unset"><Td border="unset"><Text variant="text-sm" p='0 0 6px 0'>Loading...</Text></Td></Tr>) : banners && banners.map(banner => (
                <BannerRow onDeleteBannerOpen={handleDeleteBannerOpen} handleEditBannerOpen={onBannerRowClicked} key={banner.id} banner={banner} />
              ))}
            </Tbody>
          </Table>
        </CardBody>

        { validateLoadMore(banners, bannersMeta) && (
          <Button variant="ghost" onClick={loadMore} m="16px auto" display="block">
            Show More
          </Button>
        )}
      </Card>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay />
        <ModalContent className="modal__simple" maxW={{ sm: '288px' }}>
          <ModalHeader p='0'>Attention</ModalHeader>
          <ModalCloseButton />
          <ModalBody p='0'>
            <Text variant="text-md" mb="16px">Important Notice</Text>
            <Text variant="text-md" mb="16px">Are you sure of your choice? Once deleted, it will be impossible to return the banner.</Text>
          </ModalBody>

          <ModalFooter p='0' mt='24px' >
            <Button variant='ghost' style={{color: '#1870EC'}} mr={3} onClick={() => setIsDeleteModalOpen(false)}>
              Close
            </Button>
            <Button variant="submit-md" onClick={() => handleDeleteBanner(bannerToDelete)}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Banners;