// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  InputGroup,
  InputLeftElement,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { getInitials, parseDateTime } from 'services/Utils';

// Components
import {
  LinkIcon
} from 'components/Icons/Icons';

const ReceiptRow = ({
  row,
  handleReceipt
}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const paddingTd = '8px 0 16px 40px';

  return (
    <>
      <Tr key={row.id} border="unset" className="cursor-tr">
        <Td>
          <Text variant="text" color={textColor} w="100%">{row.id}</Text>
        </Td>
        <Td verticalAlign="center" onClick={() => handleReceipt(row)}>
          <Flex gap="8px">
            { row?.userReceipt?.image ? (
              <Image src={row?.userReceipt?.image} w="41px" h="41px" borderRadius="full" objectFit="cover"/>
            ) : (
              <Box className={row?.userReceipt?.gender === 'Male' ? 'gender-male' : row?.userReceipt?.gender === 'Female' ? 'gender-female' : row?.userReceipt?.gender === 'Transgender' ? 'gender-other' : 'gender-none' }>
                <Text>{getInitials(row?.userReceipt?.firstName, row?.userReceipt?.lastName)}</Text>
              </Box>
            )}

            <Text variant="text" mt="11px" color={textColor}>{row?.userReceipt?.firstName} {row?.userReceipt?.lastName}</Text>
          </Flex>
        </Td>
        <Td verticalAlign="center" onClick={() => handleReceipt(row)}>
          <Text variant="text" color={textColor}>{parseDateTime(row?.createdAt)}</Text>
        </Td>
        <Td verticalAlign="center" onClick={() => handleReceipt(row)}>
          <Text variant="text" color={textColor}>{row.partnerReceipt?.name}</Text>
        </Td>
        <Td verticalAlign="center" onClick={() => handleReceipt(row)}>
          <Text variant="text" color={textColor}>{parseDateTime(row?.purchase)}</Text>
        </Td>
        <Td verticalAlign="center" onClick={() => handleReceipt(row)}>
          <Text variant="text" color={textColor}>${row?.amount / 100}</Text>
        </Td>
        <Td verticalAlign="center" onClick={() => handleReceipt(row)}>
          <Text 
            variant="text-md" 
            mt="-2px"
            minW="110px"
            className={row.status === 'approved' ? 'tag-green' : row.status === 'pending' ? 'tag-orange' : 'tag-red'} 
            textTransform="capitalize">{row.status}</Text>
        </Td>
        <Td verticalAlign="center" onClick={() => handleReceipt(row)}>
          <Text 
            variant="title"  
            color="#57D175">
            {row.cashback ?? '--'}
          </Text>
        </Td>
      </Tr>
    </>
  );
};

export default ReceiptRow;