// Core
import { useCallback,useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Table,
  Tbody,
  Tr,
  Td,
  Text, 
  Textarea,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';

// Components
import { GiveawaysActiveIcon, DateTimeInputIcon } from 'components/Icons/Icons';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import uploadImage from 'assets/img/upload.png';

const GiveawaysEditContent = ({ 
  newObject,
  newObjectErrors,
  setNewObject,
  setSelectedFile,
  setNewObjectErrors,
  handleCreateObject,
  handleDelete
}) => {
  const paddingTd = '16px 24px 24px 24px';
  const borderColor = useColorModeValue('white', 'white');
  const widthTitleTd = '25%';

  const isValidInputTitle = useMemo(() => !newObjectErrors.includes('title'), [newObjectErrors]);
  const isValidInputDescription = useMemo(() => !newObjectErrors.includes('description'), [newObjectErrors]);
  const isValidInputFrom = useMemo(() => !newObjectErrors.includes('startDate'), [newObjectErrors]);
  const isValidInputTo = useMemo(() => !newObjectErrors.includes('endDate'), [newObjectErrors]);
  const isValidInputStatus = useMemo(() => !newObjectErrors.includes('status'), [newObjectErrors]);
  const isValidInputType = useMemo(() => !newObjectErrors.includes('type'), [newObjectErrors]);

  const handleNewTitle = useCallback((e) => { setData(1, e.target.value, 'title'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDescriptionChange = useCallback((e) => { setData(5, e.target.value, 'description'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewFromChange = useCallback((e) => { setData(0, e.target.value, 'from'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewToChange = useCallback((e) => { setData(0, e.target.value, 'to'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewStatusChange = useCallback((e) => { setData(0, e.target.value, 'status'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewTypeChange = useCallback((e) => { setData(0, e.target.value, 'type'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  
  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleClickImage = () => {
    document.getElementById('fileInput').click(); 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, image: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/giveaways');
  }, [history, setNewObject]);

  return (
    <Box zIndex="9" position="relative">
      {
        newObject?.id ? (
          <Breadcrumbs icon={GiveawaysActiveIcon} target='Giveaways' url='#/giveaways' current='Giveaway'  />
        ) : (
          <Breadcrumbs icon={GiveawaysActiveIcon} target='Giveaways' url='#/giveaways' current='New Giveaway' />
        )
      }

      <Flex gap="16px" position="absolute" mt="-92px" right="0">
        {!newObject?.id && <Button variant="ghost-md" minW="120px" onClick={() => handleCancelClick()}>Cancel</Button>}
        {newObject?.id && <Button variant="submit-md-delete" disabled w="120px" onClick={() => handleDelete(newObject)}>Delete</Button>}
        <Button  variant="submit-md" w="120px" disabled={newObject?.id ? false : true} onClick={handleCreateObject}>{newObject?.id ? 'Confirm' : 'Create'}</Button>
      </Flex>

      <Box maxW="840px">
        <Card padding="24px" mb="40px">
          <Flex gap="24px">
            <Image 
              src={newObject?.image ? newObject?.image : uploadImage} 
              borderRadius="10px"
              objectFit="cover"
              w="160px" 
              h="90px" />
            <Box>
              <Text 
                variant="title" 
                color="#099BFF" 
                cursor="pointer" 
                onClick={handleClickImage}>
                { newObject?.image ? 'Change picture' : 'Add picture' }
              </Text>

              <Flex gap="3px">
                <Text variant="text-md">Maximum size:</Text>
                <Text variant="text-md" fontWeight="500">5 mb</Text>  
              </Flex>

              <Flex gap="3px">
                <Text variant="text-md">Supporting Image Type:</Text>
                <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
              </Flex>
              
            </Box>
          </Flex>

          <input
            id="fileInput"
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Card>

        <Text variant="title-lg" mt="40px" mb="14px">Giveaways info</Text>
        <Card padding="24px" mb="40px">
            <FormControl variant="floating" id="title">
              <Input value={newObject?.title} type='text' placeholder='' autoComplete="off" mb='24px' onChange={handleNewTitle}  className={isValidInputTitle ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="title" className={isValidInputTitle ? 'input-valid' : 'input-invalid'}>Title</FormLabel>
              <FormHelperText className={isValidInputTitle ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <FormControl variant="floating" id="description">
              <Textarea
                  h={{ base: '150px !important' }}
                  resize="none" value={newObject?.description} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewDescriptionChange}  className={isValidInputDescription ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="description" className={isValidInputDescription ? 'input-valid' : 'input-invalid'}>Description</FormLabel>
              <FormHelperText className={isValidInputDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>
        </Card>

        <Text variant="title-lg" mt="40px" mb="12px">Options</Text>
        <Card padding='8px 0' my={{ sm: '22px', xl: '0px' }}>
          <Table>
            <Tbody>
              <Tr>
                <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                  <Text variant="title">Status</Text>
                </Td>
                <Td verticalAlign="top" p={paddingTd}>
                  <FormControl variant="floating" mb='28px' >
                      <Select placeholder="Status" value={newObject?.status} onChange={handleNewStatusChange} className={isValidInputStatus ? 'input-valid' : 'input-invalid'}>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="finished">Finished</option>
                      </Select>
                      <FormHelperText className={isValidInputStatus ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                  </FormControl>

                  <FormControl variant="floating" mb='28px' position="relative">
                    <Input value={
                      newObject?.startDate ? formatDate(newObject?.startDate) : null
                    } type='datetime-local' onChange={handleNewFromChange} className={isValidInputFrom ? 'input-valid' : 'input-invalid'}/>
                    <FormLabel className={isValidInputFrom ? 'input-valid' : 'input-invalid'}>Start</FormLabel>
                    <FormHelperText className={isValidInputFrom ? 'hidden' : 'input-error' }>From</FormHelperText>
                    <DateTimeInputIcon 
                      fontSize="24px"
                      position="absolute"
                      right="15px"
                      top="15px"
                      color="#9FABCB"
                      pointerEvents="none"
                      zIndex="1"
                    />
                  </FormControl>

                  <FormControl variant="floating" >
                    <Input value={
                      newObject?.endDate ? formatDate(newObject?.endDate) : null
                    } type='datetime-local' onChange={handleNewToChange} className={isValidInputTo ? 'input-valid' : 'input-invalid'}/>
                    <FormLabel className={isValidInputTo ? 'input-valid' : 'input-invalid'}>End</FormLabel>
                    <FormHelperText className={isValidInputTo ? 'hidden' : 'input-error' }>To</FormHelperText>
                    <DateTimeInputIcon 
                      fontSize="24px"
                      position="absolute"
                      right="15px"
                      top="15px"
                      color="#9FABCB"
                      pointerEvents="none"
                      zIndex="1"
                    />
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}} border="unset">
                  <Text variant="title">Type</Text>
                </Td>
                <Td verticalAlign="top" p={paddingTd} border="unset">
                  <FormControl variant="floating" mb='28px' >
                      <Select placeholder="Type" value={newObject?.type} onChange={handleNewTypeChange} className={isValidInputType ? 'input-valid' : 'input-invalid'}>
                          <option value="member">Member</option>
                          <option value="major">Major</option>
                      </Select>
                      <FormHelperText className={isValidInputType ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                  </FormControl>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Card>

        <Text variant="title-lg" mt="40px" mb="12px">Prizes</Text>
        <Card padding='8px 0' my={{ sm: '22px', xl: '0px' }}>
          <Table>
            <Tbody>
              { newObject?.prizes?.map((row, index) => (
                <Tr key={index}>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                    <Text variant="title">Prize {index + 1}</Text>
                  </Td>
                  <Td verticalAlign="top" p={paddingTd}>
                    <Text variant="text">{row.title}</Text>
                    <Text variant="text">{row.description}</Text>
                    <Text variant="text">Winner user id: {row.winner}</Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      </Box>
    </Box>
  );
};

export default GiveawaysEditContent;