// Core
import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory, useLocation } from 'react-router-dom';

// Assets
import { apiService } from 'services/ApiService';

// Components
import BannerEditContent from './components/BannerEditContent';

const BannersEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [newObjectErrors, setNewObjectErrors] = useState([]);
  const [newObject, setNewObject] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const { isLoading, isError } = useQuery({
    queryKey: [id],
    refetchOnWindowFocus: false,
    refetchOnError: false,
    queryFn: async () => {
      if (id === 'new') {
        setNewObject({});
      } else {
        const response = await apiService.getBanner({id});
        setNewObject(response.data);
      }
      return true;
    }
  });

  const handleCreateObject = useCallback(async () => {
    if (newObjectErrors.length > 0) {
      return;
    }

    let objectId = newObject.id;

    if (newObject.id) {
      try {
        const data = newObject;
        delete data.image;
        const response = await apiService.updateBanner(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['title']);
          return;
        }
      } catch(e){
        setNewObjectErrors(['title']);
        return;
      }
    } else {
      try {
        const data = newObject;
        delete data.image;
        const response = await apiService.createBanner(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['title']);
          return;
        }

        objectId = response?.data?.id;
      } catch(e){
        setNewObjectErrors(['title']);
        return;
      }
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('itemType', 'banner');
      formData.append('sync', true);
      formData.append('itemId', objectId);

      try {
        const response = await apiService.uploadImage(formData); 
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    setNewObject({});
    history.push('/banners');
  }, [history, newObject, newObjectErrors.length]);

  return (isError ? 
    <div>Not Found</div> : isLoading ? 
    <div>Loading...</div> : 
    <BannerEditContent 
      newObject={newObject} 
      newObjectErrors={newObjectErrors} 
      setNewObject={setNewObject} 
      setSelectedFile={setSelectedFile}
      setNewObjectErrors={setNewObjectErrors} 
      handleCreateObject={handleCreateObject} 
    />);
};

export default BannersEdit;