// Core
import { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import VerificationInput from 'react-verification-input';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const TwoFAPhoneModal = ({
    authRoute, 
    login,
    password,
    dataUser, 
    setNotify, 
    seconds, 
    startTimer, 
    timerActive, 
    data2fa,
    setData2fa,
    isOpenTwoFA, 
    onOpenTwoFA, 
    onCloseTwoFA
  }) => {
  const textColor = useColorModeValue('gray.800', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];

  const [isLoading, setIsLoading] = useState(false);
  const [isValidInput, setIsValidInput] = useState(true);
  const [isValidInputCode, setIsValidInputCode] = useState(true);
  const [сode, setCode] = useState(''); 

  const [twoFAData, setTwoFAData] = useState(dataUser.phone);

  const [step, setStep] = useState(1);

  const [isValidSteps, setValidSteps] = useState({step1: true, step2: false}); 

  const handleInput = value => {
    setTwoFAData(value);
    setIsValidInput(true);
    setValidSteps({...isValidSteps, step1: value.length > 0 ? true : false });
  };

  const handleChange = async (event) => {
    event.preventDefault();

    let error = false;

    console.log(dataUser.phone);

    if(twoFAData?.length === 0 && dataUser?.phone?.length === 0){
      setIsValidInput(false);
      error = true;
    }

    if(error){
      return;
    }
    
    try {
      if(authRoute){
          const response = await apiService.authTwoFA({ login: (twoFAData?.replace('+', '')), password, type: 'phone' });
          if(response.statusCode === 200){
            setStep(2);
            startTimer(); // stop
            startTimer(); // start
          } else {
            error = true;
          }
      } else {
        const response = await apiService.userTwoFA({ type: 'phone' });
        if(response.statusCode === 200 && response.data.status === true){
          setStep(2);
          startTimer(); // stop
          startTimer(); // start
        } else {
          error = true;
        }
      }
    } catch (e) { error = true; }

    if(error){
      setIsValidInput(false);
    }
  };

  const handleCode = value => { 
    setCode(value);
    if(value?.length === 6){
      handleSubmitChange(value);
    } 

    setIsValidInputCode(true);
  };

  const handleSubmitChange = async (code) => {
    let error = false;

    setIsLoading(true);

    try {
      if(authRoute){
        const response = await apiService.authTwoFA({login: twoFAData?.replace('+', ''), password, type: 'phone', code});
        if(response?.data?.token){
            localStorage.setItem('auth', JSON.stringify(response.data));
            window.location.replace('/#/');
        } else {
          error = true;
        }
        error = true;
      } else {
        const response = await apiService.userTwoFA({type: 'phone', code});
        if(response.statusCode === 200 && response.data.status === true){
          onCloseTwoFA();
          if(data2fa.phone === false){
            setNotify({success: true, msg: 'Phone 2FA is enabled'});
            setData2fa({...data2fa, phone: true });
          } else {
            setNotify({success: true, msg: 'Phone 2FA is disabled'});
            setData2fa({...data2fa, phone: false });
          }
          setStep(1);
        } else {
          error = true;
        }
        error = true;
      }
    } catch (e) { error = true; }

    if(error){
      setIsLoading(false);
      setIsValidInputCode(false);
    }
  };

  return (
  	<> 
  	<Modal isOpen={isOpenTwoFA} onClose={onCloseTwoFA}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <Text variant="title" fontSize="24px" fontWeight="500" mb="2px">
          Two-Factor Authentication
        </Text>
        <Text variant="text-md" mb="12px">Additional level of security</Text>
        <ModalCloseButton />
        <ModalBody>
          <Text mt="16px" variant="title">Enter phone</Text>
          <Text mt="4px" variant="text-md">Enter your phone to which a confirmation code will be sent</Text>

          {!authRoute ? (
            <FormControl variant="floating" id="phone" mt="20px">
              <Input disabled={dataUser.phone ? true : false }  type='text' value={dataUser.phone ? dataUser.phone : twoFAData } placeholder='' autoComplete="off" mb='40px' 
                onChange={handleInput}  className={isValidInput ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="phone" className={isValidInput ? 'input-valid' : 'input-invalid'}>Phone</FormLabel>
              <FormHelperText className={isValidInput ? 'hidden' : 'input-error' }>This is not your phone</FormHelperText>
            </FormControl>
          ) : (
            <FormControl mt="20px">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                onChange={handleInput}
                mb='24px'
                pt='15px'
                type='text'
                placeholder=''
                color='gray.200'
                className={isValidInput ? 'input-valid' : 'input-invalid'}
              />
              <FormHelperText className={isValidInput ?  'hidden' : 'input-error' }>This is not your phone</FormHelperText>
            </FormControl>
          )}

          { step === 1 ? (
          <Flex justify="space-between" mt="-8px">
            <Text variant="text-md" fontWeight="500"></Text>
            <Button 
              variant="submit-md" 
              maxW="140px" 
              disabled={!isValidSteps.step1} 
              onClick={handleChange}
              isLoading={isLoading}
              >
                Next
            </Button>
          </Flex>
          ) : (
            <>
              <Divider opacity="1" mr="2px" borderColor='blue.50' borderTopWidth="1px" borderBottomWidth="0"/>
              <Text mt="16px" variant="title">
                Verify your code 
                { isLoading && (
                  <Spinner size="sm" color="blue.200" m="0 0 -2px 8px"/>
                )}
              </Text>
              <Text mt="4px" variant="text-md" mb="-7px">Enter the code sent to your number 6-digit confirmation code.</Text>
              <VerificationInput
                length={6}
                placeholder=""
                validChars="0-9"
                classNames={{
                  container: `code-container ${isValidInputCode ? 'input-valid-code' : 'input-invalid-code'}`,
                  character: `code-character ${isLoading ? 'input-freeze-code' : ''}`,
                  characterInactive: 'character--inactive',
                  characterSelected: 'character--selected',
                }}
                onChange={handleCode}
              />

              {!isLoading && (
                <>
                  <Text mt="5px" variant="text-md" className={!timerActive ? 'hidden' : ''}>Resend via: <Text as="span" variant="text-md" color="blue.200">
                    {Math.floor(seconds / 60)}:{seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                    </Text>
                  </Text>
                  <Flex justify="space-between" mt="32px">
                    <Text variant="text-md" fontWeight="500" mt="12px">Step 2/2</Text>
                    <Button variant="submit-md" maxW="140px" disabled={timerActive} onClick={handleChange}>
                      Try again
                    </Button>
                  </Flex>
                </>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default TwoFAPhoneModal;
