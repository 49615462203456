// Core
import { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import VerificationInput from 'react-verification-input';

// Chakra 
import { 
  Button,
  Divider,
  Flex, 
  FormControl,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

const ChangePhoneModal = ({dataUser, setNotify, seconds, startTimer, timerActive, isOpenPhone, onOpenPhone, onClosePhone}) => {

  const textColor = useColorModeValue('gray.800', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];

  const [isLoading, setIsLoading] = useState(false);
  const [isValidInputCode, setIsValidInputCode] = useState(true);
  const [сode, setCode] = useState(''); 

  const [change, setChange] = useState({ old: '', new: '', re: '' });

  const [isValidInputOld, setIsValidInputOld] = useState(true); 
  const [isValidInputNew, setIsValidInputNew] = useState(true); 
  const [isValidInputRe, setIsValidInputRe] = useState(true); 

  const [step, setStep] = useState(1);

  const [isValidSteps, setValidSteps] = useState({step1: false, step2: false}); 

  // Handles 
  const handleOld = value => {
    setChange({...change, old: value});
    setIsValidInputOld(true);
  };

  const handleNew = value => {
    setChange({...change, new: value});
    setIsValidInputNew(true);
  };

  const handleRe = value => {
    setChange({...change, re: value});
    setIsValidInputRe(true);
  };

  useEffect(() => {
    if (change?.old?.length > 0 
      && change?.new?.length > 0 
      && change?.re?.length > 0 
    ) {
      setValidSteps({...isValidSteps, step1: true});
    } else {
      setValidSteps({...isValidSteps, step1: false});
      return;
    }
  }, [change]); 

  const handleChange = async (event) => {
    event.preventDefault();

    let error = false;

    if(change?.old !== `+${dataUser.phone}`){
      setIsValidInputOld(false);
      error = true;
    }

    if(change?.new !== change?.re){
      setIsValidInputRe(false);
      error = true;
    }

    if(change?.new === change?.old){
      setIsValidInputNew(false);
      error = true;
    }

    if(error){
      return;
    }

    try {
      const response = await apiService.updateUserPhone({ phone: change.new });
      if(response.statusCode === 200 && response.data.status === true) {
        setStep(2);
        startTimer(); // stop
        startTimer(); // start
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    if(error){
      setIsValidInputOld(false);
    }
  };

  const handleCode = value => { 
    setCode(value);
    if(value?.length === 6){
      handleSubmitChange(value);
    } 

    setIsValidInputCode(true);
  };

  const handleSubmitChange = async (code) => {
    let error = false;

    setIsLoading(true);

    try {
      const response = await apiService.updateUserEmail({email: change.new, code});
      if(response.statusCode === 200 && response.data.status === true){
        setIsLoading(true);
        onClosePhone();
        setNotify({success: true, msg: 'Phone has been changed'});
        setStep(1);
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    if(error){
      setIsLoading(false);
      setIsValidInputCode(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpenPhone} onClose={onClosePhone}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">
        { step === 1 ? 'Phone number update' : 'Confirmation' }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          { step === 1 ? (
            <>
              <Text variant="text-md" mb="12px">Due to the name change, this kyc may stop working.</Text>
              <Flex justifyContent="flex-end" w="100%" mb="38px">
                <Divider opacity="1" mr="2px" borderColor='blue.200'  borderTopWidth="4px" borderBottomWidth="0"/>
                <Divider opacity="1" borderColor='gray.100'  borderTopWidth="4px" borderBottomWidth="0" />
              </Flex>

              <FormControl>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  onChange={handleOld}
                  value={change.old}
                  mb='24px'
                  pt='15px'
                  type='text'
                  placeholder=''
                  color='gray.200'
                  className={isValidInputOld ? 'input-valid' : 'input-invalid'}
                />
                <FormHelperText className={isValidInputOld ?  'hidden' : 'input-error' }>This is not your phone</FormHelperText>
              </FormControl>
              <FormControl>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  onChange={handleNew}
                  value={change.new}
                  mb='24px'
                  pt='15px'
                  type='text'
                  placeholder=''
                  color='gray.200'
                  className={isValidInputNew ? 'input-valid' : 'input-invalid'}
                />
                <FormHelperText className={isValidInputNew ?  'hidden' : 'input-error' }>The phone must be different from the current one</FormHelperText>
              </FormControl>
              <FormControl>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  onChange={handleRe}
                  value={change.re}
                  mb='24px'
                  pt='15px'
                  type='text'
                  placeholder=''
                  color='gray.200'
                  className={isValidInputRe ? 'input-valid' : 'input-invalid'}
                />
                <FormHelperText className={isValidInputRe ?  'hidden' : 'input-error' }>Phone different</FormHelperText>
              </FormControl>

              <Flex justify="space-between">
                <Text variant="text-md" fontWeight="500" mt="12px">Step: 1/2</Text>
                <Button variant="submit-md" maxW="140px" disabled={!isValidSteps.step1} onClick={handleChange}>
                Confirm
              </Button>
              </Flex>
            </>
          ) : (
            <>
              <Text variant="text-md" mb="15px" maxW={{sm: '80%'}}>To confirm your email, you will need to enter the code from the SMS that we sent to your email.</Text>
              <Flex justifyContent="flex-end" w="100%">
                <Divider opacity="1" mr="2px" borderColor='blue.200'  borderTopWidth="4px" borderBottomWidth="0"/>
                <Divider opacity="1" borderColor='blue.200'   borderTopWidth="4px" borderBottomWidth="0" />
              </Flex>
              <Text mt="30px" fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>
                Verify your code 
                { isLoading && (
                  <Spinner size="sm" color="blue.200" m="0 0 -2px 8px"/>
                )}
              </Text>
              <Text mt="5px" variant="text-md" mb="-7px">Enter the code sent to your number 6-digit confirmation code.</Text>
              <VerificationInput
                length={6}
                placeholder=""
                validChars="0-9"
                classNames={{
                  container: `code-container ${isValidInputCode ? 'input-valid-code' : 'input-invalid-code'}`,
                  character: `code-character ${isLoading ? 'input-freeze-code' : ''}`,
                  characterInactive: 'character--inactive',
                  characterSelected: 'character--selected',
                }}
                onChange={handleCode}
              />
              {!isLoading && (
                <>
                  <Text mt="5px" variant="text-md" className={!timerActive ? 'hidden' : ''}>Resend via: <Text as="span" variant="text-md" color="blue.200">
                    {Math.floor(seconds / 60)}:{seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                    </Text>
                  </Text>
                  <Flex justify="space-between" mt="32px">
                    <Text variant="text-md" fontWeight="500" mt="12px">Step: 2/2</Text>
                    <Button variant="submit-md" maxW="140px" disabled={timerActive} onClick={handleChange}>
                      Try again
                    </Button>
                  </Flex>
                </>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default ChangePhoneModal;
