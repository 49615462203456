import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import AuthLayout from 'layouts/Auth.js';
import DashboardLayout from 'layouts/Dashboard.js';
import PrivateRoute from './private';

const queryClient = new QueryClient();

ReactDOM.render(
  <HashRouter>
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <PrivateRoute path="/" component={DashboardLayout} />
      </Switch>
    </QueryClientProvider>
  </HashRouter>,
  document.getElementById('root')
);
