import { mode } from '@chakra-ui/theme-tools';

const activeStyles = {
  top: 0,
  left: 0,
  zIndex: 2,
  position: 'absolute',
  pointerEvents: 'none',
  mx: 3.5,
  px: 1,
  my: 3.5,
  transformOrigin: 'left top',
  color: '#9FABCB',
  fontWeight: '400',
  borderRadius: '6px'
};

const activeLabelStyles = {
  fontSize: '14px',
  transform: 'scale(0.85) translateY(-28px)'
};

export const formStyles = {
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,

                color: '#099BFF'
              }
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
              ...activeLabelStyles
            },
            label: (props) => ({
              ...activeStyles,
              top: '3px',
              backgroundColor: mode('#0E1633', '#0E1633')(props),
            })
          }
        },
        search: {
          container: {
            input: (props) => ({
              h: '40px',
              w: '326px',
              padding: '0 0 0 45px !important',
              bg: mode('#0E1633', '#0E1633')(props),
              borderRadius: '16px !important',
              border: 'unset !important',
              _hover: {
                boxShadow: 'unset !important',
              },
              _focus:{
                boxShadow: 'unset !important',
                borderColor: 'unset !important'
              },
              _active: {
                boxShadow: 'unset !important',
                borderColor: 'unset !important'
              },
              _placeholder: {
                color: '#8794C2 !important'
              },
            }),
            'svg': (props) => ({
              'path': {
                fill: mode('#8794C2', '#8794C2')(props),
              }
            }),
            '&.search-active': {
              'path': {
                fill: '#099BFF !important'
              }
            },
          }
        },
        authLabel: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
                color: '#099BFF'
              }
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
              ...activeLabelStyles
            },
            label: (props) => ({
              ...activeStyles,
              backgroundColor: mode('#050A19', '#050A19')(props),
            })
          }
        },
        popup: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
                color: '#099BFF'
              }
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
              ...activeLabelStyles
            },
            label: (props) => ({
              ...activeStyles,
              backgroundColor: mode('#1A243E', '#1A243E')(props),
            })
          }
        },
        card: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
                color: '#099BFF'
              }
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
              ...activeLabelStyles
            },
            label: (props) => ({
              ...activeStyles,
              backgroundColor: mode('#151A32', '#151A32')(props),
            })
          }
        }
      }
    }
  }
};