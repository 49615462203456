// Core
import { useCallback,useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Table,
  Tbody,
  Tr,
  Td,
  Text, 
  Textarea,
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';

// Components
import { OffersActiveIcon } from 'components/Icons/Icons';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import uploadImage from 'assets/img/upload.png';

const OffersEditContent = ({ 
  newObject, 
  newObjectErrors, 
  setNewObject, 
  setNewObjectErrors, 
  setSelectedFile,
  handleDeleteOffers, 
  handleCreateOffers,
  handleCreateObject,
  onDeleteOffersOpen,
}) => {

  const isValidInputTitle = useMemo(() => !newObjectErrors.includes('title'), [newObjectErrors]);
  const isValidInputDescription = useMemo(() => !newObjectErrors.includes('description'), [newObjectErrors]);
  const isValidInputObjectUrl = useMemo(() => !newObjectErrors.includes('objectUrl'), [newObjectErrors]);
  const isValidInputObjectOrder = useMemo(() => !newObjectErrors.includes('order'), [newObjectErrors]);

  const handleNewTitle = useCallback((e) => { setData(1, e.target.value, 'title'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDescriptionChange = useCallback((e) => { setData(5, e.target.value, 'description'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewObjectUrl = useCallback((e) => { setData(0, e.target.value, 'objectUrl'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewOrder = useCallback((e) => { setData(0, e.target.value, 'order'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleHiddenChange = useCallback((e) => { setNewObject({...newObject, hidden: !newObject.hidden }); }, [newObject, setNewObject]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleClickImage = () => {
    document.getElementById('fileInput').click(); 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, image: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/offers');
  }, [history, setNewObject]);

  return (
    <Box zIndex="9" position="relative">
      {
        newObject.id ? (
          <Breadcrumbs icon={OffersActiveIcon} 
            target='Offers' 
            url='#/offers' 
            current={newObject.options?.type === 'header' ? 'Edit header' : 'Edit offer'}
            variant="standart"  />
        ) : (
          <Breadcrumbs 
          icon={OffersActiveIcon} 
          target='Offers' 
          url='#/offers' 
          current={newObject.options?.type === 'header' ? 'New header' : 'New offer'}
          variant="standart" />
        )
      }

      <Flex gap="16px" position="absolute" mt="-92px" right="0">
        {!newObject.id && <Button variant="ghost-md" minW="120px" onClick={() => handleCancelClick()}>Cancel</Button>}
        {newObject.id && <Button variant="submit-md-delete" disabled w="120px" onClick={() => onDeleteOffersOpen(newObject)}>Delete</Button>}
        <Button  variant="submit-md" w="120px" onClick={handleCreateObject}>{newObject.id ? 'Confirm' : 'Create'}</Button>
      </Flex>

      <Card padding="24px" mb="8px" maxW="839px">
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" w={{sm: '5%'}} border="unset" p="0 0 0 0">
                <Text variant="title">Active</Text>
              </Td>
              <Td verticalAlign="top" border="unset" p="0 0 0 0">
                <Switch 
                  float='right' 
                  id='email-alerts' 
                  isChecked={!newObject.hidden} 
                  onChange={handleHiddenChange} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>

      <Box maxW="840px">
        <Card padding="24px" mb="40px">
          <Flex gap="24px">
            <Image 
              src={newObject.image ? newObject.image : uploadImage} 
              borderRadius="10px"
              objectFit="cover"
              w="160px" 
              h="90px" />
            <Box>
              <Text 
                variant="title" 
                color="#099BFF" 
                cursor="pointer" 
                onClick={handleClickImage}>
                { newObject.image ? 'Change picture' : 'Add picture' }
              </Text>

              <Flex gap="3px">
                <Text variant="text-md">Maximum size:</Text>
                <Text variant="text-md" fontWeight="500">5 mb</Text>  
              </Flex>

              <Flex gap="3px">
                <Text variant="text-md">Supporting Image Type:</Text>
                <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
              </Flex>
              
            </Box>
          </Flex>

          <input
            id="fileInput"
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Card>

        <Text variant="title-lg" mt="40px" mb="14px">Offer info</Text>
        <Card padding="24px" mb="40px">
            <FormControl variant="floating" id="title">
              <Input value={newObject.title} type='text' placeholder='' autoComplete="off" mb='24px' onChange={handleNewTitle}  className={isValidInputTitle ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="title" className={isValidInputTitle ? 'input-valid' : 'input-invalid'}>Title</FormLabel>
              <FormHelperText className={isValidInputTitle ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>


            <FormControl variant="floating" id="description">
              <Textarea
                  h={{ base: '150px !important' }}
                  resize="none" value={newObject.description} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewDescriptionChange}  className={isValidInputDescription ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="description" className={isValidInputDescription ? 'input-valid' : 'input-invalid'}>Description</FormLabel>
              <FormHelperText className={isValidInputDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <FormControl variant="floating" id="objectUrl">
              <Input value={newObject.objectUrl} maxLength='100' type='text' placeholder='' autoComplete="off" mb='24px' onChange={handleNewObjectUrl}  className={isValidInputObjectUrl ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="objectUrl" className={isValidInputObjectUrl ? 'input-valid' : 'input-invalid'}>URL</FormLabel>
              <FormHelperText className={isValidInputObjectUrl ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <FormControl variant="floating" id="order">
              <Input value={newObject.order} maxLength='100' type='number' placeholder='' autoComplete="off" onChange={handleNewOrder}  className={isValidInputObjectOrder ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="order" className={isValidInputObjectOrder ? 'input-valid' : 'input-invalid'}>Order</FormLabel>
              <FormHelperText className={isValidInputObjectOrder ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>
        </Card>
      </Box>
    </Box>
  );
};

export default OffersEditContent;