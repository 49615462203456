// Core
import { useState, useEffect } from 'react';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Select,
  Checkbox, 
  CheckboxGroup, 
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const EditGeneralInfoModal = ({user, setUser, isOpen, onOpen, onClose}) => {
  const [isValidInputFirstName, setIsValidInputFirstName] = useState(true); 
  const [isValidInputLastName, setIsValidInputLastName] = useState(true); 
  const [confirm, setConfirm] = useState(false); 
  const [dataUser, setDataUser] = useState(user);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = (event) => setConfirm(!confirm);
  const handleFirstName = (event) => setDataUser({...dataUser, firstName: event.target.value});
  const handleLastName = (event) => setDataUser({...dataUser, lastName: event.target.value});
  const handleBirthDate = (event) => setDataUser({...dataUser, birthDate: event.target.value});
  const handleGender = (event) => setDataUser({...dataUser, gender: event.target.value});
  const handleChange = async () => {
    let error = false;

    setIsLoading(true);
    setIsValidInputFirstName(true);
    setIsValidInputLastName(true);

    try {
      const response = await apiService.updateUsers(dataUser);
      if(response.statusCode === 200){
        setUser(dataUser);
        onClose();
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    setIsLoading(false);

    if(error){
      setIsValidInputFirstName(false);
      setIsValidInputLastName(false);
    }
  };

  return (
  	<> 
  	<Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">Edit general info</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text variant="text-md" mb="24px" pt="16px" maxW={{sm: '90%'}}>Changes to user data are made only for a reason given by the user.</Text>

            <FormControl variant="floating">
              <Input type='text' placeholder='' value={dataUser?.firstName} autoComplete="off" mb='40px' onChange={handleFirstName}  className={isValidInputFirstName ? 'input-valid' : 'input-invalid'}  />
              <FormLabel className={isValidInputFirstName ? 'input-valid' : 'input-invalid'}>First Name</FormLabel>
              <FormHelperText className={isValidInputFirstName ? 'hidden' : 'input-error' }>Invalid first name</FormHelperText>
            </FormControl>

            <FormControl variant="floating">
              <Input type='text' placeholder='' value={dataUser?.lastName} autoComplete="off" mb='40px' onChange={handleLastName}  className={isValidInputLastName ? 'input-valid' : 'input-invalid'}  />
              <FormLabel className={isValidInputLastName ? 'input-valid' : 'input-invalid'}>Last Name</FormLabel>
              <FormHelperText className={isValidInputLastName ? 'hidden' : 'input-error' }>Invalid last name</FormHelperText>
            </FormControl>

            <FormControl variant="floating" id="birthDate" mb='40px'>
              <Input type='date' placeholder='' autoComplete="off" value={dataUser?.birthDate}  pb='0px' onChange={handleBirthDate} />
              <FormLabel p='2px' htmlFor="birthDate">Date of Birth</FormLabel>
            </FormControl>

            <FormControl variant="floating"  w="100%" pb='0px' mb="32px">
              <Select onChange={handleGender} value={dataUser?.gender} placeholder="Gender" pb='0px'>
                <option value='Female'>Female</option>
                <option value='Male'>Male</option>
                <option value='Unknown'>Other</option>
                <option value='Transgender'>Prefer not to say</option>
              </Select>
            </FormControl>

            <Divider opacity="1" borderColor='blue.50' borderTopWidth="1px" borderBottomWidth="0" />

            <Text variant="text-md" mb="16px" pt="16px">Do you want to confirm the information update for this user?</Text>

            <Flex mb="24px">
              <Checkbox
                isChecked={confirm}
                onChange={handleConfirm}
              />
              <Text variant="text-md" ml="10px">I intentionally change user information</Text>
            </Flex>

            <Flex justifyContent="flex-end">
              <Button variant="submit-md" maxW="140px" isLoading={isLoading} disabled={!confirm} onClick={handleChange}>
                Confirm
              </Button>
            </Flex>
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default EditGeneralInfoModal;
