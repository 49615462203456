// Core
import { useState, useEffect } from 'react';

// Chakra imports
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Attention from 'components/Modals/Attention.js';
import TablesSessionsRow from './SessionsRow';
import { useCallback } from 'react';
import { apiService } from 'services/ApiService';
import { validateLoadMore } from 'services/Utils';

const Sessions = ({setNotify}) => {
  //{ title, captions, data, refreshSessions, currentSessionId }
  const textColor = useColorModeValue('gray.700', 'white');
  const title = 'Sessions';
  const captions=['Browser', 'Device', 'IP', 'Activity', ''];

  const [offset, setOffset] = useState(0);  

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [dataSessions, setDataSessions] = useState([]);
  const [dataSessionsMeta, setDataSessionsMeta] = useState();
  const sessionsList = async (offset) => {
    try {
      const response = await apiService.getSessions({limit: 5, offset});
      if(offset === 0){
        setDataSessions(response.data);
      } else {
        setDataSessions([...dataSessions, ...response.data]);
      }

      setDataSessionsMeta(response.meta);
    } catch (error) {}
  };

  useEffect(() => { 
    sessionsList(offset);
  }, [offset]);

  const loadMore = async () => {
    setOffset(offset + 5);
  };

  const attentionModal = async () => {
    onOpen();
  };

  const removeAllSessions = useCallback(async () => {
    try {
      const response = await apiService.deleteAllSessions();
      if(response.statusCode === 200){
        sessionsList(0);
      }
      onClose();
    } catch (e) {}
  }, []);

  return (
    <>
    <Text variant="title-lg" mb="16px">Activity</Text>
    <Card padding='24px' my={{ sm: '22px', xl: '0px' }} maxW={{sm: '100%', lg: '840px'}}>
      <CardBody>
        <Table color={textColor}>
          <Tbody>
            {dataSessions.map((row) => {
              return (
                <TablesSessionsRow
                  id={row.id}
                  key={row.id}
                  current={row.current}
                  browser={row.platform?.client?.name}
                  device={`${row.platform?.device?.brand} ${row.platform?.os?.name} ${row.platform?.device?.model}`}
                  location={`${row.location?.countryName}, ${row.location?.cityName}`}
                  ip={row.location?.ip}
                  activity={row.lastActivity}
                  sessionsList={sessionsList}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>

      <Flex justifyContent="flex-end" mt="12px">
        { validateLoadMore(dataSessions, dataSessionsMeta) ? (
          <Button w="fit-content" mt="10px" me="16px" variant="ghost" onClick={loadMore}>
            Show More
          </Button>
        ) : (
          <Text></Text>
        )}

        {dataSessions?.length > 1 && (
          <Button w="fit-content" mt="10px" variant="submit-md" onClick={attentionModal}>
            Untie everything
          </Button>
        )}
      </Flex>
    </Card>
    
    <Attention
      variant="sessions"
      handle={removeAllSessions}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    />
    </>
  );
};

export default Sessions;