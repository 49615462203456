// Core
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';

// Chakra imports
import { Box, Image, Link, Flex, ChakraProvider } from '@chakra-ui/react';

// Assets
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from 'theme/theme.js';
import signInImage from 'assets/img/signInImage.png';
import closeBtn from 'assets/svg/close.svg';
import routes from 'routes.js';

export default function Pages(props) {
  // ref for the wrapper div
  const wrapper = React.createRef();
  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === 'account') {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        if(prop.path !== '/signin'){
          // setShowBackBtn(true);
        }
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const navRef = React.useRef();
  document.documentElement.dir = 'ltr';

  return (
    <ChakraProvider theme={theme} resetCss={false} w='100%'>
      <Box ref={navRef} w='100%'>
        <Box w='100%'>
          <Box ref={wrapper}
	        h={{ sm: '100vh' }}
	        w={{ sm: '90%', md: '90%', lg: '100%' }}
	        maxW='1140px'
	        pt={{ sm: '100px', md: '0' }}
	        m="0 auto"
	       >
	        <Flex
	          m="0 auto"
	          alignItems='center'
	          style={{ userSelect: 'none' }}
	          w={{ base: '100%', md: '50%', lg: '36%' }}
	          mb='100px'>
              <Switch>
                {getRoutes(routes)}
                <Redirect from='/auth' to='/auth/404' />
              </Switch>
		    </Flex>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}
