// Core
import { IoMdClose } from 'react-icons/io';

// Chakra
import {useCallback} from 'react';
import {
  Flex,
  Tr,
  Td,
  Text,
  Icon,
  Image,
  Button,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

// Assets
import unlink from 'assets/svg/unlink.svg';
import { apiService } from 'services/ApiService';
import { parseDateTime } from 'services/Utils';

// Components
import Attention from 'components/Modals/Attention.js';

function SessionsTableRow(props) {
  const { current, id, browser, device, location, ip, activity, sessionsList} = props;
  const deviceColor = useColorModeValue('gray.800', 'white');
  const textColor = useColorModeValue('gray.75', 'white');
  const borderColor = useColorModeValue('white', 'rgba(255, 255, 255, 0.10)');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const attentionModal = async () => {
    onOpen();
  };

  const removeSessionHandler = useCallback(async () => {
    try {
      const response = await apiService.deleteSession({ sessionId: id});
      if(response.statusCode === 200){
        sessionsList(0);
      }
    } catch (e) {}
  }, [id]);

  return (
    <Tr border="unset">
      <Td borderColor={borderColor} verticalAlign="top" p='16px 0'>
        <Text fontSize="16px" color={deviceColor} fontWeight="bold">{ current ? 'Current device' : 'Previous devices'}</Text>
      </Td>
      <Td borderColor={borderColor} verticalAlign="top" p='16px 0'>
        <Text fontSize="16px" color={deviceColor} fontWeight="bold" pb=".5rem">
          {device}
        </Text>
        <Text fontSize="14px" color={textColor} fontStyle="normal" fontWeight="400" pb=".5rem">
          Browser: {browser}
        </Text>
        <Text fontSize="14px" color={textColor} fontStyle="normal" fontWeight="400" pb=".5rem">
          Location: {location}
        </Text>
        <Text fontSize="14px" color={textColor} fontStyle="normal" fontWeight="400" pb=".5rem">
          IP: {ip}
        </Text>
        <Flex fontSize="14px" color={textColor}>
          Activity: {current ? (
            <Text color="green.100" fontSize="14px" ml='4px'>Online</Text>
          ) : parseDateTime(activity)}
        </Flex>
      </Td>
      <Td borderColor={borderColor} verticalAlign="top" p='16px 0'>
        {current ? (
          <Image src={unlink} filter="brightness(0)" opacity="0.5"/>
        ) : (<Image src={unlink} cursor="pointer" onClick={attentionModal}/>)}

        <Attention
          variant="sessions"
          handle={removeSessionHandler}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Td>
    </Tr>
  );
}

export default SessionsTableRow;
