// Core
import { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Chakra 
import { 
  Box,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Divider,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { user, getInitials } from 'services/Utils';
import { HiDotsVertical } from 'react-icons/hi';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const Admins = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');
  const paddingTd = '16px 0 16px 24px';

  const history = useHistory();

  const [offset, setOffset] = useState(0);  

  const [dataUsers, setDataUsers] = useState([]);
  const fetchDataUser = async () => {
      try {
        const response = await apiService.getUsers({limit: 5, offset, role: 'Admin'});
        setDataUsers(response.data);
      } catch (error) {}
  };

  useEffect(() => { 
    fetchDataUser(offset);
  }, [offset]);

  const handleUserDataOpen = useCallback((user) => {
    history.push(`/users/${user.id}`);
  }, [history]);

  return (
  	<>
  	<Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='0' >Admins</Text>
  	<Divider opacity="1" mr="2px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0" mb='32px'/>
    <Card padding="8px 0 0 0" overflow="hidden">
      <CardBody >
	      <Table color={textColor}>
		      <Thead>
		          <Tr>
		            <Td><Text variant="title-md" p='0 0 6px 0'>Name</Text></Td>
		            <Td><Text variant="title-md" p='0 0 6px 0'>Status</Text></Td>
		            <Td><Text variant="title-md" p='0 0 6px 0'>Email</Text></Td>
		            <Td><Text variant="title-md" p='0 0 6px 0'>Phone</Text></Td>
		          </Tr>
		      </Thead>
	          <Tbody>
	            {dataUsers.map((row) => {
	              return (
	                <Tr key={row.id} border="unset" className="cursor-tr">
      				      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="center" p={paddingTd}>
                      <Flex gap="8px">
                        { row?.image ? (
                          <Image src={row?.image} w="41px" h="41px" borderRadius="full" objectFit="cover" />
                        ) : (
                          <Box className={row?.gender === 'Male' ? 'gender-male' : row?.gender === 'Female' ? 'gender-female' : row?.gender === 'Transgender' ? 'gender-other' : 'gender-none' }>
                            <Text>{getInitials(row?.firstName, row?.lastName)}</Text>
                          </Box>
                        )}
        				      	<Flex position="relative">
        				            <Text variant="text" color={textColor} pt="10px">{row.firstName} {row.lastName}</Text>
        				      	</Flex>
                      </Flex>
      				      </Td>
      				      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="center" p={paddingTd}>
      				        <Text mt="-1px" variant="text-md" 
      				        	className={
      				        		row.role === 'Admin' ? 'tag-red' : 'tag-purple'
      				        	}
      				        >{row.role}</Text>
      				      </Td>
      				      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="center" p={paddingTd}>
      				        <Text variant="text" color={textColor} >{row.email}</Text>
      				      </Td>
      				      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="center" p={paddingTd}>
      				        <Text variant="text" color={textColor}>+{row.phone}</Text>
      				      </Td>
      				    </Tr>
	              );
	            })}
	          </Tbody>
	      </Table>
      </CardBody>
    </Card>
    </>
  );
};

export default Admins;