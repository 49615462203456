// Core
import { useState } from 'react';
import { Link, Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

// Chakra imports
import { 
  Box, 
  Image,
  Flex, 
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import danger from 'assets/svg/danger.svg';
import notifySuccess from 'assets/svg/notify-success.svg';
import notifyError from 'assets/svg/notify-error.svg';

// Components
import Card from 'components/Card/Card.js';
import Profile from './components/Profile';
import Security from './components/Security';
import Sessions from './components/Sessions';

function Settings(props) {
  // Chakra color mode
  const { path, url } = useRouteMatch();
  const { fetchDataUser, dataUser, setDataUser, initialImage } = props;

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  const [notify, setNotify] = useState({
    error: false,
    success: false,
    msg: ''
  });

  const [address, setAddress] = useState({ street: '', street2: '', city: '', country: '', zip: '' });

  const textColor = useColorModeValue('blue.800', 'white');

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='12px'>Settings</Text>
      <Box mt="0px" mb="50px" direction='column'>
        <Flex align='left' mb={{ sm: '10px', md: '0px' }}
          direction={{ sm: 'column', md: 'row' }}
          w={{ sm: '100%' }}
          textAlign={{ sm: 'center', md: 'start' }}
          borderBottom="1px"
          borderColor="rgba(255, 255, 255, 0.10)"
          className="settings-tabs">
          <Link to={`${url}/profile`} className={isActive(`${path}/profile`) ? 'settings-tab-active' : ''}>Profile</Link>
          <Link to={`${url}/security`} className={isActive(`${path}/security`) ? 'settings-tab-active' : ''}>Security</Link>
          <Link to={`${url}/sessions`} className={isActive(`${path}/sessions`) ? 'settings-tab-active' : ''}>Sessions</Link>
        </Flex>

        <Card 
          display={notify?.success ? 'block' : notify?.error ? 'block' : 'none' } className={notify?.success ? 'notify-success' : notify?.error ? 'notify-error' : ''}
          variant="ghost" padding='24px 16px' mb={{ sm: '8px' }}
          borderBottom="1px"
          borderColor="blue.50">
          <Flex>
            <Image src={notify?.success ? notifySuccess : notify?.error ? notifyError : ''} h="24px"/>
            <Text ml="16px" maxW={{sm: '100%', md: '70%'}} mt="2px" variant="text-md">{notify?.msg}</Text>
          </Flex>
        </Card>
        <Box align='left' mt='32px'>
          <Switch>
            <Route path={`${path}/profile`} render={() => <Profile setNotify={setNotify} fetchDataUser={fetchDataUser} dataUser={dataUser}
            setDataUser={setDataUser} initialImage={initialImage} />} />
            <Route path={`${path}/security`} render={() => <Security setNotify={setNotify} />} />
            <Route path={`${path}/sessions`} render={() => <Sessions setNotify={setNotify} />} />
            <Redirect from={`${path}*`} to={`${path}/profile`} />
          </Switch>
        </Box>
      </Box>
    </>
  );
}

export default Settings;
