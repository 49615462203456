// Core
import { useState, useMemo, useCallback } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { TiArrowSortedDown } from 'react-icons/ti';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  IconButton,
  FormLabel,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  ModalBody,
  Input,
  SimpleGrid,
  Switch,
  Checkbox,
  Text, 
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { getInitials, parseDateTime } from 'services/Utils';

const ReceiptModal = ({ 
  isOpen, 
  onClose,
  newObject, newObjectErrors, setNewObject, 
  setNewObjectErrors, handleCreateObject,
  prevObject, nextObject,
  prevBtnDisable, nextBtnDisable,
  autoNext, setAutoNext
}) => {
  const textColor = useColorModeValue('gray.700', '#9FABCB');

  const isValidInputCashback = useMemo(() => !newObjectErrors.includes('cashback'), [newObjectErrors]);
  const isValidInputStatus = useMemo(() => !newObjectErrors.includes('status'), [newObjectErrors]);

  const handleCashbackChange = useCallback((e) => { setData(0, e.target.value, 'cashback'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleStatusChange = useCallback((e) => { 
    const updatedObject = { ...newObject, status: e };
    setNewObject(updatedObject);
    handleCreateObject(updatedObject);
  }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleAutoNext = () => {
    setAutoNext(!autoNext);
    localStorage.setItem('autoNextReceipts', (!autoNext));
  };

  return (
  	<> 
  	<Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '635px' }}>
        
        <ModalHeader display="flex" alignItems="left" justifyContent="left">Verification</ModalHeader>

        <Text 
          position="absolute"
          right="80px"
          h="47px"
          pt="13px"
          variant="text-md" 
          mt="0px"
          minW="110px"
          className={newObject.status === 'approved' ? 'tag-green' : newObject.status === 'pending' ? 'tag-orange' : 'tag-red'} 
          textTransform="capitalize">{newObject.status}
        </Text>

        <ModalCloseButton />
        <ModalBody>

          <Checkbox
            pb="16px"
            mt="4px"
            isChecked={autoNext}
            onChange={handleAutoNext}
          >
            Automatically move to the next recipe
          </Checkbox> 

          <Grid 
            gap="16px"
            mt="6px"
            templateColumns="44px 2fr 44px">

            <Box w="44px" mt="-8px" h="100%">
              <Button
                disabled={prevBtnDisable}
                h="100%"
                w="100%"
                onClick={() => prevObject(newObject?.id)}
                variant="secondary-lg" borderRadius="10px" mt="8px">
                <TiArrowSortedDown fontSize="24px" color="white" style={{ transform: 'rotate(90deg)' }}/>
              </Button>
            </Box>
            
            <Box>
              <Grid 
                templateColumns="1fr 24px">
                <Flex gap="12px">

                  { newObject?.userReceipt?.image ? (
                    <Image src={newObject?.userReceipt?.image} maxW="56px" w="100%" h="56px" borderRadius="full" objectFit="cover"/>
                  ) : (
                    <Box maxW="56px" w="100%" h="56px" className={newObject?.userReceipt?.gender === 'Male' ? 'gender-male' : newObject?.userReceipt?.gender === 'Female' ? 'gender-female' : newObject?.userReceipt?.gender === 'Transgender' ? 'gender-other' : 'gender-none' }>
                      <Text p="12px !important" variant="title-lg" fontSize="22px !important">{getInitials(newObject?.userReceipt?.firstName, newObject?.userReceipt?.lastName)}</Text>
                    </Box>
                  )}

                  <Box mt="-2px">
                    <Text variant="title-lg" fontSize="24px">{newObject?.userReceipt?.firstName} {newObject?.userReceipt?.lastName}</Text>
                    <Text variant="text-md">{newObject?.userReceipt?.email}</Text>
                  </Box>
                </Flex>

                <Box mt="-8px">
                  <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<HiDotsVertical />}
                        variant="ghost"
                        color="white"
                        fontSize="20px"
                        transition="0s all"
                      />
                      <MenuList className="context-menu">
                        <MenuItem>
                          <Link to={`/users/${newObject?.userReceipt?.id}`}>Show user</Link>
                        </MenuItem>
                      </MenuList>
                  </Menu>
                </Box>
              </Grid>

              <Flex gap="24px" width="100%" mt="16px" justifyContent="space-between">
                <Box>
                  <Text variant="text-md" color={textColor}>№</Text>
                  <Text variant="text-md" fontWeight="500">{newObject?.id}</Text>
                </Box>

                <Box>
                  <Text variant="text-md" color={textColor}>Date</Text>
                  <Text variant="text-md" fontWeight="500">{parseDateTime(newObject?.purchase)}</Text>
                </Box>

                <Box>
                  <Text variant="text-md" color={textColor}>Partner</Text>
                  <Text 
                    variant="text-md" 
                    fontWeight="500"w="100%">
                    <Link to={`/partners/${newObject?.partnerReceipt?.id}`}>{newObject?.partnerReceipt?.name}</Link>
                  </Text>
                </Box>

                <Box>
                  <Text variant="text-md" color={textColor}>Suma</Text>
                  <Text variant="text-md" fontWeight="500">${newObject?.amount / 100}</Text>
                </Box>
              </Flex>

              <Divider mt="14px" opacity="1" mr="2px" borderColor='rgba(255, 255, 255, 0.10)' borderTopWidth="1px" borderBottomWidth="0" />
            </Box>

            <Box w="44px" mt="-8px" h="100%">
              <Button
                disabled={nextBtnDisable}
                h="100%"
                w="100%"
                onClick={() => nextObject(newObject?.id)}
                variant="secondary-lg" borderRadius="10px" mt="8px">
                <TiArrowSortedDown fontSize="24px" color="white" style={{ transform: 'rotate(-90deg)' }}/>
              </Button>
            </Box>

          </Grid>

          <Box mt="24px">
            <FormControl variant="floating" id="cashback">
              <Input value={newObject?.cashback} type='number' placeholder='' autoComplete="off" mb='16px' onChange={handleCashbackChange}  className={isValidInputCashback ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="cashback" className={isValidInputCashback ? 'input-valid' : 'input-invalid'}>Cashback</FormLabel>
              <FormHelperText className={isValidInputCashback ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            { newObject?.image && (
              <Box position="relative" overflow="hidden" w="100%" h="322px" borderRadius="20px">
                <Box zIndex="1" w="100%" h="322px" bg={`url(${newObject?.image})`} filter="blur(11.413110733032227px)"></Box>
                <Image zIndex="2" top="0" position="absolute" objectFit="contain" w="100%" h="322px" src={newObject?.image} />
              </Box>
            )}
          </Box>

          { newObject?.status === 'pending' && (
            <Flex w="100%" justifyContent="center" gap="16px" mt="24px">
              <Button w="100%" variant="ghost-md" onClick={() => handleStatusChange('declined')}>Decline</Button>
              <Button 
                disabled={
                  newObject?.cashback && newObject?.image ? false 
                  : true 
                } 
                variant="submit-md" onClick={() => handleStatusChange('approved')}>Accept</Button>
            </Flex>
          )}

        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default ReceiptModal;
