// Core
import { useState } from 'react';
import { 
  Link, 
  Route, 
  Switch, 
  Redirect, 
  useRouteMatch, 
  useLocation 
} from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';

// Chakra imports
import { 
  Box, 
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  InputGroup,
  InputLeftElement,
  Text,
  Input,
  useColorModeValue
} from '@chakra-ui/react';

// Components
import Card from 'components/Card/Card.js';
import { SearchIcon } from 'components/Icons/Icons';

function Dashboard(props) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  const textColor = useColorModeValue('blue.800', 'white');
  const borderColor = useColorModeValue('rgba(255, 255, 255, 0.10)', 'rgba(255, 255, 255, 0.10)');

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='12px'>Dashboard</Text>

      <Box mt="0px" mb="50px" direction='column'>
        <Flex align='left' mb={{ sm: '10px', md: '0px' }}
          direction={{ sm: 'column', md: 'row' }}
          w={{ sm: '100%' }}
          textAlign={{ sm: 'center', md: 'start' }}
          borderBottom="1px"
          borderColor={borderColor}
          className="settings-tabs">
          <Link to={`${url}/general`} className={isActive(`${path}/general`) ? 'settings-tab-active' : ''}>General</Link>
          <Link to={`${url}/giveaway`} className={isActive(`${path}/giveaway`) ? 'settings-tab-active' : ''}>Giveaways</Link>
        </Flex>
      </Box>

      <Box align='left' mt='32px'>
        <Switch>
          <Route path={`${path}/general`} render={() => <Text variant="text">Not found page</Text>} />
          <Route path={`${path}/giveaway`} render={() => <Text variant="text">Not found page</Text>} />
          <Redirect from={`${path}*`} to={`${path}/general`} />
        </Switch>
      </Box>
    </>
  );
}

export default Dashboard;