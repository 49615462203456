import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { apiService } from 'services/ApiService';
import { useHistory } from 'react-router-dom';
import PartnerEditContent from './components/PartnerEditContent';
import NotFound from 'views/Dashboard/404.js';

const Edit = ({Object}) => {
  const history = useHistory();
  const { id } = useParams();
  const [newObjectErrors, setNewObjectErrors] = useState([]);
  const [newObject, setNewObject] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedWideFile, setSelectedWideFile] = useState(null);

  const { isLoading, isError } = useQuery({
    queryKey: [id],
    refetchOnWindowFocus: false,
    refetchOnError: false,
    queryFn: async () => {
      if (id === 'new') {
        setNewObject({});
      } else {
        const response = await apiService.getPartner({id});
        setNewObject({...response.data, bindCategories: response.data.categories , bindTags: response.data.tags});
      }
      return true;
    }
  });

  const { data: states } = useQuery({
    queryKey: ['states'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getPartnersState();
      return response.data; 
    }
  });

  const { data: categories } = useQuery({
    queryKey: ['categories'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getPartnersCategories({limit: 250});
      return response.data; 
    }
  });

  const { data: tags } = useQuery({
    queryKey: ['tags'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getPartnersTags({limit: 250});
      return response.data; 
    }
  });

  const { data: objects } = useQuery({
    queryKey: ['object'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getPartners();
      return response.data;
    }
  });

  const handleCreateObject = useCallback(async () => {
    if (newObjectErrors.length > 0) {
      return;
    }

    let objectId = newObject.id;
    newObject.tagIds = newObject?.bindTags?.map(item => item.id) || null;
    newObject.categoryIds = newObject?.bindCategories?.map(item => item.id) || null;
    newObject.country = 'Australia';

    if (newObject.id) {
      try {
        const data = newObject;
        delete data.image;
        delete data.imageWide;
        const response = await apiService.updatePartner(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['name']);
          return;
        }
      } catch(e){
        setNewObjectErrors(['name']);
        return;
      }
    } else {
      try {
        const data = newObject;
        delete data.image;
        delete data.imageWide;
        const response = await apiService.createPartner(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['name']);
          return;
        }

        objectId = response?.data?.id;
      } catch(e){
        setNewObjectErrors(['name']);
        return;
      }
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('itemType', 'partner');
      formData.append('itemId', objectId);
      formData.append('sync', true);

      try {
        const response = await apiService.uploadImage(formData); 
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    if (selectedWideFile) {
      const formData = new FormData();
      formData.append('file', selectedWideFile);
      formData.append('itemType', 'partner-wide');
      formData.append('itemId', objectId);
      formData.append('sync', true);

      try {
        const response = await apiService.uploadImage(formData); 
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    setNewObject({});
    history.push('/partners');
  }, [history, newObject, newObjectErrors.length]);

  const handleDeleteObject = useCallback(async (object) => {
    await apiService.deletePartner(object);
    setNewObject({});
    history.push('/partners');
  }, [history]);

  return (isError ? <NotFound text="Not Found" /> : isLoading ? <NotFound text="Loading..." /> 
    : 
      <PartnerEditContent 
        newObject={newObject} 
        newObjectErrors={newObjectErrors} 
        states={states} 
        tags={tags} 
        categories={categories} 
        objects={objects} 
        setSelectedFile={setSelectedFile}
        setSelectedWideFile={setSelectedWideFile}
        setNewObject={setNewObject} 
        setNewObjectErrors={setNewObjectErrors} 
        handleDeleteObject={handleDeleteObject} 
        handleCreateObject={handleCreateObject} />);
};

export default Edit;