// Core
import { useCallback,useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Table,
  Tbody,
  Tr,
  Td,
  Text, 
  Textarea,
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';

// Components
import { BannersActiveIcon } from 'components/Icons/Icons';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import uploadImage from 'assets/img/upload.png';

const BannerEditContent = ({ 
  newObject, 
  newObjectErrors, 
  setNewObject, 
  setNewObjectErrors, 
  setSelectedFile,
  handleDeleteBanner, 
  handleCreateBanner,
  handleCreateObject,
  onDeleteBannerOpen,
}) => {
  const typeOptions = [
    {
      value: 'regular',
      label: 'Regular'
    },
    {
      value: 'bonusWheel',
      label: 'Game Bonus Wheel'
    },
  ];

  const objectTypeOptions = [
    {
      value: 'url',
      label: 'URL'
    },
    {
      value: 'partner',
      label: 'Partner'
    },
    {
      value: 'game',
      label: 'Game'
    },
    {
      value: 'cashback',
      label: 'Cashback'
    },
    {
      value: 'giveaways',
      label: 'Giveaways'
    },
  ];

  const isValidInputTitle = useMemo(() => !newObjectErrors.includes('title'), [newObjectErrors]);
  const isValidInputDescription = useMemo(() => !newObjectErrors.includes('description'), [newObjectErrors]);
  const isValidInputDate = useMemo(() => !newObjectErrors.includes('date'), [newObjectErrors]);
  const isValidInputType = useMemo(() => !newObjectErrors.includes('type'), [newObjectErrors]);
  const isValidInputObjectType = useMemo(() => !newObjectErrors.includes('objectType'), [newObjectErrors]);
  const isValidInputObjectId = useMemo(() => !newObjectErrors.includes('objectId'), [newObjectErrors]);
  const isValidInputObjectUrl = useMemo(() => !newObjectErrors.includes('objectUrl'), [newObjectErrors]);
  const isValidInputObjectOrder = useMemo(() => !newObjectErrors.includes('order'), [newObjectErrors]);

  const handleNewTitle = useCallback((e) => { setData(1, e.target.value, 'title'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDescriptionChange = useCallback((e) => { setData(10, e.target.value, 'description'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDate = useCallback((e) => { setData(0, e.target.value, 'date'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewType = useCallback((e) => { setData(3, e.target.value, 'type'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewObjectType = useCallback((e) => { setData(0, e.target.value, 'objectType'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewObjectId = useCallback((e) => { setData(0, e.target.value, 'objectId'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewObjectUrl = useCallback((e) => { setData(0, e.target.value, 'objectUrl'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewOrder = useCallback((e) => { setData(0, e.target.value, 'order'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleHiddenChange = useCallback((e) => { setNewObject({...newObject, hidden: !newObject.hidden }); }, [newObject, setNewObject]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleClickImage = () => {
    document.getElementById('fileInput').click(); 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, image: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/banners');
  }, [history, setNewObject]);

  return (
    <Box zIndex="9" position="relative">
      {
        newObject.id ? (
          <Breadcrumbs icon={BannersActiveIcon} 
            target='Banners' 
            url='#/banners' 
            current={newObject.options?.type === 'header' ? 'Edit header' : 'Edit banner'}
            variant="standart"  />
        ) : (
          <Breadcrumbs 
          icon={BannersActiveIcon} 
          target='Banners' 
          url='#/banners' 
          current={newObject.options?.type === 'header' ? 'New header' : 'New banner'}
          variant="standart" />
        )
      }

      <Flex gap="16px" position="absolute" mt="-92px" right="0">
        {!newObject.id && <Button variant="ghost-md" disabled maxW="120px" onClick={() => handleCancelClick()}>Cancel</Button>}
        {newObject.id && <Button variant="submit-md-delete" disabled w="120px" onClick={() => onDeleteBannerOpen(newObject)}>Delete</Button>}
        <Button  variant="submit-md" w="120px" onClick={handleCreateObject}>{newObject.id ? 'Confirm' : 'Create'}</Button>
      </Flex>

      <Card padding="24px" mb="8px" maxW="839px">
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" w={{sm: '5%'}} border="unset" p="0 0 0 0">
                <Text variant="title">Active</Text>
              </Td>
              <Td verticalAlign="top" border="unset" p="0 0 0 0">
                <Switch 
                  float='right' 
                  id='email-alerts' 
                  isChecked={!newObject.hidden} 
                  onChange={handleHiddenChange} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>

      <Box maxW="840px">
        <Card padding="24px" mb="40px">
          <Flex gap="24px">
            <Image 
              src={newObject.image ? newObject.image : uploadImage} 
              borderRadius="10px"
              objectFit="cover"
              w="160px" 
              h="90px" />
            <Box>
              <Text 
                variant="title" 
                color="#099BFF" 
                cursor="pointer" 
                onClick={handleClickImage}>
                { newObject.image ? 'Change picture' : 'Add picture' }
              </Text>

              <Flex gap="3px">
                <Text variant="text-md">Maximum size:</Text>
                <Text variant="text-md" fontWeight="500">5 mb</Text>  
              </Flex>

              <Flex gap="3px">
                <Text variant="text-md">Supporting Image Type:</Text>
                <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
              </Flex>
              
            </Box>
          </Flex>

          <input
            id="fileInput"
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Card>

        <Text variant="title-lg" mt="40px" mb="14px">Banner info</Text>
        <Card padding="24px" mb="40px">
          <Box w={{sm: '100%'}}>
            <FormControl variant="floating" id="title">
              <Input value={newObject.title} type='text' placeholder='' autoComplete="off" mb='24px' onChange={handleNewTitle}  className={isValidInputTitle ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="title" className={isValidInputTitle ? 'input-valid' : 'input-invalid'}>Title</FormLabel>
              <FormHelperText className={isValidInputTitle ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <FormControl variant="floating" id="description">
              <Textarea resize='none' height='132px' maxLength='100' value={newObject.description} type='text' placeholder='' autoComplete="off" mb='24px' onChange={handleNewDescriptionChange}  className={isValidInputDescription ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="description" className={isValidInputDescription ? 'input-valid' : 'input-invalid'}>Description</FormLabel>
              <FormHelperText className={isValidInputDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <FormControl variant="floating" id="date">
              <Input value={newObject.date ? formatDate(newObject.date) : null} type='datetime-local'  mb='24px' onChange={handleNewDate}/>
              <FormLabel htmlFor="date" >Start time</FormLabel>
            </FormControl>

            <FormControl variant="floating" id="order">
              <Input value={newObject.order} maxLength='100' type='number' placeholder='' autoComplete="off" mb='24px' onChange={handleNewOrder}  className={isValidInputObjectOrder ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="order" className={isValidInputObjectOrder ? 'input-valid' : 'input-invalid'}>Order</FormLabel>
              <FormHelperText className={isValidInputObjectOrder ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>
          </Box>
        </Card>

        <Text variant="title-lg" mt="40px" mb="14px">Types</Text>
        <Card padding="24px 24px 0px 24px" mb="40px">
          <FormControl variant="floating" id="type">
            <Select placeholder="Select type" value={newObject.type} onChange={handleNewType} mb='24px' className={isValidInputType ? 'input-valid' : 'input-invalid'}>
              {typeOptions && typeOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Select>
            <FormLabel value={newObject.type} htmlFor="type" className={isValidInputType ? 'input-valid' : 'input-invalid'}>Content type</FormLabel>
            <FormHelperText className={isValidInputType ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
          </FormControl>

          { newObject.type === 'regular' && (
            <>
              <FormControl variant="floating" id="objectType">
                <Select placeholder="Select type" value={newObject.objectType} onChange={handleNewObjectType} mb='24px' className={isValidInputObjectType ? 'input-valid' : 'input-invalid'}>
                  {objectTypeOptions && objectTypeOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </Select>
                <FormLabel value={newObject.type} htmlFor="type" className={isValidInputType ? 'input-valid' : 'input-invalid'}>Type</FormLabel>
                <FormHelperText className={isValidInputType ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              { newObject?.objectType !== 'url' ? (
                <FormControl variant="floating" id="object">
                  <Input value={newObject.objectId} maxLength='100' type='number' placeholder='' autoComplete="off" mb='24px' onChange={handleNewObjectId}  className={isValidInputObjectId ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="objectId" className={isValidInputObjectId ? 'input-valid' : 'input-invalid'}>Object id</FormLabel>
                  <FormHelperText className={isValidInputObjectId ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              ) : (
                <FormControl variant="floating" id="objectUrl">
                  <Input value={newObject.objectUrl} maxLength='100' type='text' placeholder='' autoComplete="off" mb='24px' onChange={handleNewObjectUrl}  className={isValidInputObjectUrl ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="objectUrl" className={isValidInputObjectUrl ? 'input-valid' : 'input-invalid'}>URL</FormLabel>
                  <FormHelperText className={isValidInputObjectUrl ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              )}
            </>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default BannerEditContent;