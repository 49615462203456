// Core
import { 
  Link, 
  Route, 
  Switch, 
  Redirect, 
  useRouteMatch, 
  useLocation 
} from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';
import { useState, useMemo ,useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Chakra imports
import { 
  Box, 
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  InputGroup,
  InputLeftElement,
  Text,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Input,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { validateLoadMore, filterDate } from 'services/Utils';

// Components
import Card from 'components/Card/Card.js';
import { SearchIcon, DateTimeIcon, ColumnFilterIcon } from 'components/Icons/Icons';
import ReceiptRow from './components/ReceiptRow';
import ReceiptModal from './components/ReceiptModal';

function Receipts(props) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  const textColor = useColorModeValue('blue.800', 'white');
  const borderColor = useColorModeValue('rgba(255, 255, 255, 0.10)', 'rgba(255, 255, 255, 0.10)');

  const [filterButtonLabel, setFilterButtonLabel] = useState('All Time');  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState(); 
  const [offset, setOffset] = useState(0); 
  const [data, setData] = useState([]);  
  const [dataMeta, setDataMeta] = useState(); 
  const [currentTab, setCurrentTab] = useState('all');

  const [prevBtnDisable, setPrevBtnDisable] = useState(true);
  const [nextBtnDisable, setNextBtnDisable] = useState(false);

  const [autoNext, setAutoNext] = useState(localStorage.getItem('autoNextReceipts') === 'true' ? true : false);

  const [newObjectErrors, setNewObjectErrors] = useState([]);
  const [newObject, setNewObject] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    setOffset(0); 
  };

  const { isLoading, refetch } = useQuery({
    queryKey: ['data', offset, search, currentTab, startDate, endDate],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const data = {
        limit: 15,
        offset,
      };

      if (search) {
        data['search'] = search;
      }

      const pathname = location.pathname;
      if (pathname.includes(`${path}/pending`)) {
        data['status'] = 'pending';
      } else if (pathname.includes(`${path}/approved`)) {
        data['status'] = 'approved';
      } else if (pathname.includes(`${path}/declined`)) {
        data['status'] = 'declined';
      } 

      if(startDate){
        data['startDate'] = new Date(startDate).toISOString();
      }

      if(endDate){
        data['endDate'] = new Date(endDate).toISOString();
      }

      const response = await apiService.getReceipts(data);
      if (offset === 0) {
        setData(response.data);
      } else {
        setData((prevData) => [...prevData, ...response.data]);
      }

      setDataMeta(response.meta);
      return true;
    }
  });

  const loadMore = async () => {
    setOffset(offset + 15);
  };

  const handleDate = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const handleDateButton = (type) => {
    setFilterButtonLabel(type);
    const data = filterDate(type);
    handleDate(data);
  };

  const handleReceipt = (receipt) => {
    setNewObject(receipt);
    onOpen();
  };

  const handleCreateObject = useCallback(async (objectToUpdate) => {
    if (newObjectErrors.length > 0) {
      return;
    }

    if (objectToUpdate.id) {
      try {
        let data = objectToUpdate;
        delete data.image;
        data.cashback = parseInt(newObject.cashback, 10);
        const response = await apiService.updateReceipt(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['cashback']);
          return;
        }
      } catch(e){
        setNewObjectErrors(['cashback']);
        return;
      }
    }

    if(localStorage.getItem('autoNextReceipts') === 'true'){
      refetch();
      nextObject(objectToUpdate.id);
    } else {
      setNewObject({});
      onClose();
      refetch();
    }
  }, [newObject, newObjectErrors.length]);

  const prevObject = async (objectId) => {
    const currentIndex = data.findIndex((item) => item.id === objectId);
    if (currentIndex > 0) {
      const response = await apiService.getReceipt({id: data[currentIndex - 1]?.id});
      setNewObject(response.data);
      setNextBtnDisable(false);  
    } else {
      setPrevBtnDisable(true);
    }
  };

  const nextObject = async (objectId) => {
    const currentIndex = data.findIndex((item) => item.id === objectId);
    if (currentIndex < data.length - 1) {
      const response = await apiService.getReceipt({id: data[currentIndex + 1]?.id});
      setNewObject(response.data);
      setPrevBtnDisable(false);
    } else if (validateLoadMore(data, dataMeta)) {
      loadMore();
    } else {
      setNextBtnDisable(true);
    }
  };

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='12px'>Receipts</Text>
      <Flex position="absolute" right="0" top="80px" mr="31px" gap="12px">
        <FormControl variant="search" maxW="326px" className={search?.length > 0 ? 'search-active' : ''}>
          <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon fontSize="24px" ml="8px" />
          </InputLeftElement>
          <Input type='text' placeholder='Search' onChange={handleSearch} />
        </InputGroup>
        </FormControl>

        <Menu variant="filter">
          <MenuButton
            as={Button}
            leftIcon={<DateTimeIcon color="white" fontSize="24px" ml="0px" mr="0px" />}
            variant="secondary-md"
            color="white"
            mt="-2px"
            px="24px !important"
          >{ filterButtonLabel }</MenuButton>
          <MenuList 
            className="context-menu filter-date">
            <Box>
              <DatePicker
                onChange={handleDate}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                locale="en"
                dateFormat="d/MM/yyyy"
              />
              {(!startDate && !endDate) && (
                <Text 
                  position="absolute" 
                  variant="text"
                  className="filter-date-placeholder"
                  pointerEvents="none"
                >
                  Enter a date range
                </Text>
              )}
              <DateTimeIcon 
                fontSize="24px"
                position="absolute"
                right="20px"
                top="17px"
                color="#9FABCB"
                pointerEvents="none"
              />
            </Box>
            <MenuItem onClick={() => handleDateButton('All Time')}>All Time</MenuItem>
            <MenuItem onClick={() => handleDateButton('This Month')}>This Month</MenuItem>
            <MenuItem onClick={() => handleDateButton('Last Month')}>Last Month</MenuItem>
            <MenuItem onClick={() => handleDateButton('Last 3 Month')}>Last 3 Month</MenuItem>
            <MenuItem onClick={() => handleDateButton('This Year')}>This Year</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <Box mt="0px" mb="32px" direction='column'>
        <Flex align='left' mb={{ sm: '10px', md: '0px' }}
          direction={{ sm: 'column', md: 'row' }}
          w={{ sm: '100%' }}
          textAlign={{ sm: 'center', md: 'start' }}
          borderBottom="1px"
          borderColor={borderColor}
          className="settings-tabs">
          <Link onClick={() => handleTabChange('all')} to={`${url}/all`} className={isActive(`${path}/all`) || location.pathname === '/receipts' ? 'settings-tab-active' : ''}>All</Link>
          <Link onClick={() => handleTabChange('pending')} to={`${url}/pending`} className={isActive(`${path}/pending`) ? 'settings-tab-active' : ''}>Pending</Link>
          <Link onClick={() => handleTabChange('approved')} to={`${url}/approved`} className={isActive(`${path}/approved`) ? 'settings-tab-active' : ''}>Approved</Link>
          <Link onClick={() => handleTabChange('declined')} to={`${url}/declined`} className={isActive(`${path}/declined`) ? 'settings-tab-active' : ''}>Declined</Link>
        </Flex>
      </Box>

      <Box>
        <Text variant="text" pb="12px" color="E1E1E1">{dataMeta?.total} receipts listed</Text>
        { data.length > 0 && (
          <Card padding="8px 0 0 0" overflow="hidden">
            <Table>
              <Thead>
                  <Tr>
                    <Td><Text variant="title-md" p='0 0 6px 0px'>№</Text></Td>
                    <Td><Text variant="title-md" p='0 0 6px 0px'>Name User</Text></Td>
                    <Td><Text variant="title-md" p='0 0 6px 0px'>Created</Text></Td>
                    <Td><Text variant="title-md" p='0 0 6px 0px'>Partner</Text></Td>
                    <Td><Text variant="title-md" p='0 0 6px 0px'>Purchase</Text></Td>
                    <Td><Text variant="title-md" p='0 0 6px 0px'>Amount</Text></Td>
                    <Td><Text variant="title-md" p='0 0 6px 0px'>Status</Text></Td>
                    <Td><Text variant="title-md" p='0 0 6px 0px'>Cashback</Text></Td>
                  </Tr>
              </Thead>
              <Tbody>
                { data?.map((row) => (
                  <ReceiptRow 
                    key={row.id} 
                    row={row}
                    handleReceipt={handleReceipt} />
                ))}
              </Tbody>
            </Table>

            <ReceiptModal 
              isOpen={isOpen}
              onClose={onClose}
              newObject={newObject}
              setNewObject={setNewObject}
              newObjectErrors={newObjectErrors} 
              setNewObjectErrors={setNewObjectErrors} 
              handleCreateObject={handleCreateObject}
              prevObject={prevObject}
              nextObject={nextObject}
              prevBtnDisable={prevBtnDisable}
              nextBtnDisable={nextBtnDisable}
              autoNext={autoNext}
              setAutoNext={setAutoNext}
            />

            { validateLoadMore(data, dataMeta) && (
              <Button variant="ghost" onClick={loadMore} m="16px auto" display="block">
                Show More
              </Button>
            )}
          </Card>
        )}
      </Box>
    </>
  );
}

export default Receipts;