// Core
import { useState, useEffect, useCallback } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { getInitials } from 'services/Utils';
import { validateLoadMore, parseDateTime } from 'services/Utils';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import PartnerRow from './components/PartnerRow';
import NotFound from 'views/Dashboard/404.js';

const Partners = () => {
  const textColor = useColorModeValue('gray.700', 'white');

  const [offset, setOffset] = useState(0); 
  const [partners, setPartners] = useState([]);  
  const [partnersMeta, setPartnersMeta] = useState(); 
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const { isLoading: isPartnersLoading } = useQuery({
    queryKey: ['partners', offset],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getPartners({limit: 250, offset});
      if(offset === 0){
        setPartners(response.data);
      } else {
        setPartners([...partners, ...response.data]);
      }
      
      setPartnersMeta(response.meta);
      return true;
    }
  });

  const loadMore = async () => {
    setOffset(offset + 25);
  };

  const onRowClicked = useCallback((category) => {
    history.push(`/partners/${category.id}`);
  }, [history]);

  const onNewButtonClicked = useCallback(() => {
    history.push('/partners/new');
  }, [history]);

  return (
    <>
      <Button variant="submit-ms" position="absolute" right="0" top="80px" mr="31px" maxW="150px" onClick={onNewButtonClicked} leftIcon={<FaPlus />}>Partner</Button>
      { partners.length > 0 ? (
        <>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing="8px">
            {partners?.map(partner => (
              <PartnerRow handleEditOpen={onRowClicked} key={partner.id} partner={partner} />
            ))}
          </SimpleGrid>

          { validateLoadMore(partners, partnersMeta) && (
            <Button variant="ghost" onClick={loadMore} m="16px auto" display="block">
              Show More
            </Button>
          )}
        </>
        ) : (
          <NotFound text="Loading..." />
        )
      }    
    </>
  );
};

export default Partners;