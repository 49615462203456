// Core
import { useState, useEffect, useCallback } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { getInitials, parseDateTime, validateLoadMore } from 'services/Utils';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import NotFound from 'views/Dashboard/404.js';
import AchievementRow from './components/AchievementRow';

const Achievements = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');

  const history = useHistory();

  const [offset, setOffset] = useState(0);
  const [data, setData] = useState();
  const [dataMeta, setDataMeta] = useState(); 
  const [dataUpdater, setDataUpdater] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState({});

  const onNewButtonClicked = useCallback(() => {
    history.push('/achievements/new');
  }, [history]);

  const onRowClicked = useCallback((achievement) => {
    history.push(`/achievements/${achievement.id}`);
  }, [history]);

  const { refetch, isLoading: isDataLoading } = useQuery({
    queryKey: ['data', offset],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getAchievements({limit: 25, offset});
      if(offset === 0){
        setData(response.data);
      } else {
        setData([...data, ...response.data]);
      }
      
      setDataMeta(response.meta);
      return true;
    }
  });

  const handleDeleteData = useCallback(async (d) => {
    await apiService.deleteAchievement(d);
    setDataUpdater(dataUpdater + 1);
    setIsDeleteModalOpen(false);
    refetch();
  }, [dataUpdater]);

  const handleDelete = useCallback((d) => {
    setDataToDelete(d);
    setIsDeleteModalOpen(true);
  }, []);

  const loadMore = async () => {
    setOffset(offset + 25);
  };

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor}>Achievements</Text>
      <Button variant="submit-ms" position="absolute" right="31px" top="80px" maxW="150px" onClick={onNewButtonClicked} leftIcon={<FaPlus />}>Create</Button>
      <Divider opacity="1" mr="2px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0" mb='32px'/>
      
      { data?.length > 0 ? (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="8px">
          {data?.map(item => (
            <AchievementRow handleEditOpen={onRowClicked} key={item.id} data={item} handleDelete={handleDelete}  />
          ))}
        </SimpleGrid>
        ) : (
          <NotFound text="Not Found" />
        )
      } 

      { validateLoadMore(data, dataMeta) && (
        <Button variant="ghost" onClick={loadMore} m="16px auto" display="block">
          Show More
        </Button>
      )}

      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay />
        <ModalContent className="modal__simple" maxW={{ sm: '288px' }}>
          <ModalHeader p='0'>Attention</ModalHeader>
          <ModalCloseButton />
          <ModalBody p='0'>
            <Text variant="text-md" mb="16px">Important Notice</Text>
            <Text variant="text-md" mb="16px">Are you sure of your choice? Once deleted, it will be impossible to return the data.</Text>
          </ModalBody>

          <ModalFooter p='0' mt='24px' >
            <Button variant='ghost' mr={3} onClick={() => setIsDeleteModalOpen(false)}>
              Close
            </Button>
            <Button disabled variant="submit-md" onClick={() => handleDeleteData(dataToDelete)}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>   
    </>
  );
};

export default Achievements;