/*eslint-disable*/
// Core
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

// Chakra imports
import {
    Box,
    Button, Flex,
    Link,
    Icon,
    Stack,
    Text,
    Image,
    useColorModeValue
} from "@chakra-ui/react";

// Assets
import logo from "assets/img/logo.png";
import { ReactComponent as SidebarArrow } from "assets/svg/sidebar-arrow-rotated.svg";

// Components
import IconBox from "components/Icons/IconBox";
import { Separator } from "components/Separator/Separator";

// this function creates the links and collapses that appear in the sidebar (left menu)
const SidebarContent = ({ logoText, routes, isSidebarStack, setSidebarStack }) => {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName) ? "active" : "";
  };

  //const isActive = (path) => location.pathname.includes(path);

  const createLinks = (routes) => {
    // Chakra Color Mode
    const activeBg = useColorModeValue("white", "gray.700");
    const inactiveBg = useColorModeValue("white", "gray.700");
    const activeColor = useColorModeValue("gray.700", "#099BFF");
    const inactiveColor = useColorModeValue("gray.400", "white");
    
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      // activeRoute(prop.layout + prop.path) === "active" 

      //console.log(location.pathname, prop.layout + prop.path);

      if (prop.category) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <div key={prop.name}>
            {createLinks(prop.views)}
          </div>
        );
      }

      return (
        <NavLink to={prop.layout + prop.path} key={prop.name}>
          <Flex mb="8px" className="item-sidebar">
            {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    color="blue.200"
                    w="50px" 
                    h="50px" 
                    p="0 16px 0 15.5px"
                    borderRadius="7px"
                  >
                    {activeRoute(prop.layout + prop.path) === "active" ? prop.iconActive : prop.icon}
                  </IconBox>
                )}
            <Text ml='7px' color={activeRoute(prop.layout + prop.path) === "active" ? activeColor : inactiveColor} my="auto" fontSize="14px" fontWeight="600">
              {document.documentElement.dir === "rtl"
                ? prop.rtlName
                : prop.name}
            </Text>
          </Flex>
        </NavLink>
      );
    });
  };

  const links = <>{createLinks(routes)}</>;

  const showSidebarStack = () => setSidebarStack(!isSidebarStack)

  return (
    <>
    <Box pt={"69px"} mb="25px" ml="9px">
      <Box 
        onClick={showSidebarStack} 
        transform={isSidebarStack ? "rotate(0.5turn)" : "rotate(0turn)" }
        cursor="pointer"
        >
        <SidebarArrow color="#099BFF"/>
      </Box>
    </Box>
    <Stack direction="column" mb="40px">
      <Box className="page-list">{links}</Box>
    </Stack>
    </>
  )
}

export default SidebarContent