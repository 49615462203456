// Core
import { HiDotsVertical } from 'react-icons/hi';
import {useEffect, useState, useCallback} from 'react';
import { FaUser } from 'react-icons/fa6';
import { IoTicketSharp } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Grid,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { parseDate } from 'services/Utils';
import { ReactComponent as InfoControls } from 'assets/svg/controls.svg';
import partnerLogo from 'assets/img/partner-logo.jpg';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { TicketIcon } from 'components/Icons/Icons';

const PartnerRow = ({ partner, handleEditOpen, handleActiveChange }) => {
  const dividerColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');
  const dateColor = useColorModeValue('blue.50', '#E1E1E1');
  
  return (
    <Card key={partner.id} padding="24px">
      <CardBody>
      <Box width="100%">
          <Flex justifyContent="space-between" width="100%">
            <Flex gap="12px">
              <Image
                w="105px"
                h="105px"
                borderRadius="8px"
                objectFit="cover"
                src={ partner?.image ? partner.image : partnerLogo } />

              <Box position="relative">
                <Text variant="title-lg" fontSize="20px" onClick={() => handleEditOpen(partner)} cursor="pointer">{partner.name}</Text>
                
                <Box position="absolute" bottom="0px">
                  <Flex gap="6px">
                      { partner?.categories?.map((category, index) => (
                        <Text variant="title" fontSize="15px" fontWeight="500" key={index}>
                          {category.name}{index < partner.categories.length - 1 ? ',' : ''}
                        </Text>
                      ))}
                  </Flex>

                  <Flex gap="8px">
                    <Flex>
                      <Text variant="text" color={dateColor}>Start:</Text>
                      <Text variant="title" fontSize="15px" pl="4px">{ partner.from ? parseDate(partner.from) : 'Empty'}</Text>
                    </Flex>
                  
                    <Flex>
                      <Text variant="text" color={dateColor}>End:</Text>
                      <Text variant="title" fontSize="15px" pl="4px">{ partner.to ? parseDate(partner.to) : 'Empty'}</Text>
                    </Flex>
                  </Flex>
                </Box>
              </Box>
            </Flex>

            <Box float="right" m="-7px -16px 0px 0px">
              <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<HiDotsVertical />}
                    variant="ghost"
                    color="white"
                    fontSize="20px"
                  />
                  <MenuList className="context-menu">
                    <MenuItem onClick={() => handleEditOpen(partner)}>Change</MenuItem>
                    <MenuItem>Delete</MenuItem>
                  </MenuList>
              </Menu>
            </Box>
          </Flex>

          <Box mt="16px">
            <Text 
              variant="text" 
              mb="16px" 
              maxW="100%" 
              whiteSpace="nowrap" 
              overflow="hidden" 
              textOverflow="ellipsis"
              >
              {partner?.discountInfo?.title} ({partner?.discountInfo?.discount})
            </Text>
            { /* partner?.discountInfo?.plans && Object.entries(partner.discountInfo.plans)
              .filter(([key, item]) => item.discount !== 0) 
              .map(([key, item], index) => (
                <Flex key={key} gap="8px">
                  <Text variant="text" textTransform="capitalize">{key}:</Text>
                  <Text variant="title">{item.discount}{item.isCurrency ? '$' : '%'}</Text>
                </Flex>
            )) */ }

            <Divider opacity="1" mt="12px" mb='11px' borderColor={dividerColor} borderTopWidth="1px" borderBottomWidth="0"/>

            <Flex gap="8px" mt="6px">
                { partner?.tags?.map((tag, index) => (
                  <Text variant="text" className="tag-blue" px="10px" key={index}>{tag.name}</Text>
                ))}
            </Flex>
          </Box>
        </Box>
      </CardBody>
    </Card>
  );
};

export default PartnerRow;