import { ButtonGroup, Button, Box } from '@chakra-ui/react';
import { useState } from 'react';

const ButtonFilter = ({ buttons, selected, onSelect, icon, w }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="10px"
      background="#0D234D"
      padding="2px"
      top="24px"
      w={w}
      mt="-2px"
    >
      <ButtonGroup w="100%">
        {buttons.map((button, index) => (
          <Box key={button.key} 
            position="relative" w={w} m="0 !important" 
            pointerEvents={button.key === 'disabled' ? 'none !important' : ''}
            opacity={button.key === 'disabled' ? '0.2 !important' : '1'}
            >
            <Button
              variant={selected === button.key ? 'submit' : 'ghost'}
              borderRadius="6px"
              fontWeight={selected === button.key ? '600' : '400 !important'}
              color={selected === button.key ? 'white' : '#9FABCB !important'}
              boxShadow={selected === button.key ? '0px 1px 22.4px 0px rgba(9, 155, 255, 0.60) !important' : ''}
              m="0px !important"
              w={w}
              onClick={() => onSelect(button.key)}
            >
              { button.name !== '' ? button.name 
                : (<button.icon color="white" fontSize="24px" m="5px 0 0 5px" />)
              }
            </Button>

            {index !== buttons.length - 1 && (
              <Box
                w="1px"
                h="12px"
                bg="rgba(255, 255, 255, 0.3)"
                opacity="0.3"
                position="absolute"
                top="50%"
                left="100%"
                transform="translate(-50%, -50%)"
                borderRadius="5px"
              />
            )}
          </Box>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default ButtonFilter;