import { mode } from '@chakra-ui/theme-tools';

export const textStyles = {
  components: {
    Text: {
    	baseStyle: {
	        fontSize: '16px',
		    color: 'gray.200',
		    fontWeight: '400'
	    },
	    variants: {
	    	'title-lg': (props) => ({
	   		  fontSize: '18px',
	          fontWeight: '600',
	          color: mode('blue.800', 'white')(props),
	        }),
	    	'title': (props) => ({
	   		  fontSize: '16px',
	          fontWeight: '600',
	          color: mode('blue.800', 'white')(props),
	        }),
	        'title-md': (props) => ({
	   		  fontSize: '14px',
	          fontWeight: '600',
	          color: mode('blue.800', 'white')(props),
	        }),
	        'text': (props) => ({
	          fontSize: '16px',
	          fontWeight: '400',
	          color: mode('blue.800', '#E1E1E1')(props)
	        }),
	        'text-md': (props) => ({
	          fontSize: '14px',
		      fontStyle: 'normal',
			  fontWeight: '400',
			  color: mode('gray.75', '#E1E1E1')(props),
			  lineHeight: '20px'
	        }),
	        'text-sm': (props) => ({
	          fontSize: '12px',
		      fontStyle: 'normal',
			  fontWeight: '400',
			  color: mode('gray.75', 'white')(props),
			  lineHeight: '20px'
	        }),
	    }
    }
  },
};
