// Core
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FaPlus } from 'react-icons/fa6';

// Chakra
import { 
  Button, 
  Divider,
  Flex,
  useColorModeValue, 
  Table, 
  Text, 
  Thead, 
  Tr, 
  Td, 
  Tbody, 
  Select, 
  SimpleGrid,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody,
  ModalFooter,
  Image
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { validateLoadMore } from 'services/Utils';

// Components
import OffersRow from './components/OffersRow';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import NotFound from 'views/Dashboard/404.js';

const Offers = () => {
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');

  const [offset, setOffset] = useState(0);
  const [offers, setOffers] = useState();
  const [offersMeta, setOffersMeta] = useState(); 
  const [offersUpdater, setOffersUpdater] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [offersToDelete, setOffersToDelete] = useState({});

  const userSite = process.env.REACT_APP_MAIN_SITE || '#';

  const { refetch, isLoading: isOffersLoading } = useQuery({
    queryKey: ['offers', offset],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getBanners({limit: 25, offset, type: 'offers'});
      if(offset === 0){
        setOffers(response.data);
      } else {
        setOffers([...offers, ...response.data]);
      }
      
      setOffersMeta(response.meta);
      return true;
    }
  });

  const onNewOffersButtonClicked = useCallback(() => {
    history.push('/offers/new');
  });

  const onRowClicked = useCallback((offer) => {
    history.push(`/offers/${offer.id}`);
  }, [history]);

  const handleDeleteOffers = useCallback(async (offer) => {
    await apiService.deleteBanner(offer);
    setOffersUpdater(offersUpdater + 1);
    setIsDeleteModalOpen(false);
    refetch();
  }, [offersUpdater]);

  const handleDelete = useCallback((offer) => {
    setOffersToDelete(offer);
    setIsDeleteModalOpen(true);
  }, []);

  const loadMore = async () => {
    setOffset(offset + 25);
  };

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='0'>Special Offers</Text>
      <Divider opacity="1" mr="2px" borderColor='rgba(255, 255, 255, 0.10)' borderTopWidth="1px" borderBottomWidth="0" mb='32px'/>
      <Button variant="submit-ms" position="absolute" right="31px" top="80px" maxW="150px" onClick={onNewOffersButtonClicked} leftIcon={<FaPlus />}>Create</Button>
      { offers?.length > 0 ? (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="8px">
          {offers?.map(offer => (
            <OffersRow handleEditOpen={onRowClicked} key={offer.id} offer={offer} handleDelete={handleDelete} />
          ))}
        </SimpleGrid>
        ) : (
          <NotFound text="Not Found" />
        )
      }    

      { validateLoadMore(offers, offersMeta) && (
        <Button variant="ghost" onClick={loadMore} m="16px auto" display="block">
          Show More
        </Button>
      )}

      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay />
        <ModalContent className="modal__simple" maxW={{ sm: '288px' }}>
          <ModalHeader p='0'>Attention</ModalHeader>
          <ModalCloseButton />
          <ModalBody p='0'>
            <Text variant="text-md" mb="16px">Important Notice</Text>
            <Text variant="text-md" mb="16px">Are you sure of your choice? Once deleted, it will be impossible to return the offer.</Text>
          </ModalBody>

          <ModalFooter p='0' mt='24px' >
            <Button variant='ghost' mr={3} onClick={() => setIsDeleteModalOpen(false)}>
              Close
            </Button>
            <Button variant="submit-md" onClick={() => handleDeleteOffers(offersToDelete)}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Offers;