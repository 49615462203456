// Core
import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { FaPlus } from 'react-icons/fa6';

// Chakra 
import { 
  Box,
  Button,
  Flex, 
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import NotFound from 'views/Dashboard/404.js';
import EditModal from './components/CategoryModal';
import CategoryRow from './components/CategoryRow';

const Categories = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('white', 'white');
  const paddingTd = '8px 0 16px 24px';
  const paddingTdSub = '8px 0 16px 0';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [objects, setData] = useState();
  const [newObjectErrors, setNewObjectErrors] = useState([]);
  const [newObject, setNewObject] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const { refetch } = useQuery({
    queryKey: ['objects'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getPartnersCategories();
      setData(response.data);
    }
  });

  const handleCreateObject = useCallback(async () => {
    if (newObjectErrors.length > 0) {
      return;
    }

    let objectId = newObject.id;
    if(newObject.parentId){
      newObject.parentId = parseInt(newObject.parentId, 10);
    } else {
      newObject.parentId = null;
    }

    if (newObject.id) {
      try {
        let data = newObject;
        delete data.image;
        const response = await apiService.updatePartnersCategory(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['name']);
          return;
        }
      } catch(e){
        setNewObjectErrors(['name']);
        return;
      }
    } else {
      try {
        const data = newObject;
        const response = await apiService.createPartnersCategory(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['name']);
          return;
        }

        objectId = response?.data?.id;
      } catch(e){
        setNewObjectErrors(['name']);
        return;
      }
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('itemType', 'partner-category');
      formData.append('itemId', objectId);
      formData.append('sync', true);

      try {
        const response = await apiService.uploadImage(formData); 
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    setNewObject({});
    refetch();
  }, [newObject, newObjectErrors.length]);

  const handleDeleteObject = useCallback(async (object) => {
    await apiService.deletePartnersCategory(object);
    setNewObject({});
    refetch();
  });

  const handleDataOpen = useCallback((category) => {
    setNewObject(category);
    onOpen();
  });

  const handleFeaturedChange = useCallback(async (category) => {
    await apiService.updatePartnersCategory({ ...category, featured: !category.featured });
    refetch();
  });

  const handleShowOnLeftChange = useCallback(async (category) => {
    await apiService.updatePartnersCategory({...category, showOnLeft: !category.showOnLeft });
    refetch();
  });

  return (
    <>
      <Button variant="submit-ms" 
        position="absolute" right="0" top="80px" mr="31px" 
        maxW="150px" 
        onClick={() => handleDataOpen()}
        leftIcon={<FaPlus />}>Category</Button>

      { objects?.length > 0 ? (
      <Card padding="8px 0 0 0" maxW={{ sm: '100%'}} overflow="hidden">
        <CardBody>
          <Table color={textColor}>
            <Thead>
                <Tr>
                  <Td><Text variant="title-md" p='0 0 6px 54px'>Name</Text></Td>
                  <Td><Text variant="title-md" p='0 0 6px 0'>Image</Text></Td>
                  <Td><Text variant="title-md" p='0 0 6px 0'>Subcategories</Text></Td>
                  <Td><Text variant="title-md" p='0 0 6px 0'>Featured</Text></Td>
                  <Td><Text variant="title-md" p='0 0 6px 0'>Show On Left</Text></Td>
                  <Td></Td>
                </Tr>
            </Thead>
            <Tbody>
              { objects.map((row) => (
                <CategoryRow 
                  key={row.id} 
                  row={row} 
                  handleShowOnLeftChange={handleShowOnLeftChange}
                  handleFeaturedChange={handleFeaturedChange}
                  handleDeleteObject={handleDeleteObject}
                  handleDataOpen={handleDataOpen} />
              ))}
            </Tbody>
          </Table>
        </CardBody>

        <EditModal
          newObject={newObject} 
          newObjectErrors={newObjectErrors} 
          objects={objects} 
          setNewObject={setNewObject} 
          setNewObjectErrors={setNewObjectErrors} 
          handleDeleteObject={handleDeleteObject} 
          handleCreateObject={handleCreateObject} 
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          setSelectedFile={setSelectedFile}
        />
      </Card>
      ) : (
      <NotFound text="Categories not found" />
    )}
     </>
  );
};

export default Categories;