// Core
import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';

// Chakra imports
import {
  AbsoluteCenter,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Divider,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  IconButton,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

// Assets
import logo from 'assets/img/logo.png';
import { apiService } from 'services/ApiService';
import { validateEmail, validatePassword } from 'services/Utils';
import 'react-phone-number-input/style.css';

function SignIn() {
  // Chakra color mode
  const ttlColor = useColorModeValue('ttl.300');
  const borderColor = useColorModeValue('gray.200');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);

  const [differentErrorPassword, setDifferentErrorPassword] = useState(false);
  const [patternsErrorPassword, setPatternsErrorPassword] = useState(false);
  const [isValidErrorCode, setValidErrorCode] = useState(false); 

  const [step, setStep] = useState(1);

  const [isValid, setIsValid] = useState(false);
  const [isValid2, setIsValid2] = useState(false);

  const [isValidInputEmail, setIsValidInputEmail] = useState(true);  
  const [isValidInputPhone, setIsValidInputPhone] = useState(true); 
  const [isValidInputCode, setIsValidInputCode] = useState(true); 
  const [isValidInputPassword, setIsValidInputPassword] = useState(true); 
  const [isValidInputRePassword, setIsValidInputRePassword] = useState(true); 

  const { isOpen, onOpen } = useDisclosure();

  const handlePassClick = () => setShow(!show); 
  const handlePassClick2 = () => setShow2(!show2); 

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setIsValid(validateEmail(inputEmail));
    setIsValidInputEmail(true);
  };
  const handlePhoneChange = value => {
    setPhone(value);
    setIsValid(value && value.length > 0 ? true : false);
    setIsValidInputPhone(true);
  };
  const handleCodeChange = (event) => {
  	const value = event.target.value;
  	if (value.length <= 6 && (!value.startsWith('0') || value === '0')) {
  		setCode(event.target.value);
  	}

  	if(event.target.value.length === 0 || password.length === 0 || rePassword.length === 0){
  		setIsValid2(false);
  	} else {
  		setIsValid2(true);
  	}

  	setValidErrorCode(false);
  	setIsValidInputCode(true);
  };
  const handlePasswordChange = (event) => {
  	const value = event.target.value;

  	setPassword(value);

  	if(code.length === 0 || value.length === 0 || rePassword.length === 0){
  		setIsValid2(false);
  	} else {
  		setIsValid2(true);
  	}

  	setDifferentErrorPassword(false);
  	setPatternsErrorPassword(false);
  	setIsValidInputPassword(true);
  };
  const handleRePasswordChange = (event) => {
  	setRePassword(event.target.value);
  	if(code.length === 0 || password.length === 0 || event.target.value.length === 0){
  		setIsValid2(false);
  	} else {
  		setIsValid2(true);
  	}

  	setDifferentErrorPassword(false);
  	setIsValidInputRePassword(true);
  };

  const handleSubmitEmailPhone = async (event) => {
    event.preventDefault();  

    const data = {};
    let error = false;
    if(email){
      data['email'] = email;
    } else if(phone){
      data['phone'] = phone;
    } else {
      return;
    }

    try {
	    const response = await apiService.recoverPassword(data);
	    if(response.statusCode === 200){
	    	setStep(2);
	    } else {
	    	error = true;
	    }
    } catch (e) { error = true; }

	
    if(error){
      if(email){
        setIsValidInputEmail(false);
      } else {
        setIsValidInputPhone(false);
      }
    }
  };

  const handleSubmitForgot = async (event) => {
    event.preventDefault();
    let error = false;

    try {
    	if(password !== rePassword){
    		setIsValidInputPassword(false);
        setIsValidInputRePassword(false);
        setDifferentErrorPassword(true);
    		error = true;
    	}

      if (!validatePassword(password)) {
        setPatternsErrorPassword(true);
        error = true;
      }

      if(code.length < 6){
        setValidErrorCode(true);
      }

    	const data = { code, password };

    	if(email){
    		data['email'] = email;
    	} else if(phone){
    		data['phone'] = phone;
    	} else {
    		error = true;
    	}

    	if(!error){
    		const response = await apiService.recoverPassword(data);
		    if(response){
		        onOpen();
		    } else {
		    	setValidErrorCode(true);
		    	error = true;
		    }	
    	}
    } catch (e) { error = true; }

    if(error){
      if (code.length !== 6) {
          setIsValidInputCode(false);
      }
      setIsValidInputPassword(false);
      setIsValidInputRePassword(false);
    }
  };

  return (
    <Flex
      direction='column'
      w='100%'
      background='transparent'
      p='0px'
      mt={{ md: '150px', lg: '80px' }}>
      <Box
        mb='64px'
        h='100%'
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <NavLink to="/">
          <Image
            src={logo}
            alt='lmctplus image'
            maxWidth="300px"
          />
        </NavLink>
      </Box>
      <Heading color={ttlColor} fontSize='40px' mb='0px'>
        Forgot password
      </Heading>
      <Text
        mb='30px'
        ms='0px'
        color='gray.400'
        fontWeight='400'
        fontSize='16px'>
        Enter the required information to reset your password
      </Text>
      {step === 1 && (
      	<>
	    <FormControl variant="floating" id="email">
	        <Input
	          value={email}
	          onChange={handleEmailChange}
	          mb='34px'
	          type='email'
	          placeholder=''
	          autoComplete="off"
	          className={isValidInputEmail ? 'input-valid' : 'input-invalid'}
	        />
	        <FormLabel htmlFor="email" className={isValidInputEmail ? 'input-valid' : 'input-invalid'}>Email</FormLabel>
	        <FormHelperText className={isValidInputEmail ?  'hidden' : 'input-error'}>This email does not exist</FormHelperText>
		    <Box 
			    mb='34px'
		        ms='0px'
		        color='gray.50'
		        fontWeight='400'
		        fontSize='16px'>
              <Divider 
                opacity='1'
                borderColor='gray.50' 
                borderTopWidth="0.05em" 
                borderBottomWidth="0"/>
              <AbsoluteCenter bg='white' px='10px' my='26px'>
				    or
              </AbsoluteCenter>
            </Box>
          </FormControl>
          <FormControl>
            <PhoneInput
		      international
  			  countryCallingCodeEditable={false}
		      defaultCountry="US"
		      value={phone}
		      onChange={handlePhoneChange}
		      mb='24px'
		      pt='15px'
	          type='text'
	          placeholder=''
	          autoComplete="off"
	          color='gray.200'
	          className={isValidInputPhone ? 'input-valid' : 'input-invalid'}
		    />
		    <FormHelperText top="2px" className={isValidInputPhone ?  'hidden' : 'input-error'}>This phone does not exist</FormHelperText>
	        <Button
	          onClick={handleSubmitEmailPhone}
	          variant="submit"
	          disabled={!isValid}
	          mb='24px'>
	          Continue
	        </Button>
	    </FormControl>
	    </>
	  )}
	  {step === 2 && (
	  	<>
	    <FormControl variant="floating" id="code">
	    	<Input
	          value={code}
	          onChange={handleCodeChange}
	          mb='24px'
	          className={isValidInputCode ? 'input-valid' : 'input-invalid'}
	          type='number'
	          max="6"
	          placeholder=''
	          autoComplete="off"
	        />
	        <FormLabel htmlFor="code" className={isValidInputCode ? 'input-valid' : 'input-invalid'}>Code</FormLabel>
	        <FormHelperText className={isValidErrorCode ? 'input-error' : 'hidden'}>Code invalid</FormHelperText>
	    </FormControl>
	    <FormControl variant="floating" id="password">
		    <InputGroup size='md'>
	          <Input
	            value={password}
	            onChange={handlePasswordChange}
	            borderColor={borderColor}
	            className={isValidInputPassword ? 'input-valid' : 'input-invalid'}
	            mb='24px'
	            placeholder=''
	            autoComplete="off"
	            type={show ? 'text' : 'password'}
	          />
	          <FormLabel htmlFor="password" className={isValidInputPassword ? 'input-valid' : 'input-invalid'}>New password</FormLabel>
	          <FormHelperText className={patternsErrorPassword ? 'input-error' : 'hidden'}>Password needs 8+ chars, upper & lower case, numbers</FormHelperText>
	          <InputRightElement>
	            <IconButton
	              aria-label={show ? 'Hide password' : 'Show password'}
	              icon={show ?  <AiOutlineEye /> : <AiOutlineEyeInvisible />}
	              onClick={handlePassClick}
	              mt='14px'
	              mr='14px'
	              color='gray.200'
	              fontSize='24px'
	              variant='ghost'
	            />
	           </InputRightElement>
	        </InputGroup>
          </FormControl>
	    <FormControl variant="floating" id="rePassword">
	        <InputGroup size='md'>
	          <Input
	            value={rePassword}
	            onChange={handleRePasswordChange}
	            borderColor={borderColor}
	            className={isValidInputRePassword ? 'input-valid' : 'input-invalid'}
	            mb='24px'
	            placeholder=''
	            type={show2 ? 'text' : 'password'}
	          />
	          <FormLabel htmlFor="rePassword" className={isValidInputRePassword ? 'input-valid' : 'input-invalid'}>Enter your new password again</FormLabel>
	          <FormHelperText className={differentErrorPassword ? 'input-error' : 'hidden'}>Different password</FormHelperText>
	          <InputRightElement>
	            <IconButton
	              aria-label={show2 ? 'Hide password' : 'Show password'}
	              icon={show2 ?  <AiOutlineEye /> : <AiOutlineEyeInvisible />}
	              onClick={handlePassClick2}
	              mt='14px'
	              mr='14px'
	              color='gray.200'
	              fontSize='24px'
	              variant='ghost'
	            />
	           </InputRightElement>
	           <FormErrorMessage>Email is required.</FormErrorMessage>
	        </InputGroup>
	        <Button
	          onClick={handleSubmitForgot}
	          variant="submit"
	          disabled={!isValid2}
	          mb='24px'>
	          Continue
	        </Button>
	    </FormControl>
	    </>
	 )}
      <Modal isOpen={!isOpen}>
        <ModalOverlay />
        <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
          <ModalHeader display="center" alignItems="center" justifyContent="center">Congratulations</ModalHeader>
          <ModalBody>
            <Text variant="text-md" display="center" alignItems="center" justifyContent="center" mt="12px" mb="24px">
              Password changed successfully
            </Text>
            <Link as='a' href='/#/auth/signin' >
              <Button variant="submit-md" w='100%'>Sign In</Button>
            </Link>
          </ModalBody>
        </ModalContent>
      </Modal> 
    </Flex>
  );
}

export default SignIn;
