// Core
import { useState, useEffect } from 'react';

// Chakra 
import { 
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { user, parseDate } from 'services/Utils';
import controls from 'assets/svg/controls.svg';
import danger from 'assets/svg/danger.svg';
import checkMark from 'assets/svg/check-mark.svg';
import close from 'assets/svg/close.svg';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const Profile = (props) => {
  // Chakra color mode
  const {setNotify, fetchDataUser, dataUser, setDataUser, initialImage} = props;
  const textColor = useColorModeValue('gray.800', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];
  const [fontSizeData, fontSizeDataWeight] = ['18px', '700'];
  const paddingTd = '16px 24px 55px 24px';
  const borderColor = useColorModeValue('white', 'white');
  const { colorMode, toggleColorMode } = useColorMode();
  const [switched, setSwitched] = useState(props.isChecked);


  const reqSvgs = require.context('assets/svg/avatars', false, /\.svg$/);
  let avatars = reqSvgs.keys().map((filename) => ({
    path: reqSvgs(filename),
    number: parseInt(filename.match(/\d+/)[0], 10), 
  }));
  avatars = [...new Map(avatars.map(avatar => [avatar.number, avatar])).values()]
  .sort((a, b) => a.number - b.number);

  const [showFirstName, setFirstName] = useState(false);
  const [showLastName, setLastName] = useState(false);
  const [showBirthDate, setBirthDate] = useState(false);
  const [showGender, setGender] = useState(false);
  const [statusKYC, setStatusKYC] = useState(false); // Temporary variable

  const navbarBorderColor = useColorModeValue('blue.25', 'blue.25');
  const navbarBorderWidth = '0.1em';

  const handleShowFirstName = () => setFirstName(!showFirstName); 
  const handleShowLastName = () => setLastName(!showLastName); 
  const handleShowBirthDate = () => setBirthDate(!showBirthDate); 
  const handleShowGender = () => setGender(!showGender); 

  const handleFirstName = (event) => setDataUser({...dataUser, firstName: event.target.value});
  const handleLastName = (event) => setDataUser({...dataUser, lastName: event.target.value});
  const handleBirthDate = (event) => setDataUser({...dataUser, birthDate: event.target.value});
  const handleGender = (event) => setDataUser({...dataUser, gender: event.target.value});
  const handleAvatar = (index) => {
    setDataUser((prevDataUser) => ({ ...prevDataUser, image: index.toString() }));
  };

  useEffect(() => { 
    fetchDataUser();
  }, []); 

  const handleSubmitSaveProfile = async (event) => {
    event.preventDefault(); 

    let data = {
      name: dataUser.name,
      username: dataUser.username,
      firstName: dataUser.firstName,
      lastName: dataUser.lastName,
      birthDate: dataUser.birthDate,
      gender: dataUser.gender,
    };

    try {
      await apiService.updateUser(data);
    } catch (e) {}

    setFirstName(false);
    setLastName(false);
    setBirthDate(false);
    setGender(false);
  };

  useEffect(() => {
    const updateUserImage = async () => {
      const data = {
        image: dataUser.image,
      };

      try {
        await apiService.updateUser(data);
      } catch (e) {
        console.error(e);
      }
    };

    if (initialImage !== '' && dataUser.image && dataUser.image !== initialImage) {
      updateUserImage();
    }
  }, [dataUser.image, initialImage]);

  return (
    <>
    <Text variant="title-lg" mb="16px">General information</Text>
    <Card padding='8px 0' my={{ sm: '22px', xl: '0px' }} maxW={{sm: '100%', lg: '840px'}}>
      <CardBody>
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>First Name</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                { !showFirstName ? (
                <Text fontSize={fontSizeData} color={textColor} fontWeight={fontSizeDataWeight}>
                  {dataUser.firstName}
                </Text>
                ) : (
                <FormControl variant="floating" id="firstName" w={{sm: '70%'}} pb='0px' mb='-20px' >
                  <Input type='text' placeholder='' autoComplete="off" mb='-20px' pb='0px' onChange={handleFirstName} />
                  <FormLabel p='2px' htmlFor="firstName">First Name</FormLabel>
                </FormControl>
                )}
              </Td>
              <Td verticalAlign="top">
                <Flex justifyContent="flex-end">
                { !showFirstName ? (
                    <Image src={controls} cursor="pointer" onClick={handleShowFirstName}/>
                ) : (
                <>
                    <Image src={close} cursor="pointer" onClick={handleShowFirstName} me='16px'/>
                    <Image src={checkMark} cursor="pointer" onClick={handleSubmitSaveProfile}/>
                    </>
                )}
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Last Name</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                { !showLastName ? (
                <Text fontSize={fontSizeData} color={textColor} fontWeight={fontSizeDataWeight}>
                  {dataUser.lastName}
                </Text>
                ) : (
                <FormControl variant="floating" id="lastName" w={{sm: '70%'}} pb='0px' mb='-20px' >
                  <Input type='text' placeholder='' autoComplete="off" mb='-20px' pb='0px' onChange={handleLastName} />
                  <FormLabel p='2px' htmlFor="lastName">Last Name</FormLabel>
                </FormControl>
                )}
              </Td>
              <Td verticalAlign="top">
                <Flex justifyContent="flex-end">
                { !showLastName ? (
                    <Image src={controls} cursor="pointer" onClick={handleShowLastName}/>
                ) : (
                <>
                    <Image src={close} cursor="pointer" onClick={handleShowLastName} me='16px'/>
                    <Image src={checkMark} cursor="pointer" onClick={handleSubmitSaveProfile}/>
                    </>
                )}
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Date of Birth</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                { !showBirthDate ? (
                <Text fontSize={fontSizeData} color={textColor} fontWeight={fontSizeDataWeight}>
                  {parseDate(dataUser.birthDate)}
                </Text>
                ) : (
                <FormControl variant="floating" id="birthDate" w={{sm: '70%'}} pb='0px' mb='-20px' >
                  <Input type='date' placeholder='' autoComplete="off" mb='-20px' pb='0px' onChange={handleBirthDate} />
                  <FormLabel p='2px' htmlFor="birthDate">Date of Birth</FormLabel>
                </FormControl>
                )}
              </Td>
              <Td verticalAlign="top">
                <Flex justifyContent="flex-end">
                { !showBirthDate ? (
                    <Image src={controls} cursor="pointer" onClick={handleShowBirthDate}/>
                ) : (
                <>
                    <Image src={close} cursor="pointer" onClick={handleShowBirthDate} me='16px'/>
                    <Image src={checkMark} cursor="pointer" onClick={handleSubmitSaveProfile}/>
                    </>
                )}
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} border="unset">
                <Text fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>Gender</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd} border="unset">
                { !showGender ? (
                <Text fontSize={fontSizeData} color={textColor} fontWeight={fontSizeDataWeight}>
                  {dataUser.gender === 'Unknown' ? 'Other' : dataUser.gender === 'Transgender' ? 'Prefer not to say' : dataUser.gender}
                </Text>
                ) : (
                <FormControl variant="floating" w={{sm: '70%'}} pb='0px' mb='-34px' >
                  <Select onChange={handleGender} placeholder="Gender" mb='-20px' pb='0px'>
                    <option value='Female'>Female</option>
                    <option value='Male'>Male</option>
                    <option value='Unknown'>Other</option>
                    <option value='Transgender'>Prefer not to say</option>
                  </Select>
                </FormControl>
                )}
              </Td>
              <Td verticalAlign="top" border="unset">
                <Flex justifyContent="flex-end">
                { !showGender ? (
                    <Image src={controls} cursor="pointer" onClick={handleShowGender}/>
                ) : (
                <>
                    <Image src={close} cursor="pointer" onClick={handleShowGender} me='16px'/>
                    <Image src={checkMark} cursor="pointer" onClick={handleSubmitSaveProfile}/>
                    </>
                )}
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </CardBody>
    </Card>
    </>
  );
};

export default Profile;
