// Core
import { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';

// Assets
import routes, { sidebar} from 'routes.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from 'theme/theme.js';
import { apiService } from 'services/ApiService';
//import { user } from 'services/Utils';

// Components
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import Navbar from 'components/Navbars/Navbar.js';
import Sidebar from 'components/Sidebar';

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [ sidebarVariant, setSidebarVariant ] = useState('transparent');
  const [ fixed, setFixed ] = useState(false);
  const [isSidebarStack, setSidebarStack] = useState(true);
  const [initialImage, setInitialImage] = useState('');
  // functions for changing the states from components

  const [dataUser, setDataUser] = useState({ name: '', lastName: '', firstName: '', birthDate: '', gender: '', image: '' });
  const fetchDataUser = async () => {
      try {
        const response = await apiService.getMe();
        setDataUser(response.data);
        setInitialImage(response.data.image);
      } catch (error) {}
  };

  useEffect(() => { 
      fetchDataUser();
  }, []);

  const getRoute = () => {
    return window.location.pathname !== '/full-screen-maps';
  };
  const getActiveRoute = (route) => {
    const activeRoute = '';
    for (let i = 0; i < route.length; i++) {
      if (route[i].collapse) {
        const collapseActiveRoute = getActiveRoute(route[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (route[i].category) {
        const categoryActiveRoute = getActiveRoute(route[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(route[i].layout + route[i].path) !== -1) {
          return route[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (route) => {
    const activeNavbar = false;
    for (let i = 0; i < route.length; i++) {
      if (route[i].category) {
        const categoryActiveNavbar = getActiveNavbar(route[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(route[i].layout + route[i].path) !== -1) {
          if (route[i].secondaryNavbar) {
            return route[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === 'account') {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/') {
        return <Route path={prop.layout + prop.path} key={key}>
				  {routerProps => <prop.component {...routerProps} fetchDataUser={fetchDataUser} dataUser={dataUser}
            setDataUser={setDataUser} initialImage={initialImage}/>}
        </Route>;
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = 'ltr';

  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Sidebar
        routes={sidebar}
        logoText={'LMCT+'}
        display='none'
        sidebarVariant={sidebarVariant}
        {...rest}
        isSidebarStack={isSidebarStack}
        setSidebarStack={setSidebarStack}
        zIndex="2"
        position="relative"
      />
      <MainPanel
			  transition='unset !important'
        w={{
          base: '100%',
          lg: !isSidebarStack ? 'calc(100% - 66px)' : 'calc(100% - 190px)',
          '2xl': '100%'
        }}
        left={{
          base: '0',
          lg: !isSidebarStack ? '30px' : '92px',
          '2xl': '0'
        }}
        maxWidth="1440px"
        float="unset"
        alignItems="center"
        justifyContent="center"
        m="0px auto 0 auto"
      >	
        <Portal>
          <Navbar
            onOpen={onOpen}
            logoText={'LMCT+'}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            dataUser={dataUser}
            {...rest}
            borderTop="1px"
      			borderColor="gray.50"
          />
        </Portal>
        {getRoute() ? (
          <PanelContent mt='60px'>
            <PanelContainer 
              borderLeft={{lg: '0.5px solid rgba(9, 155, 255, 0.10)' }}
              p={{sm: '32px 0px 32px 0px', md: '16px', lg: '10px 16px 150px 32px'}}
            >
              <Switch>
                {getRoutes(routes)}
                <Redirect from='/' to='users' />
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : null}
      </MainPanel>
    </ChakraProvider>
  );
}
