// Core
import { useState } from 'react';
import { Link, Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

// Chakra imports
import { 
  Box, 
  Image,
  Flex, 
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Components
import Card from 'components/Card/Card.js';
import Giveaways from './Giveaways.js';
import GiveawaysEdit from './GiveawaysEdit.js';

function Resources(props) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  const textColor = useColorModeValue('blue.800', 'white');
  const borderColor = useColorModeValue('rgba(255, 255, 255, 0.10)', 'rgba(255, 255, 255, 0.10)');

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='12px'>Giveaways</Text>
      <Box mt="0px" mb="50px" direction='column'>
        <Flex align='left' mb={{ sm: '10px', md: '0px' }}
          direction={{ sm: 'column', md: 'row' }}
          w={{ sm: '100%' }}
          textAlign={{ sm: 'center', md: 'start' }}
          borderBottom="1px"
          borderColor={borderColor}
          className="settings-tabs">
          <Link to={`${url}`} className={isActive(`${path}`) || location.pathname === '/giveaways' ? 'settings-tab-active' : ''}>Major</Link>
        </Flex>
        
        <Box align='left' mt='32px'>
          <Switch>
            <Route path={`${path}/:id`} render={() => <GiveawaysEdit />} />
            <Route path={`${path}`} render={() => <Giveaways />} />
            <Redirect from={`${path}*`} to={`${path}`} />
          </Switch>
        </Box>
      </Box>
    </>
  );
}

export default Resources;