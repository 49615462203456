import { mode } from '@chakra-ui/theme-tools';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

const inputs = {
  transition: '0.3s all !important',
  borderRadius: '10px !important',
  border: '1px solid #9FABCB  !important',
  p: '26px 16px !important',
  fontSize: '16px !important',
  fontWeight: '400 !important',
  _hover: {
    boxShadow: '0 0 0 0.5px #099BFF !important',
  },
  _focus:{
    boxShadow: '0 0 0 0.5px #099BFF !important',
    borderColor: '#099BFF !important'
  }
};

const gender = {
  borderRadius: '30px', 
  w: '100%', 
  maxW: '41px',
  h: '41px', 
  textAlign: 'center',
  'p': { 
    fontSize: '16px',
    fontWeight: '600',
    color: 'white',
    pt: '11px'
  }
};

const tag = {
  p: '3px 0',
  borderRadius: '20px',
  textAlign: 'center',
  mt: '6px',
  maxW: '110px'
};

export const globalStyles = {
  colors: {
    white: {
      0: 'white'
    },
    gray: {
      25: '#FAF9FF',
      50: '#C5C6D0',
      75: '#525E7B',
      90: '#E3E2E6',
      100: '#EDF0FF',
      150: '#919094',
      200: '#757780',
      400: '#757780',
      700: '#1f2733',
      800: '#1B1B1F'
    },
    ttl: {
      200: 'black',
      300: 'black',
      400: 'black'
    },
    teal: {
      200: '#299cef',
      300: '#0094ff',
      400: '#0a7fd4'
    },
    blue: {
      20: '#8490AF',
      25: '#AEC6FF',
      50: '#D8E2FF',
      75: '#292D32',
      100: '#508DFF',
      200: '#1870EC',
      800: '#001A43',
      900: '#050A19'
    },
    btn: {
      0: '#C7C6CA',
      100: '#1870EC',
      200: '#0059C5',
      300: '#004EAE'
    },
    red: {
      0: '#FFE1DF',
      50: '#FFF8F7',
      75: '#FFE2E6',
      100: '#ED1145',
      200: '#BA1A1A',
      300: '#FF2D55',
      400: '#DE3730'
    },
    green: {
      50: '#DFF9E5',
      100: '#22973F',
      150: '#57D175'
    },
    violet: {
      50: '#E3EAFC',
      200: '#4A46BC'
    }
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('#F0F4FD', 'blue.900')(props),
        fontFamily: '\'Inter\', sans-serif'
      },
      html: {
        fontFamily: '\'Inter\', sans-serif'
      },
	    select: {
	    	...inputs,
	    	h: 'unset !important',
	    	p: '16px 15px !important'
	    },
      'select.select-md': {
        ...inputs,
        h: 'unset !important',
        p: '8px 16px !important'
      },
	    input: {
	    	...inputs
	    },
      textarea: {
        ...inputs
      },
	    'input.input-invalid': { borderColor: 'red !important' },
      'select.input-invalid': { borderColor: 'red !important' },
	    '.input-invalid': { 
	    	'input': { borderColor: 'red !important' },
	    },
	    'label.input-invalid': { color: 'red !important' },
	    'button.freeze': { pointerEvents: 'none' },
      '.freeze': { pointerEvents: 'none', opacity: '0.3' },
      '.freeze-iframe': { pointerEvents: 'none', opacity: '0.7' },
	    '.input-error': {
	    	top: 0,
        left: 0,
        zIndex: 2,
        position: 'absolute',
        backgroundColor: '#0e1633',
        pointerEvents: 'none',
        mx: '14px !important',
        px: '4px !important',
        my: '15px !important',
        transformOrigin: 'left top',
        color: 'red !important',
      	fontWeight: '400',
      	fontSize: '14px',
				transform: 'scale(0.85) translateY(35px)',
				lineHeight: 'normal !important'
	    },
	    '.hidden': { display: 'none !important' },
	    '.chakra-modal__content-container': {
        bg: 'rgba(0, 0, 0, 0.30)',
        backdropFilter: 'blur(12px)',
        '.chakra-modal__content': {
          p: '33px',
          pb: '40px',
          borderRadius: '20px !important',
          border: mode('unset', '1px solid rgba(255, 255, 255, 0.10)')(props),
          background: mode('white', '#0E1633')(props),
        },
        '.chakra-modal__header': {
          fontSize: '32px !important',
          fontWeight: '700 !important',
          color: mode('#1B1B1F !important', 'white !important')(props),
          pb: '0px !important'
        },
        '.modal__simple': {
          padding: '22px 24px 24px 24px',
          '*': {
            transition: '0.3s all'
          },
          '.chakra-modal__header': {
            p: '0px',
            pb: '0px',
          },
          '.chakra-modal__body': {
            padding: '0px !important',
          },
          '.chakra-modal__close-btn': {
            borderColor: mode('blue.50', '#0D234D')(props),
            borderWidth: '1px',
            w: '44px',
            h: '44px',
            m: '16px 11px 0px 0',
            borderRadius: '10px',
            bg: mode('unset', '#0D234D')(props),
            outline: 'none !important',
            boxShadow: 'none !important',
            'svg': {
              w: '14px',
              h: '14px',
              color: mode('blue.200', '#099BFF')(props),
            },
            _hover: {
              bg: mode('unset', 'transparent')(props),
            },
            _active: {
              bg: mode('unset', 'transparent')(props),
            },
            _focus: {
              outline: 'none !important',
              boxShadow: 'none !important'
            }
          },
        },
      },
	    '.PhoneInputCountry' : {
	    	position: 'absolute !important',
		    p: '21px 0 0px 20px !important',
		    zIndex: '9 !important'
	    }, 
	    '.PhoneInputInput': { 
	    	p: '15px 20px 15px 70px !important',
        bg: 'transparent',
	    	_focusVisible: {
				  outline: 'none !important'
        } 
	    },
      '.PhoneInputCountryIcon': { fontSize: '14px', boxShadow: 'unset' },
      '.PhoneInputCountrySelectArrow': { 
        margin: '-4px 0 0 8px', 
        fontSize: '23px', 
        color: mode('#1f2733 !important', 'white !important')(props),
        opacity: '1'
      },
      '.PhoneInput': { mb: '24px' },
      '.PhoneInputCountryIcon--border': { boxShadow: 'unset !important' },
      '.vi__container': {
        w: 'unset',
        m: '22px auto 27px auto',
        input: {
          border: 'unset !important',
          _hover: {
					  boxShadow: 'unset !important',
          },
          _focus:{
					  boxShadow: 'unset !important',
					  borderColor: 'unset !important'
          }
        },
        '.vi__character': {
				    borderRadius: '50px',
				    maxWidth: '50px',
				    fontSize: '24px',
				    minWidth: '50px',
				    borderColor: 'gray.50',
				    color: 'gray.800'
        },
        '.character--inactive': { background: 'transparent !important', color: 'gray.800' },
        '.vi__character--filled': { borderColor: 'blue.200', color: 'gray.800', boxShadow: '0 0 0 0.5px #1870EC !important'},
        '.character--selected': { outline: 'unset !important', color: 'gray.800', borderColor: 'blue.200', boxShadow: '0 0 0 0.5px #1870EC !important' },
      },
      '.code-container': {
        w: 'unset',
        m: '22px auto 27px auto',
        input: {
          border: 'unset !important',
          _hover: {
            boxShadow: 'unset !important',
          },
          _focus:{
            boxShadow: 'unset !important',
            borderColor: 'unset !important'
          }
        },
        '.vi__character': {
            borderRadius: '50px',
            minW: '64px',
            maxW: '64px',
            maxH: '64px',
            minH: '64px',
            fontSize: '24px',
            borderColor: 'blue.50',
            borderWidth: '0px',
            color: 'gray.800',
            padding: '6px',
            boxShadow: '0 0 0 1px #D8E2FF !important'
        },
        '.character--inactive': { background: 'transparent !important', color: 'gray.800' },
        '.vi__character--filled': { borderColor: 'blue.200', color: 'gray.800', boxShadow: '0 0 0 1px #1870EC !important'},
        '.character--selected': { outline: 'unset !important', color: 'gray.800', borderColor: 'blue.200' },
        '.input-freeze-code' : {bg: 'gray.100', boxShadow: '0 0 0 1px #D8E2FF !important', pointerEvents: 'none'}
      },
      '.input-invalid-code': {
        '.vi__character': {
          bg: '#FFF8F7',
          borderColor: 'red.200',
          boxShadow: '0 0 0 0.5px red !important' 
        }
      },
      '.circle-object': {
        w: '40px',
        h: '40px',
        borderRadius: '20px',
        color: '#1B1B1F !important',
        fontWeight: '600 !important',
        p: '8px 15px'
      },
      '.boxObject-active': { bg: 'blue.200', color: 'white !important'},
      '.boxObject-inactive': { boxShadow: '0 0 0 1px #C5C6D0' },
      '.chakra-menu__menu-list': {
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06) !important',
        borderRadius: '20px !important',
        borderWidth: '0 !important',
        p: '12px 8px'
      },
      '[role="menuitem"]': {
        _focus: {
		            bg: 'transparent !important',
		        },
		        _hover: {
		            bg: 'transparent !important',
		        },
		        _active: {
		            bg: 'transparent !important',
		        }
      },
      '.settings-tabs': {
        a: {
          fontSize: '16px',
          fontWeight: '600',
          color: mode('gray.800', 'white')(props),
          me: '24px',
          mb: '-1px',
          pb: '5px'
        },
        '.settings-tab-active': {
           color: mode('blue.200', '#0497FD')(props),
           borderColor: mode('blue.200', '#0497FD')(props),
           borderBottom: '1px'
        }
      },
      '.notify-success': {
        bg: 'green.50'
      }, 
      '.notify-error': {
        bg: 'red.0'
      },
      '.chakra-checkbox': {
        transition: '0.0s all !important',
        '*': { transition: '0.0s all !important'}
      },
      'button[aria-haspopup="menu"]': {
        transition: '0.0s all !important',
        '*': { transition: '0.0s all !important'}
      },
      '.chakra-checkbox__control': {
        w: '20px !important',
        h: '20px !important',
        borderRadius: '5px !important',
        borderColor: '#9FABCB !important',
        borderWidth: '0.099em !important',
        _hover: {
          outline: 'none !important',
          boxShadow: 'none !important'
        },
        _active: {
          outline: 'none !important',
          boxShadow: 'none !important',
        },
        _focus: {
          outline: 'none !important',
          boxShadow: 'none !important'
        }
      },
      '.chakra-checkbox__label': {
        pl: '4px'
      },
      '.chakra-checkbox__control[data-checked]':{
        bg: 'linear-gradient(0deg, #0497FD, #2ABDFD) !important',
        border: 'unset'
      },
      '.chakra-switch': {
        transform: 'scale(1.2)',
        '.chakra-switch__track': {
          bg: '#333F6D !important',
          _focus: {
            boxShadow: 'unset !important'
          },
          '> span': {
            bg: '#0A1027 !important'
          },
          '&[data-checked]': {
            bg: '#099BFF !important',
          }
        },
      },
      '.selected-ball': {
        bg: 'linear-gradient(152deg, #34A853 0.67%, #77C01B 39.93%, #34A853 89.58%), #1870EC !important',
        boxShadow: '0px 2px 4px 0px rgba(255, 255, 255, 0.10) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.10) !important',
        fontWeight: '700 !important',
        color: 'white !important',
        position: 'relative !important',
        fontSize: '20px !important',
        pt: '8px !important',
        _after:{
          content: '""',
          position: 'absolute',
          bg: 'rgba(255, 255, 255, 0.79)',
          filter: 'blur(4.300000190734863px)',
          w: '7px',
          h: '19px',
          transform: 'rotate(49.011deg)',
          left: '9px',
          top: '3px'
        }
      },
      'img.current-avatar': {
        boxShadow: '0 0 0 0.1em #1870EC',
        borderRadius: '30px'
      },
      '.active-svg': {
        'path': {
          fill: 'blue.200'
        }
      },
      '.chakra-menu__menu-button': {
        _hover: {
          outline: 'none !important',
          boxShadow: 'none !important',
          bg: 'transparent !important'
        },
        _active: {
          outline: 'none !important',
          boxShadow: 'none !important',
          bg: 'transparent !important'
        },
      },
      '.page-list':{
        svg: {
            w: '28px',
            h: '28px'
        },
        '.active': {
          '.item-sidebar': {
             bg: mode('blue.50', 'rgba(216, 226, 255, 0.05)')(props),
             borderRadius: '10px'
          }
        }
      },
      '.chakra-breadcrumb': {
          mb: '8px',
          a: {
            color: 'blue.20',
            textDecoration: 'none !important', 
            div: {
              span: {
                pl: '16px'
              }
            }
          },
          svg: {
            position: 'absolute',
            mt: '-1px',
            path: { fill: 'blue.20', stroke: 'blue.20' }
          },
          '.active': {
            fontWeight: '600',
            color: '#099BFF',
            pointerEvents: 'none',
          },
          span: {
              ml: '5px !important',
              me: '0px'
          }
      },
      '.react-datepicker-wrapper': {
        w: '100%',
        input: {
            w: '100%',
            h: '54px'
        },
        'input[disabled]': {
            opacity: '0.4',
            background: 'transparent',
            pointerEvents: 'none'
        }
      },
      '.tag-blue': {
        ...tag,
        bg: mode('blue.50', '#099BFF')(props),
        color: mode('#1870EC !important', 'white !important')(props),
      }, 
      '.tag-green': {
        ...tag,
        bg: mode('green.50', 'rgba(87, 209, 117, 0.15)')(props), 
        color: mode('#1E7735 !important', '#57D175 !important')(props), 
      }, 
      '.tag-red': {
        ...tag,
        bg: mode('red.75', 'rgba(255, 159, 173, 0.15)')(props), 
        color: mode('#ED1145 !important', '#FF6982 !important')(props), 
      },
      '.tag-orange': {
        ...tag,
        bg: mode('#FFF1C5', 'rgba(255, 185, 27, 0.15)')(props), 
        color: mode('#E26E00 !important', '#FFB91B !important')(props), 
      },
      '.tag-purple': {
        ...tag,
        bg: '#F6EAFD',
        color: '#AF52DE !important'
      },
      '.tag-gray': {
        ...tag,
        bg: '#EFF0FA',
        color: '#50525A !important'
      },
      '.tag-transparent': {
        ...tag,
        bg: mode('gray.50', '#0D234D')(props), 
        color: mode('black !important', 'white !important')(props), 
      },
      '.tag-secondary': {
        ...tag,
        w: 'fit-content',
        p: '3px 12px',
        bg: mode('#0D234D', '#0D234D')(props), 
        color: mode('white !important', 'white !important')(props), 
        mr: '8px',
        display: 'inline-flex'
      },
      '.cursor-tr': {
        _hover: {
          cursor: 'pointer',
          bg: mode('#FAF9FF', '#10337F !important')(props),
        }
      },
      '.full-width-chart': {
        w: '100% !important',
        '.apx-legend-position-top': {
            'span.apexcharts-legend-text': {
              pl: '20px !important'
            }
        }
      },
      '.gender': {
        '&-male': { ...gender, bg: '#508DFF'},
        '&-female': { ...gender, bg: '#FF2D55'},
        '&-other': { ...gender, bg: '#FF9500'},
        '&-none': { ...gender, bg: '#00C7BE'},
      },
      '.filter-date': {
        p: '0 0 16px 0 !important',
        w: '303px !important',
        overflow: 'hidden',
        button: {
          _hover: {
            bg: '#10337F !important'
          }
        },
        '.react-datepicker-wrapper': {
          p: '8px',
          w: '100%'
        },
        '.filter-date-placeholder': {
          left: '24px',
          top: '17px',
          color: '#9FABCB'
        },
        input: {
          w: '100%',
          h: '40px !important',
          p: '6px 16px !important',
          bg: 'rgba(5, 11, 33, 0.54)',
          mb: '4px',
          borderRadius: '10px !important',
          borderColor: 'unset !important',
          borderWidth: '0 !important',
          color: 'white',
          _hover: {
            outline: 'none !important',
            boxShadow: 'none !important'
          },
          _active: {
            outline: 'none !important',
            boxShadow: 'none !important',
          },
          _focus: {
            outline: 'none !important',
            boxShadow: 'none !important'
          },
          _placeholder: {
            color: 'white'
          }
        }
      }
    })
  }
};
