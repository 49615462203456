// Core
import { useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import qrDefault from 'assets/img/qrDefault.jpeg';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const TwoFAQAModal = (
  {
    authRoute, 
    login,
    password,
    dataUser, 
    setNotify, 
    seconds, 
    startTimer, 
    timerActive, 
    data2fa,
    setData2fa,
    isOpenTwoFA, 
    onOpenTwoFA, 
    onCloseTwoFA
  }) => {
  const textColor = useColorModeValue('gray.800', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];

  const [isLoading, setIsLoading] = useState(false);
  const [isValidInput, setIsValidInput] = useState(true);
  const [isValidInputCode, setIsValidInputCode] = useState(true);
  const [сode, setCode] = useState(''); 
  const [qrCodeImage, setQrCodeImage] = useState('');

  const [twoFAData, setTwoFAData] = useState();

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (authRoute && isOpenTwoFA) {
      handleStep();
      handleStep2();
    }
  }, [authRoute, isOpenTwoFA]);

  // Step only for user profile
  const handleStep = async () => {
    setStep(2);
    if (authRoute) {
      try {
        await apiService.authTwoFA({login, password, type: 'google'});
      } catch (e) {}
    } else {
      try {
        const response = await apiService.userTwoFA({type: 'google'});
        if(response.statusCode === 200){
          setTwoFAData(response.data.qr);
        } 
      } catch (e) {}
    }
  };

  const handleStep2 = async () => {
    setStep(3);

    startTimer(); // stop
    startTimer(); // start
  };

  const handleCode = value => { 
    setCode(value);
    if(value?.length === 6){
      handleSubmitChange(value);
    } 

    setIsValidInputCode(true);
  };

  const handleSubmitChange = async (code) => {
    let error = false;

    setIsLoading(true);

    console.log();

    try {
      if(authRoute){
        const response = await apiService.authTwoFA({login, password, type: 'google', code});
        if(response.statusCode === 200){
          if(response?.data?.token){
            localStorage.setItem('auth', JSON.stringify(response.data));
            window.location.replace('/#/');
          } 
        } else {
          error = true;
        }
        error = true;  
      } else {
        const response = await apiService.userTwoFA({type: 'google', code});
        if(response.statusCode === 200 && response.data.status === true){
          onCloseTwoFA();
          if(data2fa.google === false){
            setNotify({success: true, msg: 'Google 2FA is enabled'});
            setData2fa({...data2fa, google: true });
          } else {
            setNotify({success: true, msg: 'Google 2FA is disabled'});
            setData2fa({...data2fa, google: false });
          }
          setStep(1);
        } else {
          error = true;
        }
        error = true;  
      }
      
    } catch (e) { error = true; }

    if(error){
      setIsLoading(false);
      setIsValidInputCode(false);
    }
  };

  return (
  	<> 
  	<Modal isOpen={isOpenTwoFA} onClose={onCloseTwoFA}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <Text variant="title" fontSize="24px" fontWeight="500" mb="2px">
          Two-Factor Authentication
        </Text>
        <Text variant="text-md" mb="12px">Additional level of security</Text>
        <ModalCloseButton />
        <ModalBody>
          {!authRoute && (
            <>
            <Text mt="16px" variant="title">Download an authenticator app</Text>
            <Text mt="4px" variant="text-md">Use an authentication app (such as Google Authenticator) on your phone.</Text>

            { step === 1 && (
              <Flex justify="space-between" mt="32px">
                <Text variant="text-md" fontWeight="500" mt="12px">Step: 1/3</Text>
                <Button variant="submit-md" maxW="140px" onClick={handleStep}>
                  Next
                </Button>
              </Flex>
            )}
            
            { (step === 2 || step === 3) && ( 
              <>
                <Divider opacity="1" mr="2px" mt="28px" borderColor='blue.50' borderTopWidth="1px" borderBottomWidth="0"/>
                {twoFAData && ( <Text mt="16px" variant="title">Scan the QR code</Text> )}

                <Text variant="text-md" mt={!twoFAData ? '16px' : '4px'} mb={!twoFAData ? '16px' : ''}>
                  Open the authenticator app {twoFAData && ( 'and scan the image below using your phone’s camera.')}
                </Text>

                {twoFAData && ( 
                  <Flex justify="center" mt="16px" mb="32px">
                    <Image src={twoFAData} maxW='142px'/>
                  </Flex>
                )}

                { step === 2 && ( 
                  <Flex justify="space-between" mt="32px">
                    <Text variant="text-md" fontWeight="500" mt="12px">Step: 2/3</Text>
                    <Button variant="submit-md" maxW="140px" onClick={handleStep2}>
                      Next
                    </Button>
                  </Flex>
                )}
              </>
            )}
            </>
          )}

          { step === 3 && ( 
            <>
              <Divider opacity="1" mr="2px" borderColor='blue.50' borderTopWidth="1px" borderBottomWidth="0"/>
              <Text mt="16px" variant="title">
                Verify your code 
                { isLoading && (
                  <Spinner size="sm" color="blue.200" m="0 0 -2px 8px"/>
                )}
              </Text>
              <Text mt="4px" variant="text-md" mb="-7px">Enter the 6-digit verification code that will appear in the authentication application after scanning the code.</Text>
              <VerificationInput
                length={6}
                placeholder=""
                validChars="0-9"
                classNames={{
                  container: `code-container ${isValidInputCode ? 'input-valid-code' : 'input-invalid-code'}`,
                  character: `code-character ${isLoading ? 'input-freeze-code' : ''}`,
                  characterInactive: 'character--inactive',
                  characterSelected: 'character--selected',
                }}
                onChange={handleCode}
              />
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default TwoFAQAModal;
