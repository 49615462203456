// Core
import { useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { validateEmail } from 'services/Utils';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const ChangeEmailModal = ({dataUser, setNotify, seconds, startTimer, timerActive, isOpenEmail, onOpenEmail, onCloseEmail}) => {
  // Chakra color mode

  const textColor = useColorModeValue('gray.800', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];

  const [isLoading, setIsLoading] = useState(false);
  const [isValidInputCode, setIsValidInputCode] = useState(true);
  const [сode, setCode] = useState(''); 

  const [change, setChange] = useState({ old: '', new: '', re: '' });

  const [isValidInputOld, setIsValidInputOld] = useState(true); 
  const [isValidInputNew, setIsValidInputNew] = useState(true); 
  const [isValidInputRe, setIsValidInputRe] = useState(true); 

  const [step, setStep] = useState(1);

  const [isValidSteps, setValidSteps] = useState({step1: false, step2: false}); 

  // Handles 
  const handleOld = (event) => {
    setChange({...change, old: event.target.value});
    setIsValidInputOld(true);
  };

  const handleNew = (event) => {
    setChange({...change, new: event.target.value});
    setIsValidInputNew(true);
  };

  const handleRe = (event) => {
    setChange({...change, re: event.target.value});
    setIsValidInputRe(true);
  };

  useEffect(() => {
    if (change?.old?.length > 0 
    	&& change?.new?.length > 0 
    	&& change?.re?.length > 0 
    ) {
      setValidSteps({...isValidSteps, step1: true});
    } else {
      setValidSteps({...isValidSteps, step1: false});
      return;
    }
    setValidSteps({...isValidSteps, step1: validateEmail(change.new)});
  }, [change]); 

  const handleChange = async (event) => {
    event.preventDefault();

    let error = false;
    if(change?.old !== dataUser.email){
    	setIsValidInputOld(false);
    	error = true;
    }

    if(change?.new !== change?.re){
    	setIsValidInputRe(false);
    	error = true;
    }

    if(change?.new === change?.old){
      setIsValidInputNew(false);
      error = true;
    }

    if(error){
    	return;
    }

    try {
      const response = await apiService.updateUserEmail({ email: change.new });
      if(response.statusCode === 200 && response.data.status === true){
        setStep(2);
        startTimer(); // stop
        startTimer(); // start
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    if(error){
      setIsValidInputOld(false);
    }
  };

  const handleCode = value => { 
    setCode(value);
    if(value?.length === 6){
      handleSubmitChange(value);
    } 

    setIsValidInputCode(true);
  };

  const handleSubmitChange = async (code) => {
    let error = false;

    setIsLoading(true);

    try {
      const response = await apiService.updateUserEmail({email: change.new, code});
      if(response.statusCode === 200 && response.data.status === true){
      	onCloseEmail();
        setNotify({success: true, msg: 'Your old email was successfully updated to the new one.'});
        setStep(1);
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    if(error){
      setIsLoading(false);
      setIsValidInputCode(false);
    }
  };

  return (
  	<> 
  	<Modal isOpen={isOpenEmail} onClose={onCloseEmail}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">
        { step === 1 ? 'Email Update' : 'Confirmation' }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          { step === 1 ? (
            <>
            <Text variant="text-md" mb="12px">Due to the name change, this kyc may stop working.</Text>
            <Flex justifyContent="flex-end" w="100%" mb="38px">
	          		<Divider opacity="1" mr="2px" borderColor='blue.200'  borderTopWidth="4px" borderBottomWidth="0"/>
	          		<Divider opacity="1" borderColor='gray.100'  borderTopWidth="4px" borderBottomWidth="0" />
	          </Flex>
            <FormControl variant="floating" id="old-email">
	            <Input type='text' placeholder='' autoComplete="off" mb='40px' onChange={handleOld}  className={isValidInputOld ? 'input-valid' : 'input-invalid'}  />
	            <FormLabel htmlFor="old-email" className={isValidInputOld ? 'input-valid' : 'input-invalid'}>Old Email</FormLabel>
	            <FormHelperText className={isValidInputOld ? 'hidden' : 'input-error' }>This is not your email</FormHelperText>
	          </FormControl>

	          <FormControl variant="floating" id="new-email">
	            <Input type='text' placeholder='' autoComplete="off" mb='40px' onChange={handleNew}  className={isValidInputNew ? 'input-valid' : 'input-invalid'}  />
	            <FormLabel htmlFor="new-email" className={isValidInputNew ? 'input-valid' : 'input-invalid'}>New Email</FormLabel>
	            <FormHelperText className={isValidInputNew ? 'hidden' : 'input-error' }>The email must be different from the current one</FormHelperText>
	          </FormControl>

	          <FormControl variant="floating" id="re-email">
	            <Input type='text' placeholder='' autoComplete="off" mb='32px' onChange={handleRe}  className={isValidInputRe ? 'input-valid' : 'input-invalid'}  />
	            <FormLabel htmlFor="re-email" className={isValidInputRe ? 'input-valid' : 'input-invalid'}>Confirm your new email address.</FormLabel>
	            <FormHelperText className={isValidInputRe ? 'hidden' : 'input-error' }>Email different</FormHelperText>
	          </FormControl>

	          <Flex justify="space-between">
	          	<Text variant="text-md" fontWeight="500" mt="12px">Step: 1/2</Text>
	          	<Button variant="submit-md" maxW="140px" disabled={!isValidSteps.step1} onClick={handleChange}>
  		          Confirm
  		        </Button>
	          </Flex>
	          </>
          ) : (
	        <>
	          	<Text variant="text-md" mb="15px" maxW={{sm: '80%'}}>To confirm your email, you will need to enter the code from the SMS that we sent to your email.</Text>
	          	<Flex justifyContent="flex-end" w="100%">
	          		<Divider opacity="1" mr="2px" borderColor='blue.200'  borderTopWidth="4px" borderBottomWidth="0"/>
	          		<Divider opacity="1" borderColor='blue.200'   borderTopWidth="4px" borderBottomWidth="0" />
	          	</Flex>
	          	<Text mt="30px" fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>
	          		Verify your code 
	          		{ isLoading && (
	          			<Spinner size="sm" color="blue.200" m="0 0 -2px 8px"/>
	          		)}
	          	</Text>
	          	<Text mt="5px" variant="text-md" mb="-7px">Enter the code sent to your number 6-digit confirmation code.</Text>
	          	<VerificationInput
	              length={6}
	              placeholder=""
	              validChars="0-9"
	              classNames={{
	                container: `code-container ${isValidInputCode ? 'input-valid-code' : 'input-invalid-code'}`,
	                character: `code-character ${isLoading ? 'input-freeze-code' : ''}`,
	                characterInactive: 'character--inactive',
	                characterSelected: 'character--selected',
	              }}
	              onChange={handleCode}
	            />
	            {!isLoading && (
	            	<>
  		            <Text mt="5px" variant="text-md" className={!timerActive ? 'hidden' : ''}>Resend via: <Text as="span" variant="text-md" color="blue.200">
                    {Math.floor(seconds / 60)}:{seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                    </Text>
                  </Text>
  		          	<Flex justify="space-between" mt="32px">
  			          	<Text variant="text-md" fontWeight="500" mt="12px">Step: 2/2</Text>
  			          	<Button variant="submit-md" maxW="140px" disabled={timerActive} onClick={handleChange}>
  				            Try again
    				        </Button>
    			        </Flex>
  			        </>
		          )}
	        </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default ChangeEmailModal;
