// Chakra imports
import { 
  Text
} from '@chakra-ui/react';


function Regions(props) {
  return (
    <Text variant="text">Not found</Text>
  );
}

export default Regions;