// Core
import { useState } from 'react';
import { Link, Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

// Chakra imports
import { 
  Box, 
  Divider,
  Image,
  Flex, 
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import danger from 'assets/svg/danger.svg';
import notifySuccess from 'assets/svg/notify-success.svg';
import notifyError from 'assets/svg/notify-error.svg';

// Components
import Card from 'components/Card/Card.js';
import Stream from './Stream';
import StreamEdit from './StreamEdit';

function Streams(props) {
  // Chakra color mode
  const { path } = useRouteMatch();
  
  return (
    <>
        <Box>
          <Switch>
            <Route path={`${path}/:id`} render={() => <StreamEdit />} />
            <Route path={`${path}`} render={() => <Stream />} />
            <Redirect from={`${path}*`} to={`${path}`} />
          </Switch>
        </Box>
    </>
  );
}

export default Streams;