// Core
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMinutes, setMinutes, setHours } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { DashboardActiveIcon } from 'components/Icons/Icons';

// Chakra
import { 
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text, 
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';

const TagsModal = ({ 
  newObject, newObjectErrors, setNewObject, 
  setNewObjectErrors, handleDeleteObject, handleCreateObject,
  isOpen, onClose
}) => {
  const history = useHistory();
  const isValidInputName = useMemo(() => !newObjectErrors.includes('name'), [newObjectErrors]);

  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'gray.800');

  const handleNewNameChange = useCallback((e) => {
    setData(1, e.target.value, 'name');
  }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/partners/tags');
  }, [history, setNewObject]);

  return (
  	<> 
  	<Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '562px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">
        { newObject?.id ? 'Edit Tag' : 'Add Tag' }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        	<Text variant="text-md" mt="16px" color={textColor}>
        		{ newObject?.id ?
        			'Modify the details of this tag. You can change the name or adjust the parent tag to refine its position in the hierarchy.'
        			:
        			'Create a new tag to organize items effectively. Name the tag, and if needed, assign it a parent tag for hierarchical structure.'
        		}
        	</Text>
            <Box mt="16px">
              <FormControl variant="floating" id="name">
                <Input value={newObject?.name} type='text' placeholder='' autoComplete="off" mb='24px' onChange={handleNewNameChange}  className={isValidInputName ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="name" className={isValidInputName ? 'input-valid' : 'input-invalid'}>Name</FormLabel>
                <FormHelperText className={isValidInputName ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>
            </Box>

	        <Flex gap="16px" style={{float: 'right'}} >
	          <Button variant="ghost-md" w="100px" onClick={() => onClose()}>Cancel</Button>
	          <Button variant="submit-md" w="125px" onClick={() => { handleCreateObject(); onClose();}}>{newObject?.id ? 'Confirm' : 'Create'}</Button>
	        </Flex>
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default TagsModal;
