// 1. Import the utilities
import { createBreakpoints } from '@chakra-ui/theme-tools';
// 2. Update the breakpoints as key-value pairs
export const breakpoints = createBreakpoints({
  sm: '220px',
  md: '768px',
  lg: '1350px',
  xl: '1351px',
  '2xl': '1800px',
});
