// Core
import { useState } from 'react';
import { Link, Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

// Chakra imports
import { 
  Box, 
  Image,
  Divider,
  Flex, 
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Components
import Banners from './components/banners/Banners';
import BannersEdit from './components/banners/BannersEdit';

function Banner(props) {
  // Chakra color mode
  const { path, url } = useRouteMatch();

  const location = useLocation();

  const isActive = (routeName) => {
    return location.pathname === routeName ? true : false;
  };

  const [notify, setNotify] = useState({
    error: false,
    success: false,
    msg: ''
  });

  const textColor = useColorModeValue('blue.800', 'white');

  return (
    <Box>
      <Switch>
        <Route path={`${path}/:id`} render={() => <BannersEdit setNotify={setNotify} />} />
        <Route path={`${path}`} render={() => <Banners setNotify={setNotify} />} />
      </Switch>
    </Box>
  );
}

export default Banner;