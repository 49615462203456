// Core
import { useState } from 'react';
import { Link, Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

// Chakra imports
import { 
  Box, 
  Image,
  Flex, 
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Components
import Card from 'components/Card/Card.js';
import Regions from './Regions.js';
import Currencies from './Currencies.js';
import Management from './management/Management.js';

function Resources(props) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  const textColor = useColorModeValue('blue.800', 'white');

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='12px'>Resource management</Text>
      <Box mt="0px" mb="50px" direction='column'>
        <Flex align='left' mb={{ sm: '10px', md: '0px' }}
          direction={{ sm: 'column', md: 'row' }}
          w={{ sm: '100%' }}
          textAlign={{ sm: 'center', md: 'start' }}
          borderBottom="1px"
          borderColor="rgba(255, 255, 255, 0.10)"
          className="settings-tabs">
          <Link to={`${url}/regions`} className={isActive(`${path}/regions`) ? 'settings-tab-active' : ''}>Regions</Link>
          <Link to={`${url}/currencies`} className={isActive(`${path}/currencies`) ? 'settings-tab-active' : ''}>Currencies</Link>
          <Link to={`${url}/public-pages-management`} className={isActive(`${path}/public-pages-management`) ? 'settings-tab-active' : ''}>Public page</Link>
        </Flex>
        
        <Box align='left' mt='32px'>
          <Switch>
            <Route path={`${path}/regions`} render={() => <Regions />} />
            <Route path={`${path}/currencies`} render={() => <Currencies />} />
            <Route path={`${path}/public-pages-management`} render={() => <Management />} />
            <Redirect from={`${path}*`} to={`${path}/regions`} />
          </Switch>
        </Box>
      </Box>
    </>
  );
}

export default Resources;