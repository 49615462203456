// Core
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMinutes, setMinutes, setHours } from 'date-fns';
import { useHistory } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Checkbox,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text, 
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';
import placeholder from 'assets/img/placeholder.png';

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import { MembershipActiveIcon } from 'components/Icons/Icons';

const MembershipEditContent = ({ 
  newObject, newObjectErrors, setSelectedFile, objects, setNewObject, setNewObjectErrors, handleDeleteObject, handleCreateObject
}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'gray.800');
  const paddingTd = '24px 24px 32px 24px';
  const borderColor = useColorModeValue('white', 'white');

  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/membership');
  }, [history, setNewObject]);

  const isValidInputName = useMemo(() => !newObjectErrors.includes('name'), [newObjectErrors]);
  const isValidInputDescription = useMemo(() => !newObjectErrors.includes('description'), [newObjectErrors]);
  const isValidInputType = useMemo(() => !newObjectErrors.includes('type'), [newObjectErrors]);
  const isValidInputPrice = useMemo(() => !newObjectErrors.includes('price'), [newObjectErrors]);
  const isValidInputPriceYearly = useMemo(() => !newObjectErrors.includes('priceYearly'), [newObjectErrors]);
  const isValidInputEntries = useMemo(() => !newObjectErrors.includes('entries'), [newObjectErrors]);
  const isValidInputMatesRates = useMemo(() => !newObjectErrors.includes('matesRates'), [newObjectErrors]);
  const isValidInputMerchDiscount = useMemo(() => !newObjectErrors.includes('merchDiscount'), [newObjectErrors]);
  
  const handleName = useCallback((e) => { setData(1, e.target.value, 'name'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleDescription = useCallback((e) => { setData(4, e.target.value, 'description'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handlePrice = useCallback((e) => { setData(1, e.target.value, 'price'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handlePriceYearly = useCallback((e) => { setData(0, e.target.value, 'priceYearly'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleEntries = useCallback((e) => { setData(0, e.target.value, 'entries'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleMatesRates = useCallback((e) => { setData(0, e.target.value, 'matesRates'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleMerchDiscount = useCallback((e) => { setData(0, e.target.value, 'merchDiscount'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleType = useCallback((e) => { setData(1, e.target.value, 'type'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleFreeHat = useCallback((e) => { setNewObject({...newObject, freeHat: !newObject.freeHat }); }, [newObject, setNewObject]);
  const handleVipInvites = useCallback((e) => { setNewObject({...newObject, vipInvites: !newObject.vipInvites }); }, [newObject, setNewObject]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleClickImage = () => {
    document.getElementById('fileInput').click(); 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, image: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box zIndex="9" position="relative" mt="133px">
      {
        newObject.id ? (
          <Breadcrumbs icon={MembershipActiveIcon} target='Membership' url='#/membership' current='Membership' />
        ) : (
          <Breadcrumbs icon={MembershipActiveIcon} target='Membership' url='#/membership' current='New Membership' />
        )
      }

      <Flex gap="16px" position="absolute" mt="-92px" right="0">
        {!newObject.id && <Button variant="ghost-md" maxW="120px" onClick={() => handleCancelClick()}>Cancel</Button>}
        {newObject.id && <Button variant="submit-md-delete" disabled w="120px" onClick={() => handleDeleteObject(newObject)}>Delete</Button>}
        <Button  variant="submit-md" w="120px" onClick={handleCreateObject}>{newObject.id ? 'Confirm' : 'Create'}</Button>
      </Flex>

      <Card padding="24px" mb="40px" maxW="839px">
        <Flex gap="24px">
          <Image 
            src={newObject.image ? newObject.image : placeholder} 
            borderRadius="10px"
            objectFit="cover"
            bg="#0D234D"
            p="12px"
            w="107px" 
            h="107px" />
          <Box>
            <Text 
              variant="title" 
              color="#099BFF" 
              cursor="pointer" 
              onClick={handleClickImage}>
              { newObject.image ? 'Change picture' : 'Add picture' }
            </Text>

            <Flex gap="3px" mt="8px">
              <Text variant="text-md">Maximum size:</Text>
              <Text variant="text-md" fontWeight="500">5 mb</Text>  
            </Flex>

            <Flex gap="3px">
              <Text variant="text-md">Supporting Image Type:</Text>
              <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
            </Flex>
            
          </Box>
        </Flex>

        <input
          id="fileInput"
          type="file"
          accept=".jpg,.jpeg,.png"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </Card>

      <Text variant="title-lg" mb="12px">Membership info</Text>
      <Card padding="0" maxW="839px" overflow="hidden">
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Name</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="name">
                  <Input value={newObject?.name} type='text' placeholder='' autoComplete="off" onChange={handleName}  className={isValidInputName ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="name" className={isValidInputName ? 'input-valid' : 'input-invalid'}>Name</FormLabel>
                  <FormHelperText className={isValidInputName ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Description</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="description">
                  <Textarea resize='none' height='132px' maxLength='100' value={newObject.description} type='text' placeholder='' autoComplete="off" onChange={handleDescription}  className={isValidInputDescription ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="description" className={isValidInputDescription ? 'input-valid' : 'input-invalid'}>Description</FormLabel>
                  <FormHelperText className={isValidInputDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Type</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="type">
                  <Select disabled placeholder="Type" value={newObject.type} onChange={handleType} className={isValidInputType ? 'input-valid' : 'input-invalid'}>
                    {objects && objects.map(option => (
                      <option key={option.type} value={option.type}>{option.type}</option>
                    ))}
                  </Select>
                  <FormLabel value={newObject.type} htmlFor="type" className={isValidInputType ? 'input-valid' : 'input-invalid'}>Type</FormLabel>
                  <FormHelperText className={isValidInputType ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Entries</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="entries">
                  <Input value={newObject?.entries} type='text' placeholder='' autoComplete="off" onChange={handleEntries}  className={isValidInputEntries ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="entries" className={isValidInputEntries ? 'input-valid' : 'input-invalid'}>Quantity</FormLabel>
                  <FormHelperText className={isValidInputEntries ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Mates rates</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="matesRates">
                  <Input value={newObject?.matesRates} type='text' placeholder='' autoComplete="off" onChange={handleMatesRates}  className={isValidInputMatesRates ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="matesRates" className={isValidInputMatesRates ? 'input-valid' : 'input-invalid'}>Quantity</FormLabel>
                  <FormHelperText className={isValidInputMatesRates ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Merch discount</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="merchDiscount">
                  <Input value={newObject?.merchDiscount} type='text' placeholder='' autoComplete="off" onChange={handleMerchDiscount}  className={isValidInputMerchDiscount ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="merchDiscount" className={isValidInputMerchDiscount ? 'input-valid' : 'input-invalid'}>Quantity</FormLabel>
                  <FormHelperText className={isValidInputMatesRates ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td><Text variant="title">Vip Invites</Text></Td>
              <Td><Switch isChecked={newObject?.vipInvites} onChange={handleVipInvites} /></Td>
            </Tr>

            <Tr>
              <Td><Text variant="title">Featured</Text></Td>
              <Td><Switch isChecked={newObject?.freeHat} onChange={handleFreeHat} /></Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>
    </Box>
  );
};

export default MembershipEditContent;