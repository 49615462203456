// Core
import { useState, useCallback } from 'react';
import { 
  Link, 
  Route, 
  Switch, 
  Redirect, 
  useRouteMatch, 
  useLocation 
} from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Chakra imports
import { 
  Box, 
  Button,
  Grid,
  Divider,
  Image,
  Flex, 
  FormControl,
  SimpleGrid,
  InputGroup,
  InputLeftElement,
  Text,
  Input,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { validateLoadMore, filterDate } from 'services/Utils';

// Components
import Card from 'components/Card/Card.js';
import { SearchIcon, DateTimeIcon } from 'components/Icons/Icons';
import GiveawaysRow from './components/GiveawaysRow';
import NotFound from 'views/Dashboard/404.js';

function Giveaways(props) {
  const { path, url } = useRouteMatch();

  const history = useHistory();

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  const textColor = useColorModeValue('blue.800', 'white');

  const [filterButtonLabel, setFilterButtonLabel] = useState('All Time');  
  const [currentTab, setCurrentTab] = useState('major');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState(); 
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState();
  const [dataMeta, setDataMeta] = useState(); 

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const onNewButtonClicked = useCallback(() => {
    history.push('/giveaways/new');
  });

  const { refetch, isLoading: isDataLoading } = useQuery({
    queryKey: ['data', offset, search, currentTab, startDate, endDate],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const data = {
        limit: 250,
        offset,
      };

      if (search) {
        data['search'] = search;
      }

      if(startDate){
        data['startDate'] = new Date(startDate).toISOString();
      }

      if(endDate){
        data['endDate'] = new Date(endDate).toISOString();
      }

      const response = await apiService.getGiveaways(data);
      if(offset === 0){
        setData(response.data);
      } else {
        setData([...data, ...response.data]);
      }
      
      setDataMeta(response.meta);
      return true;
    }
  });

  const handleEditOpen = useCallback((offer) => {
    history.push(`/giveaways/${offer.id}`);
  }, [history]);

  const handleDelete = useCallback(async (offer) => {
    //await apiService.deleteGiveaway(offer);
    refetch();
  });

  const handleDate = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const handleDateButton = (type) => {
    setFilterButtonLabel(type);
    const data = filterDate(type);
    handleDate(data);
  };

  const loadMore = async () => {
    setOffset(offset + 5);
  };

  return (
    <>
      <Flex position="absolute" right="0" top="80px" mr="31px" gap="12px">
        <FormControl variant="search" maxW="274px" className={search?.length > 0 ? 'search-active' : ''}>
          <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon fontSize="24px" ml="8px" />
          </InputLeftElement>
          <Input type='text' placeholder='Search' onChange={handleSearch} />
        </InputGroup>
        </FormControl>

        <Menu variant="filter">
          <MenuButton
            as={Button}
            leftIcon={<DateTimeIcon color="white" fontSize="24px" ml="0px" mr="0px" />}
            variant="secondary-md"
            color="white"
            mt="-2px"
            minW="140px"
            w="100%"
            px="24px !important"
          >{ filterButtonLabel }</MenuButton>
          <MenuList 
            className="context-menu filter-date">
            <Box>
              <DatePicker
                onChange={handleDate}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                locale="en"
                dateFormat="d/MM/yyyy"
              />
              {(!startDate && !endDate) && (
                <Text 
                  position="absolute" 
                  variant="text"
                  className="filter-date-placeholder"
                  pointerEvents="none"
                >
                  Enter a date range
                </Text>
              )}
              <DateTimeIcon 
                fontSize="24px"
                position="absolute"
                right="20px"
                top="17px"
                color="#9FABCB"
                pointerEvents="none"
              />
            </Box>
            <MenuItem onClick={() => handleDateButton('All Time')}>All Time</MenuItem>
            <MenuItem onClick={() => handleDateButton('This Month')}>This Month</MenuItem>
            <MenuItem onClick={() => handleDateButton('Last Month')}>Last Month</MenuItem>
            <MenuItem onClick={() => handleDateButton('Last 3 Month')}>Last 3 Month</MenuItem>
            <MenuItem onClick={() => handleDateButton('This Year')}>This Year</MenuItem>
          </MenuList>
        </Menu>

        <Button variant="submit-ms" minW="140px" w="100%" onClick={onNewButtonClicked}  leftIcon={<FaPlus />}>Create</Button>
      </Flex>

      { data?.length > 0 ? (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="8px">
          { data?.map((row) => (
            <GiveawaysRow 
              key={row.id}
              handleEditOpen={handleEditOpen} 
              handleDelete={handleDelete}
              object={row} />
          ))}
        </SimpleGrid>
        ) : (
          <NotFound text="Not Found" />
        )
      } 

      { validateLoadMore(data, dataMeta) && (
        <Button variant="ghost" onClick={loadMore} m="16px auto" display="block">
          Show More
        </Button>
      )}
    </>
  );
}

export default Giveaways;