// Core
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMinutes, setMinutes, setHours } from 'date-fns';
import { useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';

// Chakra
import { 
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Checkbox,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text, 
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { formatDate } from 'services/Utils';
import partnerLogo from 'assets/img/partner-logo.jpg';

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import { PartnerActiveIcon, DateTimeInputIcon } from 'components/Icons/Icons';
import PartnerPreviewContent from './PartnerPreviewContent';
import { ArrowIcon } from 'components/Icons/Icons';
import ButtonFilter from 'components/ButtonFilter';

const PartnerEditContent = ({ 
  newObject, newObjectErrors, states, tags, categories, setSelectedFile, setSelectedWideFile, objects, setNewObject, setNewObjectErrors, handleDeleteObject, handleCreateObject
}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'gray.800');
  const paddingTd = '16px 24px 24px 24px';
  const borderColor = useColorModeValue('white', 'white');
  const widthTitleTd = '25%';

  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/partners');
  }, [history, setNewObject]);

  const isValidInputName = useMemo(() => !newObjectErrors.includes('name'), [newObjectErrors]);
  const isValidInputDescription = useMemo(() => !newObjectErrors.includes('description'), [newObjectErrors]);
  const isValidInputDescDescription = useMemo(() => !newObjectErrors.includes('descDescription'), [newObjectErrors]);
  const isValidInputEmail = useMemo(() => !newObjectErrors.includes('email'), [newObjectErrors]);
  const isValidInputLocation = useMemo(() => !newObjectErrors.includes('location'), [newObjectErrors]);
  const isValidInputInstagram = useMemo(() => !newObjectErrors.includes('instagram'), [newObjectErrors]);
  const isValidInputFacebook = useMemo(() => !newObjectErrors.includes('facebook'), [newObjectErrors]);
  const isValidInputTiktok = useMemo(() => !newObjectErrors.includes('tiktok'), [newObjectErrors]);
  const isValidInputTwitter = useMemo(() => !newObjectErrors.includes('twitter'), [newObjectErrors]);
  const isValidInputWebsite = useMemo(() => !newObjectErrors.includes('website'), [newObjectErrors]);
  const isValidInputYoutube = useMemo(() => !newObjectErrors.includes('youtube'), [newObjectErrors]);
  const isValidInputFrom = useMemo(() => !newObjectErrors.includes('from'), [newObjectErrors]);
  const isValidInputTo = useMemo(() => !newObjectErrors.includes('to'), [newObjectErrors]);
  const isValidInputStatus = useMemo(() => !newObjectErrors.includes('status'), [newObjectErrors]);
  const isValidInputState = useMemo(() => !newObjectErrors.includes('state'), [newObjectErrors]);
  const isValidInputCountry = useMemo(() => !newObjectErrors.includes('country'), [newObjectErrors]);
  const isValidInputContact = useMemo(() => !newObjectErrors.includes('contact'), [newObjectErrors]);
  const isValidInputDiscountAmount = useMemo(() => !newObjectErrors.includes('discountInfo'), [newObjectErrors]);
  const isValidInputBindCategories = useMemo(() => !newObjectErrors.includes('bindCategories'), [newObjectErrors]);
  const isValidInputBindTags = useMemo(() => !newObjectErrors.includes('bindTags'), [newObjectErrors]);

  const handleNewNameChange = useCallback((e) => { setData(1, e.target.value, 'name'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDescriptionChange = useCallback((e) => { setData(10, e.target.value, 'description'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDescDescriptionChange = useCallback((e) => { setData(0, e.target.value, 'descDescription'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewEmailChange = useCallback((e) => { setData(0, e.target.value, 'email'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewLocationChange = useCallback((e) => { setData(0, e.target.value, 'location'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewInstagramChange = useCallback((e) => { setData(0, e.target.value, 'instagram'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewFacebookChange = useCallback((e) => { setData(0, e.target.value, 'facebook'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewTiktokChange = useCallback((e) => { setData(0, e.target.value, 'tiktok'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewTwitterChange = useCallback((e) => { setData(0, e.target.value, 'twitter'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewWebsiteChange = useCallback((e) => { setData(0, e.target.value, 'website'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewYoutubeChange = useCallback((e) => { setData(0, e.target.value, 'youtube'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleFeaturedChange = useCallback((e) => { setNewObject({...newObject, featured: !newObject.featured }); }, [newObject, setNewObject]);
  const handleShopChange = useCallback((e) => { setNewObject({...newObject, shop: !newObject.shop }); }, [newObject, setNewObject]);
  const handleExclusiveChange = useCallback((e) => { setNewObject({...newObject, exclusive: !newObject.exclusive }); }, [newObject, setNewObject]);
  const handleDeliveryChange = useCallback((e) => { setNewObject({...newObject, delivery: !newObject.delivery }); }, [newObject, setNewObject]);
  const handleAffiliateChange = useCallback((e) => { setNewObject({...newObject, affiliate: !newObject.affiliate }); }, [newObject, setNewObject]);
  const handleNewFromChange = useCallback((e) => { setData(0, e.target.value, 'from'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewToChange = useCallback((e) => { setData(0, e.target.value, 'to'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewStatusChange = useCallback((e) => { setData(0, e.target.value, 'status'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewStateChange = useCallback((e) => { setData(0, e.target.value, 'state'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewCountryChange = useCallback((e) => { setData(0, e.target.value, 'country'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewContactChange = useCallback((e) => { setData(0, e.target.value, 'contact'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDiscountAmount = useCallback((e, planKey, field) => {
    const { value } = e.target;
    const parsedValue = value === '' ? '' : isNaN(value) ? value : Number(value);

    setNewObject((prev) => {
      if (planKey === 'title' || planKey === 'discount') {
        return {
          ...prev,
          discountInfo: {
            ...prev.discountInfo,
            [field]: parsedValue
          }
        };
      } else {
        return {
          ...prev,
          discountInfo: {
            ...prev.discountInfo,
            plans: {
              ...prev.discountInfo.plans,
              [planKey]: {
                ...prev.discountInfo.plans[planKey],
                [field]: field === 'isCurrency' ? (value === 'true') : parsedValue
              }
            }
          }
        };
      }
    });

    if (parsedValue === 0) {
      setNewObjectErrors((prevErrors) => [...prevErrors, field]);
    } else {
      setNewObjectErrors((prevErrors) => prevErrors.filter((error) => error !== field));
    }
  }, [setNewObject, setNewObjectErrors]);
  
  const handleNewCategoriesChange = useCallback((object) => {
    setNewObject((prev) => {
      const bindCategories = Array.isArray(prev.bindCategories) ? prev.bindCategories : [];
      const isAlreadySelected = bindCategories.some((category) => category.id === object.id);
      const updatedBindCategories = isAlreadySelected
        ? bindCategories.filter((category) => category.id !== object.id)
        : [...bindCategories, object]; 

      return {
        ...prev,
        bindCategories: updatedBindCategories,
      };
    });
  }, [setNewObject]);

  const handleNewTagsAmount = useCallback((object) => {
    setNewObject((prev) => {
      const bindTags = Array.isArray(prev.bindTags) ? prev.bindTags : [];
      const isAlreadySelected = bindTags.some((tag) => tag.id === object.id);
      const updatedBindTags = isAlreadySelected
        ? bindTags.filter((tag) => tag.id !== object.id)
        : [...bindTags, object]; 

      return {
        ...prev,
        bindTags: updatedBindTags,
      };
    });
  }, [setNewObject]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleClickImage = () => {
    document.getElementById('fileInput').click(); 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, image: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  const handleClickImageWide = () => {
    document.getElementById('fileInputWide').click(); 
  };

  const handleFileWideChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 2048 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedWideFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, imageWide: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  const [collapsedRows, setCollapsedRows] = useState({}); 
  const toggleRow = (rowId) => {
    setCollapsedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const { data: memberships, isLoading: isLoadingMemberships } = useQuery({
    queryKey: ['memberships'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getMemberships();
      return response.data;
    }
  });

  const getImageByType = (type) => {
    const item = memberships.find((item) => item.type === type);
    return item ? item.image : null;
  };

  /*const [selectedPromocodes, setSelectedPromocodes] = useState({});
  const togglePromocode = (key, value) => {
    setSelectedPromocodes((prev) => ({
      ...prev,
      [key]: value,
    }));

    if (value === 'off') {
      handleNewDiscountAmount({ target: { value: '' } }, key, 'code');
    }
  };

  <ButtonFilter
    key={key} 
    buttons={[
      { key: 'on', name: 'On' },
      { key: 'off', name: 'Off' },
    ]}
    selected={
      selectedPromocodes[key] === 'off' 
      ? 'off' 
      : 'on'
    }
    onSelect={(value) => togglePromocode(key, value)}
    w="100%"
  />*/

  return (
    <Box zIndex="9" position="relative">
      {
        newObject.id ? (
          <Breadcrumbs icon={PartnerActiveIcon} target='Partners' url='#/partners' current='Partner' />
        ) : (
          <Breadcrumbs icon={PartnerActiveIcon} target='Partners' url='#/partners' current='New Partner' />
        )
      }

      <Flex gap="16px" position="absolute" mt="-92px" right="0">
        {!newObject.id && <Button variant="ghost-md" maxW="120px" onClick={() => handleCancelClick()}>Cancel</Button>}
        {newObject.id && <Button variant="submit-md-delete" disabled w="120px" onClick={() => handleDeleteObject(newObject)}>Delete</Button>}
        <Button  variant="submit-md" w="120px" onClick={handleCreateObject}>{newObject.id ? 'Confirm' : 'Create'}</Button>
      </Flex>

      <Grid templateColumns={{ sm: '1fr', lg: '3fr 1.1fr' }} templateRows='1fr' gap="24px">
        <Box>
          <Card padding="0" mb="40px">
            <Flex gap="24px" p="24px 24px 32px 24px">
              <Image 
                src={newObject.image ? newObject.image : partnerLogo} 
                borderRadius="10px"
                objectFit="cover"
                w="160px" 
                h="107px" />
              <Box>
                <Text 
                  variant="title" 
                  color="#099BFF" 
                  cursor="pointer" 
                  onClick={handleClickImage}>
                  { newObject.image ? 'Change picture' : 'Add picture' }
                </Text>

                <Flex gap="3px">
                  <Text variant="text-md">Name:</Text>
                  <Text variant="text-md" fontWeight="500">Square</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Format:</Text>
                  <Text variant="text-md" fontWeight="500">3:2</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Maximum size:</Text>
                  <Text variant="text-md" fontWeight="500">5 mb</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Supporting Image Type:</Text>
                  <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
                </Flex>
                
              </Box>
            </Flex>

            <Divider opacity="1" borderColor='rgba(255, 255, 255, 0.10)' borderTopWidth="1px" borderBottomWidth="0" />

            <Flex gap="24px" p="24px 24px 32px 24px">
              <Image 
                src={newObject.imageWide ? newObject.imageWide : partnerLogo} 
                borderRadius="10px"
                objectFit="cover"
                w="160px" 
                h="107px" />
              <Box>
                <Text 
                  variant="title" 
                  color="#099BFF" 
                  cursor="pointer" 
                  onClick={handleClickImageWide}>
                  { newObject.imageWide ? 'Change picture' : 'Add picture' }
                </Text>

                <Flex gap="3px">
                  <Text variant="text-md">Name:</Text>
                  <Text variant="text-md" fontWeight="500">Wide</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Format:</Text>
                  <Text variant="text-md" fontWeight="500">16:9</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Maximum size:</Text>
                  <Text variant="text-md" fontWeight="500">5 mb</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Supporting Image Type:</Text>
                  <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
                </Flex>
                
              </Box>
            </Flex>

            <Divider opacity="1" borderColor='rgba(255, 255, 255, 0.10)' borderTopWidth="1px" borderBottomWidth="0" />

            <Flex gap="24px" p="24px 24px 32px 24px">
              <Image 
                src={ newObject?.banners?.length > 0 ? newObject?.banners?.[0].image : partnerLogo }
                borderRadius="10px"
                objectFit="cover"
                w="160px" 
                h="107px" />
              <Box>
                <Link to={ newObject?.banners?.length > 0 ? '/banners/'+ newObject?.banners?.[0].id : '/banners/new' }>
                  <Text 
                    variant="title" 
                    color="#099BFF" 
                    cursor="pointer">
                    { newObject?.banners?.length > 0 ? 'Change banner' : 'Add banner' }
                  </Text>
                </Link>

                <Flex gap="3px">
                  <Text variant="text-md">Name:</Text>
                  <Text variant="text-md" fontWeight="500">Slider Banner</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Format:</Text>
                  <Text variant="text-md" fontWeight="500">21:9</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Maximum size:</Text>
                  <Text variant="text-md" fontWeight="500">5 mb</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Supporting Image Type:</Text>
                  <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
                </Flex>
                
              </Box>
            </Flex>

            <input
              id="fileInput"
              type="file"
              accept=".jpg,.jpeg,.png"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />

            <input
              id="fileInputWide"
              type="file"
              accept=".jpg,.jpeg,.png"
              style={{ display: 'none' }}
              onChange={handleFileWideChange}
            />
          </Card>

          <Text variant="title-lg" mb="12px">Partner info</Text>
          <Card padding="24px" mb="8px">
              <FormControl variant="floating" id="name">
                <Input value={newObject.name} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewNameChange}  className={isValidInputName ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="name" className={isValidInputName ? 'input-valid' : 'input-invalid'}>Title</FormLabel>
                <FormHelperText className={isValidInputName ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating" id="description">
                <Textarea
                    h={{ base: '150px !important' }}
                    resize="none" value={newObject.description} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewDescriptionChange}  className={isValidInputDescription ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="description" className={isValidInputDescription ? 'input-valid' : 'input-invalid'}>Description</FormLabel>
                <FormHelperText className={isValidInputDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating" id="descDescription">
                <Textarea
                    h={{ base: '150px !important' }}
                    resize="none" value={newObject.descDescription} type='text' placeholder='' autoComplete="off" onChange={handleNewDescDescriptionChange}  className={isValidInputDescDescription ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="descDescription" className={isValidInputDescDescription ? 'input-valid' : 'input-invalid'}>Long Description</FormLabel>
                <FormHelperText className={isValidInputDescDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>
          </Card>

          <Text variant="title-lg" mt="40px" mb="12px">Options</Text>
          <Card padding='8px 0' my={{ sm: '22px', xl: '0px' }}>
            <Table>
              <Tbody>
                <Tr>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                    <Text variant="title">Options</Text>
                  </Td>
                  <Td verticalAlign="top" p={paddingTd}>
                    <Grid templateColumns={{ sm: '1fr', lg: '1fr 1fr' }} templateRows='1fr' gap="94px">
                      <Box>
                        <Flex w="100%" justifyContent="space-between">
                          <Text variant="title">Featured</Text>
                          <Switch float='right' isChecked={newObject?.featured} onChange={handleFeaturedChange} />
                        </Flex>
                        <Flex w="100%" justifyContent="space-between" mt="16px">
                          <Text variant="title">Shop</Text>
                          <Switch float='right' isChecked={newObject?.shop} onChange={handleShopChange} />
                        </Flex>
                        <Flex w="100%" justifyContent="space-between" mt="16px">
                          <Text variant="title">Exclusive</Text>
                          <Switch float='right' isChecked={newObject?.exclusive} onChange={handleExclusiveChange} />
                        </Flex>
                      </Box>

                      <Box>
                        <Flex w="100%" justifyContent="space-between">
                          <Text variant="title">Delivery</Text>
                          <Switch float='right' isChecked={newObject?.delivery} onChange={handleDeliveryChange} />
                        </Flex>
                        <Flex w="100%" justifyContent="space-between" mt="16px">
                          <Text variant="title">Affiliate</Text>
                          <Switch float='right' isChecked={newObject?.affiliate} onChange={handleAffiliateChange} />
                        </Flex>
                      </Box>
                    </Grid>
                  </Td>
                </Tr>

                <Tr>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                    <Text variant="title">Categories</Text>
                  </Td>
                  <Td verticalAlign="top" p={paddingTd} >
                    
                      {categories?.map((row) => (
                        <Flex key={row.id} w="100%" justifyContent="space-between">
                          <Box w="100%">
                            <Checkbox
                              pb="16px"
                              w="100%"
                              isChecked={
                                newObject?.bindCategories?.some(category => category.id === row.id)
                              }
                              onChange={() => handleNewCategoriesChange(row)}
                              w="25%"
                            >
                              <Text variant="title" w="100%" whiteSpace="nowrap">{row.name}</Text>
                            </Checkbox>

                            {row.subCategories && (
                              <Box
                                ml="32px"
                                w="100%"
                                display={collapsedRows[row.id] ? 'block' : 'none'}
                              >
                                {row.subCategories.map((subRow) => (
                                  <Checkbox
                                    w="100%"
                                    display="flex"
                                    key={subRow?.id}
                                    pb="16px"
                                    isChecked={
                                      newObject?.bindCategories?.some(category => category.id === subRow.id)
                                    }
                                    onChange={() => handleNewCategoriesChange(subRow)}
                                    w="25%"
                                  >
                                    <Text variant="text-md">{subRow?.name}</Text>
                                  </Checkbox>
                                ))}
                              </Box>
                            )}
                          </Box>

                          {row?.subCategories.length > 0 && (
                            <Box>
                              <ArrowIcon
                                transform={collapsedRows[row.id] ? 'rotate(0deg)' : 'rotate(180deg)'} 
                                fontSize="22px"
                                color="white"
                                cursor="pointer"
                                onClick={() => toggleRow(row.id)} 
                              />
                            </Box>
                          )}
                        </Flex>
                      ))}
                  </Td>
                </Tr>

                <Tr>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                    <Text variant="title">Tags</Text>
                  </Td>
                  <Td verticalAlign="top" p={paddingTd}>
                    <Text variant="title" color="#099BFF">Add tags</Text>
                    <Text variant="text-md" mt="6px" color="#9FABCB">
                      Choose tags to categorize your content and make it easier to find. You can select multiple tags to refine your search criteria.
                    </Text>

                    <Text variant="text-md" mt="6px" fontWeight="500" color="#9FABCB" mt="18px" pb="12px">
                      Total selected: {newObject?.bindTags?.length}
                    </Text>

                    <Flex wrap="wrap">
                      {tags?.map((option) => (
                        <Checkbox
                          pb="16px"
                          key={option.id}
                          isChecked={ 
                            newObject?.bindTags?.some(tag => tag.id === option.id) 
                          }
                          onChange={() => handleNewTagsAmount(option)}
                          w="33%"
                        >
                          {option.name}
                        </Checkbox>
                      ))}
                    </Flex>
                  </Td>
                </Tr>

                <Tr>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}} border="unset">
                    <Text variant="title">Status</Text>
                  </Td>
                  <Td verticalAlign="top" p={paddingTd} border="unset">
                    <FormControl variant="floating" mt="8px" mb='28px' >
                        <Select placeholder="Status" value={newObject?.status} onChange={handleNewStatusChange} className={isValidInputStatus ? 'input-valid' : 'input-invalid'}>
                            <option value="pending">Pending</option>
                            <option value="published">Published</option>
                            <option value="draft">Draft</option>
                            <option value="trash">Trash</option>
                        </Select>
                        <FormHelperText className={isValidInputStatus ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                    </FormControl>

                    <FormControl variant="floating" mb='28px' position="relative">
                      <Input value={
                        newObject?.from ? formatDate(newObject?.from) : null
                      } type='datetime-local' onChange={handleNewFromChange} className={isValidInputFrom ? 'input-valid' : 'input-invalid'}/>
                      <FormLabel className={isValidInputFrom ? 'input-valid' : 'input-invalid'}>From</FormLabel>
                      <FormHelperText className={isValidInputFrom ? 'hidden' : 'input-error' }>From</FormHelperText>
                      <DateTimeInputIcon 
                        fontSize="24px"
                        position="absolute"
                        right="15px"
                        top="15px"
                        color="#9FABCB"
                        pointerEvents="none"
                        zIndex="1"
                      />
                    </FormControl>

                    <FormControl variant="floating" >
                      <Input value={
                        newObject?.to ? formatDate(newObject?.to) : null
                      } type='datetime-local' onChange={handleNewToChange} className={isValidInputTo ? 'input-valid' : 'input-invalid'}/>
                      <FormLabel className={isValidInputTo ? 'input-valid' : 'input-invalid'}>To</FormLabel>
                      <FormHelperText className={isValidInputTo ? 'hidden' : 'input-error' }>To</FormHelperText>
                      <DateTimeInputIcon 
                        fontSize="24px"
                        position="absolute"
                        right="15px"
                        top="15px"
                        color="#9FABCB"
                        pointerEvents="none"
                        zIndex="1"
                      />
                    </FormControl>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Card>

          <Text variant="title-lg" mt="40px" mb="12px">Discount</Text>
          <Card padding='8px 0 0px 0' mb="8px" overflow="hidden">
            <Table mb="-1px">
              <Tbody>
                <Tr>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                    <Text variant="title">General Info</Text>
                  </Td>
                  <Td verticalAlign="top" p={paddingTd}>
                    <FormControl variant="floating">
                      <Input 
                        type="text" 
                        value={newObject?.discountInfo?.title} 
                        onChange={(e) => handleNewDiscountAmount(e, 'title', 'title')}
                        name="title" />
                      <FormLabel>General Title</FormLabel>
                    </FormControl>

                    <FormControl variant="floating" mt="32px">
                      <Input 
                        type="text"
                        value={newObject?.discountInfo?.discount} 
                        onChange={(e) => handleNewDiscountAmount(e, 'discount', 'discount')}
                        name="generalDiscount" />
                      <FormLabel>Discount</FormLabel>
                    </FormControl>
                  </Td>
                </Tr>

                {newObject?.discountInfo?.plans && Object.entries(newObject.discountInfo.plans).map(([key, item], index) => (
                  <Tr key={key}>
                    <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                      <Text variant="title" textTransform="capitalize">{key} plan</Text>
                      { !isLoadingMemberships && memberships && (
                        <Box mt="10px" w="50px" h="50px" bg="#0D234D" borderRadius="12px" p="7px">
                          <Image src={getImageByType(key)} alt="" />
                        </Box>
                      )}
                    </Td>
                    <Td verticalAlign="top" p={paddingTd}>

                      <FormControl mt="8px" variant="floating" id={`${key}_description`}>
                        <Textarea
                            h={{ base: '150px !important' }}
                            resize="none" 
                            value={item.description} 
                            type='text' 
                            placeholder='' 
                            autoComplete="off" 
                            onChange={(e) => handleNewDiscountAmount(e, key, 'description')}
                            className={isValidInputDescDescription ? 'input-valid' : 'input-invalid'}  />
                        <FormLabel>Description</FormLabel>
                      </FormControl>

                      <Grid 
                        gap="26px"
                        mt="24px"
                        templateColumns="1fr 1fr">

                        <Grid 
                          gap="10px"
                          templateColumns="1fr"
                        >
                          <FormControl variant="floating" id={`${key}_code`}>
                            <Input
                              value={item.code} 
                              type='text' 
                              name="code"
                              onChange={(e) => handleNewDiscountAmount(e, key, 'code')}
                            />
                            <FormLabel>Promocode</FormLabel>
                          </FormControl>
                        </Grid>

                        <Grid 
                          gap="10px"
                          templateColumns="1fr 0.6fr">
                          <FormControl variant="floating" id={`${key}_discount`}>
                            <Input 
                              type='number' 
                              value={item.discount} 
                              name="discount"
                              onChange={(e) => handleNewDiscountAmount(e, key, 'discount')}
                            />
                            <FormLabel>Discount</FormLabel>
                          </FormControl>

                          <ButtonFilter
                            key={key}
                            buttons={[
                              { key: 'false', name: '%' },
                              { key: 'true', name: '$' },
                            ]}
                            selected={item.isCurrency ? 'true' : 'false'}
                            onSelect={(value) => handleNewDiscountAmount({ target: { value } }, key, 'isCurrency')}
                            w="100%"
                          />
                        </Grid>

                      </Grid>
                    </Td>
                  </Tr>

                ))}
                
              </Tbody>
            </Table>
          </Card>

          <Text variant="title-lg" mt="40px" mb="12px">Social</Text>
          <Card padding='8px 0 0px 0' mb="8px" overflow="hidden">
            <Table mb="-1px">
              <Tbody>
                <Tr>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                    <Text variant="title">Social media link</Text>
                  </Td>

                  <Td verticalAlign="top" p={paddingTd}>
                      <FormControl variant="floating" id="instagram">
                        <Input value={newObject.instagram} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewInstagramChange}  className={isValidInputInstagram ? 'input-valid' : 'input-invalid'}  />
                        <FormLabel htmlFor="instagram" className={isValidInputInstagram ? 'input-valid' : 'input-invalid'}>Instagram</FormLabel>
                        <FormHelperText className={isValidInputInstagram ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                      </FormControl>

                      <FormControl variant="floating" id="facebook">
                        <Input value={newObject.facebook} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewFacebookChange}  className={isValidInputFacebook ? 'input-valid' : 'input-invalid'}  />
                        <FormLabel htmlFor="facebook" className={isValidInputFacebook ? 'input-valid' : 'input-invalid'}>Facebook</FormLabel>
                        <FormHelperText className={isValidInputFacebook ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                      </FormControl>

                      <FormControl variant="floating" id="youtube">
                        <Input value={newObject.youtube} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewYoutubeChange}  className={isValidInputYoutube ? 'input-valid' : 'input-invalid'}  />
                        <FormLabel htmlFor="youtube" className={isValidInputYoutube ? 'input-valid' : 'input-invalid'}>Youtube</FormLabel>
                        <FormHelperText className={isValidInputYoutube ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                      </FormControl>

                      <FormControl variant="floating" id="tiktok">
                        <Input value={newObject.tiktok} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewTiktokChange}  className={isValidInputTiktok ? 'input-valid' : 'input-invalid'}  />
                        <FormLabel htmlFor="tiktok" className={isValidInputTiktok ? 'input-valid' : 'input-invalid'}>Tiktok</FormLabel>
                        <FormHelperText className={isValidInputTiktok ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                      </FormControl>

                      <FormControl variant="floating" id="twitter">
                        <Input value={newObject.twitter} type='text' placeholder='' autoComplete="off" onChange={handleNewTwitterChange}  className={isValidInputTwitter ? 'input-valid' : 'input-invalid'}  />
                        <FormLabel htmlFor="twitter" className={isValidInputTwitter ? 'input-valid' : 'input-invalid'}>Twitter</FormLabel>
                        <FormHelperText className={isValidInputTwitter ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                      </FormControl>
                  </Td>
                </Tr>                
              </Tbody>
            </Table>
          </Card>

          <Text variant="title-lg" mt="40px" mb="12px">Contact Information</Text>
          <Card padding='8px 0 0px 0' mb="8px" overflow="hidden">
            <Table mb="-1px">
              <Tbody>
                <Tr>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                    <Text variant="title">Address</Text>
                  </Td>

                  <Td verticalAlign="top" p={paddingTd}>
                    <FormControl variant="floating" id="location" mb='28px' >
                      <Input value={newObject.location} type='text' placeholder='' autoComplete="off" onChange={handleNewLocationChange}  className={isValidInputLocation ? 'input-valid' : 'input-invalid'}  />
                      <FormLabel htmlFor="location" className={isValidInputLocation ? 'input-valid' : 'input-invalid'}>Location (Address)</FormLabel>
                      <FormHelperText className={isValidInputLocation ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                    </FormControl>

                    <FormControl variant="floating" id="country" mb='28px' >
                      <Input value="Australia" disabled type='text' placeholder='' autoComplete="off" onChange={handleNewCountryChange}  className={isValidInputCountry ? 'input-valid' : 'input-invalid'}  />
                      <FormLabel htmlFor="country" className={isValidInputCountry ? 'input-valid' : 'input-invalid'}>Country</FormLabel>
                      <FormHelperText className={isValidInputCountry ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                    </FormControl>

                    <FormControl variant="floating" >
                        <Select placeholder="State" value={newObject.state} onChange={handleNewStateChange} className={isValidInputState ? 'input-valid' : 'input-invalid'}>
                            { states && states.map((option, index) => (
                              <option key={index} value={option}>{option}</option>
                            ))}
                        </Select>
                        <FormHelperText className={isValidInputState ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                    </FormControl>
                  </Td>
                </Tr> 

                <Tr>
                  <Td verticalAlign="top" p={paddingTd} w={{sm: widthTitleTd}}>
                    <Text variant="title">Contacts</Text>
                  </Td>

                  <Td verticalAlign="top" p={paddingTd}>
                    <FormControl variant="floating" id="website" mb='28px' mt='6px' >
                      <Input value={newObject.website} type='text' placeholder='' autoComplete="off" onChange={handleNewWebsiteChange}  className={isValidInputWebsite ? 'input-valid' : 'input-invalid'}  />
                      <FormLabel htmlFor="website" className={isValidInputWebsite ? 'input-valid' : 'input-invalid'}>Website</FormLabel>
                      <FormHelperText className={isValidInputWebsite ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                    </FormControl>
                  
                    <FormControl variant="floating" id="email" mb='28px' >
                      <Input value={newObject.email} type='text' placeholder='' autoComplete="off" onChange={handleNewEmailChange}  className={isValidInputEmail ? 'input-valid' : 'input-invalid'}  />
                      <FormLabel htmlFor="email" className={isValidInputEmail ? 'input-valid' : 'input-invalid'}>Email</FormLabel>
                      <FormHelperText className={isValidInputEmail ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                    </FormControl>

                    <FormControl variant="floating" id="contact" mb='28px' >
                      <Input value={newObject.contact} type='text' placeholder='' autoComplete="off" onChange={handleNewContactChange}  className={isValidInputContact ? 'input-valid' : 'input-invalid'}  />
                      <FormLabel htmlFor="contact" className={isValidInputContact ? 'input-valid' : 'input-invalid'}>Phone</FormLabel>
                      <FormHelperText className={isValidInputContact ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                    </FormControl>

                  </Td>
                </Tr>                
              </Tbody>
            </Table>
          </Card>

        </Box>

        <PartnerPreviewContent newObject={newObject} />
      </Grid>
    </Box>
  );
};

export default PartnerEditContent;