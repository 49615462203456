// Core
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMinutes, setMinutes, setHours } from 'date-fns';
import { useHistory } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text, 
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';
import uploadImage from 'assets/img/upload.png';

// Components
import { DashboardActiveIcon } from 'components/Icons/Icons';

const CategoryModal = ({ 
  newObject, newObjectErrors, setNewObject, objects, 
  setNewObjectErrors, handleDeleteObject, handleCreateObject,
  isOpen, onClose,
  setSelectedFile
}) => {
  const history = useHistory();
  const isValidInputName = useMemo(() => !newObjectErrors.includes('name'), [newObjectErrors]);
  const isValidInputParentId = useMemo(() => !newObjectErrors.includes('parentId'), [newObjectErrors]);

  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'gray.800');

  const handleNewNameChange = useCallback((e) => {
    setData(1, e.target.value, 'name');
  }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);

  const handleNewParentIdChange = useCallback((e) => {
    setData(0, e.target.value, 'parentId');
  }, [newObject, newObjectErrors, setNewObject, setNewObjectErrors]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/partners/categories');
  }, [history, setNewObject]);

  const handleClickImage = () => {
    document.getElementById('fileInput').click(); 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, image: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  return (
  	<> 
  	<Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '562px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">
        { newObject?.id ? 'Edit Category' : 'Add Category' }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        	<Text variant="text-md" mt="16px" color={textColor}>
        		{ newObject?.id ?
        			'Modify the details of this category. You can change the name or adjust the parent category to refine its position in the hierarchy.'
        			:
        			'Create a new category to organize items effectively. Name the category, and if needed, assign it a parent category for hierarchical structure.'
        		}
        	</Text>
            <Box>
              <Flex gap="24px" p="24px 0">
                <Image 
                  src={newObject.image ? newObject.image : uploadImage} 
                  borderRadius="10px"
                  objectFit="cover"
                  h="107px" />
                <Box>
                  <Text 
                    variant="title" 
                    color="#099BFF" 
                    cursor="pointer" 
                    onClick={handleClickImage}>
                    { newObject.image ? 'Change picture' : 'Add picture' }
                  </Text>

                  <Flex gap="3px">
                    <Text variant="text-md">Maximum size:</Text>
                    <Text variant="text-md" fontWeight="500">5 mb</Text>  
                  </Flex>

                  <Flex gap="3px">
                    <Text variant="text-md">Supporting Image Type:</Text>
                    <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
                  </Flex>

                  <input
                    id="fileInput"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </Box>
              </Flex>
              <FormControl variant="floating" id="name">
                <Input value={newObject?.name} type='text' placeholder='' autoComplete="off" mb='24px' onChange={handleNewNameChange}  className={isValidInputName ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="name" className={isValidInputName ? 'input-valid' : 'input-invalid'}>Name</FormLabel>
                <FormHelperText className={isValidInputName ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating">
                  <Select placeholder="Parent category" value={newObject?.parentId} onChange={handleNewParentIdChange} className={isValidInputParentId ? 'input-valid' : 'input-invalid'}>
                      { objects && objects.map((option, index) => (
                        <option key={index} value={option.id} disabled={newObject?.id === option.id ? true : false}>{option.name}</option>
                      ))}
                  </Select>
                  <FormHelperText className={isValidInputParentId ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

            </Box>

	        <Flex gap="16px" mt="28px" style={{float: 'right'}} >
	          <Button variant="ghost-md" w="100px" onClick={() => onClose()}>Cancel</Button>
	          <Button variant="submit-md" w="125px" onClick={() => { handleCreateObject(); onClose();}}>{newObject?.id ? 'Confirm' : 'Create'}</Button>
	        </Flex>
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default CategoryModal;
