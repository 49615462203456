// Core
import { HiDotsVertical } from 'react-icons/hi';
import {useEffect, useState, useCallback} from 'react';
import { IoTicketSharp } from 'react-icons/io5';
import { NavLink, Link } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { parseDateTime } from 'services/Utils';
import { ReactComponent as InfoControls } from 'assets/svg/controls.svg';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { TicketIcon, EditIcon, DeleteIcon } from 'components/Icons/Icons';
import Attention from 'components/Modals/Attention.js';

const GiveawaysRow = ({ object, handleEditOpen, handleDelete }) => {
  const [switched, setSwitched] = useState(object.hidden);

  const borderColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const attentionModal = async () => {
    onOpen();
  };

  return (
    <Card key={object.id} padding="24px">
      <CardBody>
        <Box w="100%">
          <Flex justifyContent="space-between" w="100%" mb="10px">
          	<Flex gap="4px">
            	<Text variant="text-md">Created:</Text>
            	<Text variant="text-md" fontWeight="500" color="white">{ parseDateTime(object.createdAt) }</Text>
            </Flex>

            <Text 
              variant="text-md" 
              mt="-3px"
              minW="110px"
              className={
                object.status === 'active' ? 'tag-green' : object.status === 'inactive' ? 'tag-orange' : 'tag-red'
              }
            >
            {object.status}
            </Text>

          </Flex>

          <Text variant="title" transition="0.2s all" lineHeight="34px" fontWeight="700" fontSize="28px"
            whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {object.title}
          </Text>
          <Text variant="text" mt="2px" mb="-4px">{object.description.length > 60 ? `${object.description.slice(0, 60)}...` : object.description}</Text>

          <Divider opacity="1" my="16px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0"/>

          <Flex gap="4px" mb="16px">
            <Text variant="text-md">End date:</Text>
            <Text variant="text-md" fontWeight="500" color="white">{ parseDateTime(object.endDate) }</Text>
          </Flex>

          <Flex justifyContent="space-between" w="100%" gap="8px" mt="14px">
            <Button
              variant="secondary-md" 
              onClick={attentionModal} 
              leftIcon={<DeleteIcon color="white" fontSize="22px" pl="4px" mr="-4px" />} />

            <Button 
              variant="submit-md" 
              onClick={() => handleEditOpen(object)} 
              leftIcon={<EditIcon color="white" fontSize="24px" />}>
              Change
            </Button>
          </Flex>

          <Attention
            variant="remove"
            handle={() => handleEditOpen(object)}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
          />
        </Box>
      </CardBody>
    </Card>
  );
};

export default GiveawaysRow;