// Core
import { useState, useEffect } from 'react';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  Checkbox,
  Textarea,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const EditGeneralInfoModal = ({user, isOpen, onOpen, onClose}) => {

  const [isValidInput, setIsValidInput] = useState(true);  
  const [text, setText] = useState(); 
  const [isLoading, setIsLoading] = useState(false);

  const handleText = (event) => {
    setText(event.target.value);
    setIsValidInput(true);
  };
  const handleSend = async () => {
    let error = false;
    setIsLoading(true);

    try {
      //const response = await apiService.updateUsers(dataUser);
      //if(response.statusCode === 200){
        onClose();
      //} else {
      //  error = true;
      //}
    } catch (e) { error = true; }

    setIsLoading(false);

    if(error){
      setIsValidInput(false);
    }
  };

  return (
  	<> 
  	<Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">Contact form</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text variant="text-md" mb="24px" pt="16px" maxW={{sm: '90%'}}>Please enter and send a message to the user by specifying the subject and text below.</Text>

            <FormControl variant="floating">
                <Textarea
                  minH={{ base: '200px !important' }} type='text' placeholder='' autoComplete="off" mb='32px' onChange={handleText}  className={isValidInput ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="description" className={isValidInput ? 'input-valid' : 'input-invalid'}>Message</FormLabel>
                <FormHelperText className={isValidInput ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <Flex justifyContent="flex-end">
              <Button variant="submit-md" maxW="140px" isLoading={isLoading} onClick={handleSend}>
                Send
              </Button>
            </Flex>
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default EditGeneralInfoModal;
