// Core
import { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Select,
  Checkbox, 
  CheckboxGroup, 
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const ContactFormModal = ({user, setUser, isOpen, onOpen, onClose}) => {

  const [isValidInputEmail, setIsValidInputEmail] = useState(true); 
  const [isValidInputPhone, setIsValidInputPhone] = useState(true); 
  const [confirm, setConfirm] = useState(false); 
  const [dataUser, setDataUser] = useState(user);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = (event) => setConfirm(!confirm);
  const handleEmail = (event) => {
    setDataUser({...dataUser, email: event.target.value});
    setIsValidInputEmail(true);
  };
  const handlePhone = value => {
    setDataUser({...dataUser, phone: value});
    setIsValidInputPhone(true);
  };
  const handleChange = async () => {
    let error = false;

    setIsLoading(true);

    try {
      const response = await apiService.updateUsers(dataUser);
      if(response.statusCode === 200){
        setUser(dataUser);
        onClose();
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    setIsLoading(false);

    if(error){
      setIsValidInputEmail(false);
      setIsValidInputPhone(false);
    }
  };

  return (
  	<> 
  	<Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">Edit contact</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text variant="text-md" mb="24px" pt="16px" maxW={{sm: '90%'}}>Changes to user data are made only for a reason given by the user.</Text>

            <FormControl variant="floating">
              <Input type='text' placeholder='' value={dataUser?.email} autoComplete="off" mb='40px' onChange={handleEmail}  className={isValidInputEmail ? 'input-valid' : 'input-invalid'}  />
              <FormLabel className={isValidInputEmail ? 'input-valid' : 'input-invalid'}>Email</FormLabel>
              <FormHelperText className={isValidInputEmail ? 'hidden' : 'input-error' }>Invalid email</FormHelperText>
            </FormControl>

            <FormControl mb='32px'>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                onChange={handlePhone}
                value={dataUser.phone}
                pt='15px'
                type='text'
                placeholder=''
                color='gray.200'
                className={isValidInputPhone ? 'input-valid' : 'input-invalid'}
              />
              <FormHelperText className={isValidInputPhone ?  'hidden' : 'input-error' }>Invalid phone</FormHelperText>
            </FormControl>

            <Divider opacity="1" borderColor='blue.50' borderTopWidth="1px" borderBottomWidth="0" />

            <Text variant="text-md" mb="16px" pt="16px">Do you want to confirm the information update for this user?</Text>

            <Flex mb="24px">
              <Checkbox
                isChecked={confirm}
                onChange={handleConfirm}
              />
              <Text variant="text-md" ml="10px">I intentionally change user information</Text>
            </Flex>

            <Flex justifyContent="flex-end">
              <Button variant="submit-md" maxW="140px" isLoading={isLoading} disabled={!confirm} onClick={handleChange}>
                Confirm
              </Button>
            </Flex>
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default ContactFormModal;
