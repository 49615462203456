// Core
import { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';

// Chakra 
import { 
  Box,
  Flex, 
  FormControl,
  FormLabel,
  Divider,
  Input,
  Image,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Select,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { useHistory, useRouteMatch, Route, Redirect, Switch } from 'react-router-dom';

// Assets
import { apiService } from 'services/ApiService';
import { getInitials } from 'services/Utils';
import { HiDotsVertical } from 'react-icons/hi';

// Components
import User from './components/User';
import UserRow from './components/UserRow';

const Users = () => {
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const [user, setUser] = useState([]);
  const [userMeta, setUserMeta] = useState([]);
  const [offset, setOffset] = useState(0);  
  const [search, setSearch] = useState();  

  const { isLoading: isUserLoading } = useQuery({
    queryKey: ['user', offset, search],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const data = {
        limit: 25, 
        role: 'User',
        offset,
      };

      if(search?.length > 0){
        data['search'] = search;
      }

      const response = await apiService.getUsers(data);
      if(offset === 0){
        setUser(response.data);
      } else {
        setUser([...user, ...response.data]);
      }

      console.log(response.data);
      
      setUserMeta(response.meta);
      return true;
    }
  });

  const loadMore = async () => {
    setOffset(offset + 25);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const onRowClicked = useCallback((period) => {
    history.push(`/users/${period.id}`);
  }, [history]);

  return (
  	<Switch>
	    <Route path={`${path}/:id`} render={() => <User />} />
  	    <Route path={`${path}`} render={() => 
          <UserRow 
            dataUsers={user} 
            handleUserDataOpen={onRowClicked} 
            userMeta={userMeta}
            loadMore={loadMore} 
            handleSearch={handleSearch}
            search={search}
            />
        } />         
    </Switch>
  );
 
};

export default Users;