// Core
import { useState } from 'react';
import { 
  Link, 
  Route, 
  Switch, 
  Redirect, 
  useRouteMatch, 
  useLocation 
} from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';

// Chakra imports
import { 
  Box, 
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  InputGroup,
  InputLeftElement,
  Text,
  Input,
  useColorModeValue
} from '@chakra-ui/react';

// Components
import Memberships from './Membership';
import MembershipsEdit from './MembershipEdit';
import Card from 'components/Card/Card.js';
import { SearchIcon } from 'components/Icons/Icons';

function Membership(props) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  return (
    <>
      <Box>
        <Switch>
          <Route path={`${path}/:id`} render={() => <MembershipsEdit />} />
          <Route path={`${path}`} render={() => <Memberships />} />
          <Redirect from={`${path}*`} to={`${path}`} />
        </Switch>
      </Box>
    </>
  );
}

export default Membership;