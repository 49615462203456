// Core
import { useState, useEffect, useCallback, useMemo } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { useHistory, useParams, useRouteMatch, Route, Redirect, Switch, useLocation, Link } from 'react-router-dom';
import { useQuery } from 'react-query';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Image,
  Input,
  Menu,
  MenuButton,
  Icon,
  IconButton,
  MenuList,
  MenuItem,
  Select,
  SimpleGrid,
  Text, 
  Textarea,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { getInitials, parseDate } from 'services/Utils';

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { EditContactIcon, CheckConfirmIcon, UsersActiveIcon, LockIcon, RoleIcon, MsgIcon, UserEditIcon } from 'components/Icons/Icons';
import Card from 'components/Card/Card';
import BlockUserModal from './modals/BlockUser';
import ChangeRoleModal from './modals/ChangeRole';
import ConfirmRegistrationModal from './modals/ConfirmRegistration';
import ContactFromModal from './modals/ContactForm';
import EditGeneralInfoModal from './modals/EditGeneralInfo';
import SendMsgModal from './modals/SendMsg';

const UserContent = () => {
  const history = useHistory();
  const { id } = useParams();
  const [userErrors, setUserErrors] = useState([]);
  const { path, url } = useRouteMatch();
  const location = useLocation();

  const [user, setUser] = useState([]);

  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'gray.800');
  const dividerColor = useColorModeValue('gray.50', 'rgba(255, 255, 255, 0.10)'); 
  const iconMenuColor = useColorModeValue('blue.75', '#9FABCB');

  const { isOpen: isOpenBlockUser, onOpen: onOpenBlockUser, onClose: onCloseBlockUser } = useDisclosure();
  const { isOpen: isOpenChangeRole, onOpen: onOpenChangeRole, onClose: onCloseChangeRole } = useDisclosure();
  const { isOpen: isOpenConfirmReg, onOpen: onOpenConfirmReg, onClose: onCloseConfirmReg } = useDisclosure();
  const { isOpen: isOpenContactForm, onOpen: onOpenContactForm, onClose: onCloseContactForm } = useDisclosure();
  const { isOpen: isOpenEditInfo, onOpen: onOpenEditInfo, onClose: onCloseEditInfo } = useDisclosure();
  const { isOpen: isOpenSendMsg, onOpen: onOpenSendMsg, onClose: onCloseSendMsg } = useDisclosure();

  const isActive = (routeName) => {
    return location.pathname === routeName ? true : false;
  };

  const { isLoading: isLoadingUser } = useQuery({
    queryKey: [id],
    refetchOnWindowFocus: false,
    refetchOnError: false,
    queryFn: async () => {
      const response = await apiService.getUser({id});
      setUser(response.data);
      return;
    }
  });

  return (
    <>
    { isLoadingUser ? (
        <Text variant="text">Loading...</Text>
      ) : (
    <>
      <Breadcrumbs icon={UsersActiveIcon} target='Users' url='#/users' current='User' variant="standart" />

      <Grid templateColumns={{ sm: '1fr', lg: '1fr 3.05fr' }} templateRows='1fr' gap='8px'>
        <Card padding="16px" mb="8px">
          <Box w={{sm: '100%'}}>
            <Flex w="100%" flexDirection="row" position="relative">
              <Flex h="26px">
                <Text variant="text-md" className="tag-transparent" m="0 auto" p="3px 15px">{user?.gender}</Text>
              </Flex> 
              <Box float="right" position="absolute" right="-14px" top="-9px">
                  <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          color={textColor}
                          icon={<HiDotsVertical fontSize="20px" />}
                          variant="ghost"
                        />
                        <MenuList className="context-menu">
                          <MenuItem onClick={onOpenEditInfo}>
                            <Flex>
                              <UserEditIcon me="10px" mt="-2px" fontSize="24px" color={iconMenuColor}/>
                              Edit general info
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={onOpenConfirmReg} className={user.isConfirm ? 'freeze' : '' }>
                            <Flex>
                              <CheckConfirmIcon me="10px" mt="-2px" fontSize="24px" color={iconMenuColor}/>
                              Confirm registration
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={onOpenContactForm}>
                            <Flex>
                              <EditContactIcon me="10px" mt="-2px" fontSize="24px" color={iconMenuColor}/>
                              Edit contact
                            </Flex>
                          </MenuItem>
                          <Divider opacity='1' borderColor={dividerColor} borderTopWidth="0.05em" borderBottomWidth="0"/>
                          <MenuItem onClick={onOpenBlockUser}>
                            <Flex>
                              <LockIcon me="10px" mt="-2px" fontSize="24px" color={iconMenuColor}/>
                              {user.isBlocked ? 'Unblock' : 'Block'} user
                            </Flex>
                          </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            </Flex>
            <Box textAlign="center">
              <Text variant="text-md" 
                className={
                  user?.role === 'User' ? 'tag-blue' :
                  user?.role === 'Admin' ? 'tag-red' : 'tag-purple'
                } 
                m="16px auto 0 auto">
                {user?.role}
              </Text>
              <Text variant="title" mt="16px">{user?.firstName} {user?.lastName}</Text>
              <Text variant="text-md" mt="2px">+{user?.phone}</Text>
              <Text variant="text-md" mt="2px">{user?.email}</Text>
            </Box>
            <Flex gap="8px" justifyContent="center" alignItems="center" mt="16px">
              <MsgIcon color="#099BFF" onClick={onOpenSendMsg} cursor="pointer" p="11px" w="44px" h="44px" border="1px solid #0D234D" borderRadius="10px"/>
              <RoleIcon color="#099BFF" onClick={onOpenChangeRole} cursor="pointer" p="11px" w="44px" h="44px" border="1px solid #0D234D" borderRadius="10px"/>
            </Flex>
            <Divider opacity="1" mr="2px" borderColor={dividerColor} borderTopWidth="1px" borderBottomWidth="0" mb='24px' mt='24px'/>
            <Text variant="text-md">Date of Birth</Text>
            <Text variant="text-md" color={textColor} fontWeight="500">{parseDate(user?.birthDate) ?? 'Empty'}</Text>
            <Text variant="text-md" mt="8px">Address</Text>
            <Text variant="text-md" color={textColor} fontWeight="500" pb="16px">{user?.address ?? 'Empty'}</Text>
          </Box>
        </Card>
      </Grid>

      { user.id && (
        <>

        <BlockUserModal 
          user={user}
          setUser={setUser}
          isOpen={isOpenBlockUser}
          onOpen={onOpenBlockUser}
          onClose={onCloseBlockUser} />

        <ChangeRoleModal
          user={user}
          setUser={setUser}
          isOpen={isOpenChangeRole}
          onOpen={onOpenChangeRole}
          onClose={onCloseChangeRole} />

        <ConfirmRegistrationModal
          user={user}
          setUser={setUser}
          isOpen={isOpenConfirmReg}
          onOpen={onOpenConfirmReg}
          onClose={onCloseConfirmReg} />

        <ContactFromModal
          user={user}
          setUser={setUser}
          isOpen={isOpenContactForm}
          onOpen={onOpenContactForm}
          onClose={onCloseContactForm} />

        <EditGeneralInfoModal
          user={user}
          setUser={setUser}
          isOpen={isOpenEditInfo}
          onOpen={onOpenEditInfo}
          onClose={onCloseEditInfo} />

        <SendMsgModal
          user={user}
          setUser={setUser}
          isOpen={isOpenSendMsg}
          onOpen={onOpenSendMsg}
          onClose={onCloseSendMsg} />
        </>
      )}
    </>)}</>
  );
};

export default UserContent;