// Core
import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory, useLocation } from 'react-router-dom';

// Assets
import { apiService } from 'services/ApiService';

// Components
import GiveawaysEditContent from './components/GiveawaysEditContent';

const GiveawaysEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [newObjectErrors, setNewObjectErrors] = useState([]);
  const [newObject, setNewObject] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const { isLoading, isError } = useQuery({
    queryKey: [id],
    refetchOnWindowFocus: false,
    refetchOnError: false,
    queryFn: async () => {
      if (id === 'new') {
        setNewObject();
      } else {
        const response = await apiService.getGiveaway({id});
        setNewObject(response.data);
      }
      return true;
    }
  });

  const handleCreateObject = useCallback(async () => {
    if (newObjectErrors.length > 0) {
      return;
    }

    let objectId = newObject.id;
    const data = newObject;
    if (newObject.id) {
      try {
        delete data.image;
        delete data.prizes;
        const response = await apiService.updateGiveaway(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['title']);
          return;
        }
      } catch(e){
        setNewObjectErrors(['title']);
        return;
      }
    } else {
      try {
        delete data.image;
        delete data.prizes;
        const response = await apiService.createGiveaway(data);
        if(response.data === false || response.statusCode !== 200){
          setNewObjectErrors(['title']);
          return;
        }

        objectId = response?.data?.id;
      } catch(e){
        setNewObjectErrors(['title']);
        return;
      }
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('itemType', 'giveaway');
      formData.append('sync', true);
      formData.append('itemId', objectId);

      try {
        const response = await apiService.uploadImage(formData); 
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    setNewObject({});
    history.push('/giveaways');
  }, [history, newObject, newObjectErrors.length]);

  const handleDelete = useCallback(async (give) => {
    //await apiService.deleteGiveaways(give);
    //setNewObject({});
    history.push('/giveaways');
  }, [history]);

  return (isError ? 
    <div>Not Found</div> : isLoading ? 
    <div>Loading...</div> : 
    <GiveawaysEditContent 
      newObject={newObject} 
      newObjectErrors={newObjectErrors} 
      setNewObject={setNewObject} 
      setSelectedFile={setSelectedFile}
      setNewObjectErrors={setNewObjectErrors} 
      handleCreateObject={handleCreateObject} 
      handleDelete={handleDelete}
    />);
};

export default GiveawaysEdit;