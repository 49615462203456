// Core
import { useState } from 'react';
import VerificationInput from 'react-verification-input';

// Chakra 
import { 
  Button,
  Checkbox, 
  Divider,
  Flex, 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Stack,
  Text, 
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

const DeleteProfileModal = ({dataUser, setNotify, seconds, startTimer, timerActive, isOpenDelete, onOpenDelete, onCloseDelete}) => {
  // Chakra color mode
  const textColor = useColorModeValue('gray.800', 'white');
  const [fontSizeTitle, fontSizeTitleWeight] = ['16px', '600'];

  const [isLoading, setIsLoading] = useState(false);
  const [isValidInputCode, setIsValidInputCode] = useState(true);
  const [checkbox, setCheckbox] = useState(false);

  const [step, setStep] = useState(1);

  // Handles 
  const handleCheckbox = () => setCheckbox(!checkbox); 

  const handleChange = async (event) => {
    let error = false;

    try {
      const response = await apiService.deleteUser();
      if(response.statusCode === 200 && response.data.status === true){
        setStep(2);
        setNotify({success: false});
        startTimer(); // stop
        startTimer(); // start
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    if(error === true){
      window.scrollTo(0, 0);
      onCloseDelete();
      setNotify({error: true, msg: 'Sorry, but delete account failed. If the problem persists, please contact support.'});
    }
  };

  const handleCode = value => { 
    if(value?.length === 6){
      handleSubmitChange(value);
    } 

    setIsValidInputCode(true);
  };

  const handleSubmitChange = async (code) => {
    let error = false;

    setIsLoading(true);
    try {
      const response = await apiService.deleteUser({code});
      if(response.statusCode === 200 && response.data.status === true){
        await apiService.logout();
        window.location.href = '/#/auth/signin'; 
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    if(error){
      setIsLoading(false);
      setIsValidInputCode(false);
    }
  };

  return (
  	<> 
  	<Modal isOpen={isOpenDelete} onClose={onCloseDelete}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">
        { step === 1 ? 'Attention' : 'Confirmation' }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
              <Text variant="text-md" mb="16px">Important Notice</Text>
          { step === 1 ? (
              <>
              <Flex justifyContent="flex-end" w="100%" mb="16px">
                <Divider opacity="1" mr="2px" borderColor='blue.200'  borderTopWidth="4px" borderBottomWidth="0"/>
                <Divider opacity="1" borderColor='gray.100'  borderTopWidth="4px" borderBottomWidth="0" />
              </Flex>
              <Text variant="text-md" mb="12px">Are you sure of your choice? After the expiration of the period, the account will be deleted forever.</Text>
  	          <Stack spacing={5} direction='row'>
                  <Checkbox onChange={handleCheckbox}>
                     <Text variant="text-md">I deliberately delete my account</Text>
                  </Checkbox>
              </Stack>
              <Flex justify="space-between" mt='16px'>
  	          	<Text variant="text-md" fontWeight="500" mt="12px">Step: 1/2</Text>
  	          	<Button variant="submit-md" maxW="140px"  disabled={!checkbox} onClick={handleChange}>
  		          Next
  		        </Button>
  	          </Flex>
	          </>
          ) : (
	        <>
	          	<Flex justifyContent="flex-end" w="100%">
	          		<Divider opacity="1" mr="2px" borderColor='blue.200'  borderTopWidth="4px" borderBottomWidth="0"/>
	          		<Divider opacity="1" borderColor='blue.200'   borderTopWidth="4px" borderBottomWidth="0" />
	          	</Flex>
	          	<Text mt="16px" fontSize={fontSizeTitle} color={textColor} fontWeight={fontSizeTitleWeight}>
	          		Verify your code 
	          		{ isLoading && (
	          			<Spinner size="sm" color="blue.200" m="0 0 -2px 8px"/>
	          		)}
	          	</Text>
	          	<Text mt="5px" variant="text-md" mb="-7px">Enter the 6-digit code that was sent by email to confirm account deletion</Text>
	          	<VerificationInput
	              length={6}
	              placeholder=""
	              validChars="0-9"
	              classNames={{
	                container: `code-container ${isValidInputCode ? 'input-valid-code' : 'input-invalid-code'}`,
	                character: `code-character ${isLoading ? 'input-freeze-code' : ''}`,
	                characterInactive: 'character--inactive',
	                characterSelected: 'character--selected',
	              }}
	              onChange={handleCode}
	            />
	            {!isLoading && (
	            	<>
                  <Text mt="5px" variant="text-md" className={!timerActive ? 'hidden' : ''}>Resend via: <Text as="span" variant="text-md" color="blue.200">
                    {Math.floor(seconds / 60)}:{seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                    </Text>
                  </Text>
                  <Flex justify="space-between" mt="32px">
                    <Text variant="text-md" fontWeight="500" mt="12px">Step: 2/2</Text>
                    <Button variant="submit-md" maxW="140px" disabled={timerActive} onClick={handleChange}>
                      Try again
                    </Button>
                  </Flex>
                </>
		          )}
	        </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default DeleteProfileModal;
