// Core
import { HiDotsVertical } from 'react-icons/hi';
import {useEffect, useState, useCallback} from 'react';
import { IoTicketSharp } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Link,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { parseDateTime } from 'services/Utils';
import placeholder from 'assets/img/placeholder.png';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { TicketIcon, AdminsActiveIcon, EditIcon, DeleteIcon } from 'components/Icons/Icons';

const AchievementRow = ({ data, handleEditOpen, handleDelete }) => {

  const borderColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');

  const [switched, setSwitched] = useState(data.hidden);

  const handleHidden = (event) => { 
    setSwitched(switched ? false : true);
  };

  useEffect(() => { 
    if(data && data.hidden !== switched){
      apiService.updateAchievement({...data, hidden: switched});
    }
  }, [switched]);

  return (
    <Card key={data.id} padding="24px">
      <CardBody>
        <Box w="100%">

          <Flex justifyContent="space-between" w="100%">
            <Text variant="text-md" w="100%">{ parseDateTime(data.createdAt) }</Text>
            <Flex justifyContent="space-between" w="100%" gap="12px" maxW="96px">
              <Text variant="title">Active</Text> 
              <Switch
                colorscheme="teal"
                m="2px 0 0 -4px"
                isChecked={!switched}
                onChange={handleHidden} />
            </Flex>
          </Flex>

          <Flex justifyContent="space-between" width="100%" mt="12px">
            <Flex gap="19px">
              <Image 
                src={data?.image ? data.image : placeholder} 
                bg="#0D234D"
                borderRadius="12px"
                objectFit="cover"
                p="12px"
                w="78px" 
                h="78px" />
              <Box mt="-6px">
                <Text 
                  w="100%"
                  whiteSpace="nowrap" 
                  overflow="hidden" 
                  textOverflow="ellipsis"
                  variant="title"
                  fontSize="28px" 
                  fontWeight="700">{data?.title}</Text>
                <Text variant="text-md" fontSize="15px">Cashback: { data?.cashback }</Text>
                <Text variant="text-md" fontSize="15px" mt="4px">Users have: 0</Text>
              </Box>
            </Flex>
          </Flex>

          <Divider opacity="1" my="16px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0"/>

          <Flex justifyContent="space-between" w="100%" gap="8px" mt="14px">
            <Button
              variant="secondary-md" 
              onClick={() => handleDelete(data)}
              leftIcon={<DeleteIcon color="white" fontSize="22px" pl="4px" mr="-4px" />} />

            <Button 
              variant="submit-md" 
              onClick={() => handleEditOpen(data)}
              leftIcon={<EditIcon color="white" fontSize="24px" />}>
              Change
            </Button>
          </Flex>

          
        </Box>
      </CardBody>
    </Card>
  );
};

export default AchievementRow;