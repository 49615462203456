// Core
import { useState } from 'react';
import { Link, Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

// Chakra imports
import { 
  Box, 
  Image,
  Flex, 
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Components
import Card from 'components/Card/Card.js';
import Partners from './components/partners/Partners.js';
import PartnersEdit from './components/partners/PartnersEdit.js';
import Categories from './components/categories/Categories.js';
import Tags from './components/tags/Tags.js';

function Resources(props) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  const textColor = useColorModeValue('blue.800', 'white');

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='12px'>Partners</Text>
      <Box mt="0px" mb="50px" direction='column'>
        <Flex align='left' mb={{ sm: '10px', md: '0px' }}
          direction={{ sm: 'column', md: 'row' }}
          w={{ sm: '100%' }}
          textAlign={{ sm: 'center', md: 'start' }}
          borderBottom="1px"
          borderColor="rgba(255, 255, 255, 0.10)"
          className="settings-tabs">
          <Link to={`${url}`} className={location.pathname === '/partners' ? 'settings-tab-active' : ''}>Partners</Link>
          <Link to={`${url}/categories`} className={isActive(`${path}/categories`) ? 'settings-tab-active' : ''}>Categories</Link>
          <Link to={`${url}/tags`} className={isActive(`${path}/tags`) ? 'settings-tab-active' : ''}>Tags</Link>
        </Flex>
        
        <Box align='left' mt='32px'>
          <Switch>
            <Route path={`${path}/categories`} render={() => <Categories />} />
            <Route path={`${path}/tags`} render={() => <Tags />} />
            <Route path={`${path}/:id`} render={() => <PartnersEdit />} />
            <Route path={`${path}`} render={() => <Partners />} />
            <Redirect from={`${path}*`} to={`${path}`} />
          </Switch>
        </Box>
      </Box>
    </>
  );
}

export default Resources;