// Core
import { useHistory, Link } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Checkbox,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text, 
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';
import partnerLogo from 'assets/img/partner-logo.jpg';
import { ReactComponent as Abstraction } from 'assets/svg/abstraction.svg';
import { ReactComponent as Dasharray } from 'assets/svg/dasharray.svg';
import { ReactComponent as CategoryIcon } from 'assets/svg/partner/category.svg';
import { ReactComponent as StateIcon } from 'assets/svg/partner/state.svg';
import { ReactComponent as SiteIcon } from 'assets/svg/partner/site.svg';
import { ReactComponent as MailIcon } from 'assets/svg/partner/mail.svg';
import { ReactComponent as LocationIcon } from 'assets/svg/partner/location.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/partner/phone.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/partner/link.svg';

const PartnerPreviewContent = ({ newObject }) => {
  const border = '1px solid #252d47';
  const bgIcon = 'rgba(9, 155, 255, 0.10)';

  const LabelItem = ({ ItemIcon, text, link}) => {
    return (
      <Flex gap="16px" mb="10px">
        <Box bg={bgIcon} borderRadius="8px" p="4px" h="32px">
          <ItemIcon />
        </Box>

        <Text position="relative" mt="6px" pb="8px" pr="25px" color="#E1E1E1" w="100%" fontSize="15px" fontWeight="400" borderBottom="1px solid rgba(255, 255, 255, 0.10)">
          {text}
          { link && (
            <LinkIcon style={{ position: 'absolute', top: '-4px', right: '0px'}} />
          )}
        </Text>
      </Flex>
    );
  };

  return (
    <Box overflow="hidden">
      <Image 
        src={newObject.image ? newObject.image : partnerLogo} 
        objectFit="cover"
        borderRadius="16px 16px 0px 0px"
        w="100%" 
        h="232px" />
      <Box
        p="12px 16px 6px 16px"
        borderLeft={border}
        borderRight={border}>
        
        <Box 
          borderRadius="16px"
          padding="8px 16px"
          bg="linear-gradient(180deg, #2ABDFD 0%, #0497FD 100%)"
          boxShadow="0px 4px 12px 0px rgba(0, 122, 255, 0.50)"
          overflow="hidden"
          position="relative">

          <Abstraction style={{position: 'absolute', top: '0'}} />

          <Text 
            lineHeight="21px"
            letterSpacing="-0.4px"
            variant="title">
            {newObject?.discountInfo?.title}
          </Text>
        </Box>
      </Box>

      <Box my="-4px" position="relative" h="46px">
        <Box zIndex="1" bg="#050a19" w="46px" h="46px" borderRadius="full" position="absolute" left="-34px" border={border} />
        <Dasharray style={{position: 'absolute', top: '23px', left: '50%', transform: 'translate(-50%, -50%)'}} />
        <Box zIndex="1" bg="#050a19" w="46px" h="46px" borderRadius="full" position="absolute" right="-34px" border={border} />
      </Box>

      <Box
        overflow="hidden"
        p="0px 16px 12px 16px"
        borderRadius="0 0 16px 16px"
        borderLeft={border}
        borderRight={border}
        borderBottom={border}>

        <Text variant="text" color="#9FABCB">Partner</Text>
        <Text variant="title-lg" fontSize="22px" fontWeight="700" mt="-4px">{newObject?.name}</Text>
        <Text variant="title-lg" fontSize="22px" fontWeight="700" mt="-6px" color="#E1E1E1">{newObject?.discountInfo?.discount}</Text>

        <Divider my="8px" opacity="1" borderColor='rgba(255, 255, 255, 0.10)' borderTopWidth="1px" borderBottomWidth="0" />

        <LabelItem ItemIcon={CategoryIcon} text={newObject?.bindCategories?.map(item => item.name).join(', ') || '-'} link={false} />
        <LabelItem ItemIcon={StateIcon} text={newObject?.state ? 'AU, ' + newObject?.state : '-'} link={false} />

        <Box my="16px">
          { newObject?.bindTags?.map((item) => (
            <Text key={item} class="tag-secondary">{item.name}</Text>
          ))}
        </Box>

        <Text py="4px" mb="12px" variant="text" lineHeight="21px" letterSpacing="-0.4px">{newObject?.description}</Text>

        <LabelItem ItemIcon={SiteIcon} text={newObject?.website} link={true} />
        <LabelItem ItemIcon={MailIcon} text={newObject?.email} link={true} />
        <LabelItem ItemIcon={LocationIcon} text={newObject?.location} link={true} />
        <LabelItem ItemIcon={PhoneIcon} text={newObject?.contact || '-'} link={true} />

      </Box>
    </Box>
  );
};

export default PartnerPreviewContent;