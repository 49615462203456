// Core
import { useState, useEffect } from 'react';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Tr,
  Td,
  Checkbox,
  Textarea,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const EditGeneralInfoModal = ({user, setUser, isOpen, onOpen, onClose}) => {
  const [isValidInput, setIsValidInput] = useState(true);  
  const [text, setText] = useState(); 
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState(false); 
  const [dataUser, setDataUser] = useState(user);

  const handleConfirm = (event) => setConfirm(!confirm);
  const handleText = (event) => {
    setText(event.target.value);
    setIsValidInput(true);
  };
  const handleChange = async () => {
    let error = false;

    setIsLoading(true);

    const data = {...dataUser, isBlocked: dataUser.isBlocked === true ? false : true};

    setUser(data);
    setDataUser(data);

    try {
      const response = await apiService.updateUsers(data);
      if(response.statusCode === 200){
        onClose();
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    setIsLoading(false);

    if(error){
      setIsValidInput(false);
    }
  };

  return (
    <> 
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">{dataUser.isBlocked ? 'UnBlock user' : 'Block user'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text variant="text-md" pt="16px" maxW={{sm: '90%'}}>Important Notice</Text>
            <Text variant="text-md" pt="16px">Indicate to the user the reason for blocking his account and how his account can possibly be unblocked.</Text>
            <Text variant="text-md" mb="24px" pt="16px">This letter will be sent to the user’s email address which he specified during registration.</Text>

            <FormControl variant="floating">
                <Textarea
                  minH={{ base: '100px !important' }} type='text' placeholder='' autoComplete="off" mb='32px' onChange={handleText}  className={isValidInput ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="description" className={isValidInput ? 'input-valid' : 'input-invalid'}>Message</FormLabel>
                <FormHelperText className={isValidInput ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <Divider opacity="1" borderColor='blue.50' borderTopWidth="1px" borderBottomWidth="0" />

            <Text variant="text-md" mb="16px" pt="16px">Confirm {dataUser.isBlocked ? 'un' : ''}blocking this user?</Text>

            <Flex mb="24px">
              <Checkbox
                isChecked={confirm}
                onChange={handleConfirm}
              />
              <Text variant="text-md" ml="10px">I deliberately {dataUser.isBlocked ? 'un' : ''}block a user</Text>
            </Flex>

            <Flex justifyContent="flex-end">
              <Button variant="submit-md" maxW="140px" isLoading={isLoading} disabled={!confirm} onClick={handleChange}>
                {dataUser.isBlocked ? 'Unblock' : 'Block'}
              </Button>
            </Flex>
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default EditGeneralInfoModal;
