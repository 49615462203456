// Core
import { useState, useEffect } from 'react';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text, 
  Table,
  Tbody,
  Select,
  Checkbox, 
  CheckboxGroup, 
  Tr,
  Td,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const ChangeRoleModal = ({user, setUser, isOpen, onOpen, onClose}) => {
  const [isValidInputRole, setIsValidInputRole] = useState(true); 
  const [confirm, setConfirm] = useState(false); 
  const [dataUser, setDataUser] = useState(user);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = (event) => setConfirm(!confirm);
  const handleRole = (event) => setDataUser({...dataUser, role: event.target.value});
  const handleChange = async () => {
    let error = false;

    setIsLoading(true);

    try {
      const response = await apiService.updateUsers(dataUser);
      if(response.statusCode === 200){
        setUser(dataUser);
        onClose();
      } else {
        error = true;
      }
    } catch (e) { error = true; }

    setIsLoading(false);

    if(error){
      setIsValidInputRole(false);
    }
  };
  
  return (
  	<> 
  	<Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal__simple" maxW={{ sm: '472px' }}>
        <ModalHeader display="flex" alignItems="left" justifyContent="left">Change role</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text variant="text-md" mb="24px" pt="16px" maxW={{sm: '90%'}}>Assign a role to the Rogers Rosie</Text>

            <FormControl variant="floating"  w="100%" pb='0px' mb="32px">
              <Select onChange={handleRole} value={dataUser?.role} placeholder="Role" pb='0px'>
                <option value='User'>User</option>
                <option value='Admin'>Admin</option>
                <option value='Manager'>Manager</option>
              </Select>
            </FormControl>

            <Divider opacity="1" borderColor='blue.50' borderTopWidth="1px" borderBottomWidth="0" />

            <Text variant="text-md" mb="16px" pt="16px">Confirm role update for this user?</Text>

            <Flex mb="24px">
              <Checkbox
                isChecked={confirm}
                onChange={handleConfirm}
              />
              <Text variant="text-md" ml="10px">I deliberately block a user</Text>
            </Flex>

            <Flex justifyContent="flex-end">
              <Button variant="submit-md" maxW="140px" isLoading={isLoading} disabled={!confirm} onClick={handleChange}>
                Confirm
              </Button>
            </Flex>
        </ModalBody>
      </ModalContent>
    </Modal> 
    </>
  );
};

export default ChangeRoleModal;
