// Core
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMinutes, setMinutes, setHours } from 'date-fns';
import { useHistory } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Checkbox,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text, 
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';
import placeholder from 'assets/img/placeholder.png';

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import { AchievementActiveIcon } from 'components/Icons/Icons';
import ButtonFilter from 'components/ButtonFilter';

const AchievementEditContent = ({ 
  newObject, newObjectErrors, setSelectedFile, objects, setNewObject, setNewObjectErrors, handleDeleteObject, handleCreateObject
}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'gray.800');
  const paddingTd = '24px 24px 32px 24px';
  const paddingTdRight = '24px 24px 32px 0px';
  const borderColor = useColorModeValue('white', 'white');

  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/achievements');
  }, [history, setNewObject]);

  const isValidInputTitle = useMemo(() => !newObjectErrors.includes('title'), [newObjectErrors]);
  const isValidInputDescription = useMemo(() => !newObjectErrors.includes('description'), [newObjectErrors]);
  const isValidInputSlug = useMemo(() => !newObjectErrors.includes('slug'), [newObjectErrors]);
  const isValidInputDelay = useMemo(() => !newObjectErrors.includes('delay'), [newObjectErrors]);
  const isValidInputPoints = useMemo(() => !newObjectErrors.includes('points'), [newObjectErrors]);
  const isValidInputPointCashback = useMemo(() => !newObjectErrors.includes('pointCashback'), [newObjectErrors]);
  const isValidInputCashback = useMemo(() => !newObjectErrors.includes('cashback'), [newObjectErrors]);
  const isValidInputOrder = useMemo(() => !newObjectErrors.includes('order'), [newObjectErrors]);
  const isValidInputType = useMemo(() => !newObjectErrors.includes('type'), [newObjectErrors]);

  const handleTitle = useCallback((e) => { setData(1, e.target.value, 'title'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleDescription = useCallback((e) => { setData(4, e.target.value, 'description'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleSlug = useCallback((e) => { setData(2, e.target.value, 'slug'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleDelay = useCallback((e) => { setData(0, e.target.value, 'delay'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handlePoints = useCallback((e) => { setData(0, e.target.value, 'points'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handlePointCashback = useCallback((e) => { setData(0, e.target.value, 'pointCashback'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleCashback = useCallback((e) => { setData(0, e.target.value, 'cashback'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleOrder = useCallback((e) => { setData(0, e.target.value, 'order'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleType = useCallback((e) => { setData(4, e.target.value, 'type'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  
  const handleLoop = useCallback((e) => { setNewObject({...newObject, loop: !newObject.loop }); }, [newObject, setNewObject]);
  const handlePointReset = useCallback((e) => { setNewObject({...newObject, pointReset: !newObject.pointReset }); }, [newObject, setNewObject]);
  const handleHiddenChange = useCallback((e) => { setNewObject({...newObject, hidden: !newObject.hidden }); }, [newObject, setNewObject]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleClickImage = () => {
    document.getElementById('fileInput').click(); 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, image: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  const [selectedPointCashback, setSelectedPointCashback] = useState(false);
  const handleSelectedPointCashback = () => {
    setSelectedPointCashback(!selectedPointCashback);
  };

  const styleDelay = {
    pointerEvents: 'none',
    opacity: '0.3'
  };

  return (
    <Box zIndex="9" position="relative" mt="133px">
      {
        newObject.id ? (
          <Breadcrumbs icon={AchievementActiveIcon} target='Achievement' url='#/achievements' current='Achievement' />
        ) : (
          <Breadcrumbs icon={AchievementActiveIcon} target='Achievement' url='#/achievements' current='New Achievement' />
        )
      }

      <Flex gap="16px" position="absolute" mt="-92px" right="0">
        {!newObject.id && <Button variant="ghost-md" maxW="120px" onClick={() => handleCancelClick()}>Cancel</Button>}
        {newObject.id && <Button variant="submit-md-delete" disabled w="120px" onClick={() => handleDeleteObject(newObject)}>Delete</Button>}
        <Button  variant="submit-md" w="120px" onClick={handleCreateObject}>{newObject.id ? 'Confirm' : 'Create'}</Button>
      </Flex>

      <Card padding="24px" mb="8px" maxW="839px">
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" w={{sm: '5%'}} border="unset" p="0 0 0 0">
                <Text variant="title">Active</Text>
              </Td>
              <Td verticalAlign="top" border="unset" p="0 0 0 0">
                <Switch 
                  float='right' 
                  id='email-alerts' 
                  isChecked={!newObject.hidden} 
                  onChange={handleHiddenChange} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>

      <Card padding="24px" mb="40px" maxW="839px">
        <Flex gap="24px">
          <Image 
            src={newObject.image ? newObject.image : placeholder} 
            borderRadius="10px"
            objectFit="cover"
            bg="#0D234D"
            p="12px"
            w="107px" 
            h="107px" />
          <Box>
            <Text 
              variant="title" 
              color="#099BFF" 
              cursor="pointer" 
              onClick={handleClickImage}>
              { newObject.image ? 'Change picture' : 'Add picture' }
            </Text>

            <Flex gap="3px" mt="8px">
              <Text variant="text-md">Maximum size:</Text>
              <Text variant="text-md" fontWeight="500">5 mb</Text>  
            </Flex>

            <Flex gap="3px">
              <Text variant="text-md">Supporting Image Type:</Text>
              <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
            </Flex>
            
          </Box>
        </Flex>

        <input
          id="fileInput"
          type="file"
          accept=".jpg,.jpeg,.png"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </Card>

      <Text variant="title-lg" mb="12px">Details</Text>
      <Card padding="0" maxW="839px" overflow="hidden">
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Title</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="title">
                  <Input value={newObject?.title} type='text' placeholder='' autoComplete="off" onChange={handleTitle}  className={isValidInputTitle ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="title" className={isValidInputTitle ? 'input-valid' : 'input-invalid'}>Title</FormLabel>
                  <FormHelperText className={isValidInputTitle ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Description</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="description">
                  <Textarea resize='none' height='132px' maxLength='100' value={newObject.description} type='text' placeholder='' autoComplete="off" onChange={handleDescription}  className={isValidInputDescription ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="description" className={isValidInputDescription ? 'input-valid' : 'input-invalid'}>Description</FormLabel>
                  <FormHelperText className={isValidInputDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>

      <Text mt="40px" variant="title-lg" mb="12px">Prize</Text>
      <Card padding="0" maxW="839px" overflow="hidden">
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} border="unset">
                <Text variant="title">Cashback</Text>
              </Td>

              <Td verticalAlign="top" p={paddingTd} border="unset" >
                <FormControl variant="floating" id="cashback" mb="-5px" display={ selectedPointCashback ? 'none': 'block' }>
                  <Input value={newObject?.cashback} type='number' placeholder='' autoComplete="off" onChange={handleCashback}  className={isValidInputCashback ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="cashback" className={isValidInputCashback ? 'input-valid' : 'input-invalid'}>Cashback</FormLabel>
                  <FormHelperText className={isValidInputCashback ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>

                <FormControl variant="floating" id="pointCashback" mb="-5px" display={ selectedPointCashback ? 'block': 'none' }>
                  <Input value={newObject?.pointCashback} type='number' placeholder='' autoComplete="off" onChange={handlePointCashback}  className={isValidInputPointCashback ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="pointCashback" className={isValidInputPointCashback ? 'input-valid' : 'input-invalid'}>Cashback (by point)</FormLabel>
                  <FormHelperText className={isValidInputPointCashback ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}} border="unset">
                <Text variant="title">Receiving</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd} border="unset" w="100%"> 
                <ButtonFilter
                  buttons={[
                    { key: 'false', name: 'For each point' },
                    { key: 'true', name: 'At the very end' },
                  ]}
                  selected={selectedPointCashback ? 'true' : 'false'}
                  onSelect={handleSelectedPointCashback}
                  w="100%"
                />
              </Td>
              
            </Tr>
          </Tbody>
        </Table>
      </Card>

      <Text mt="40px" variant="title-lg" mb="12px">Settings</Text>
      <Card padding="0" maxW="839px" overflow="hidden">
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}><Text variant="title">Loop</Text></Td>
              <Td verticalAlign="top" p={paddingTd}><Switch isChecked={newObject?.loop} onChange={handleLoop} /></Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Points</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="points">
                  <Input value={newObject?.points} type='number' placeholder='' autoComplete="off" onChange={handlePoints}  className={isValidInputPoints ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="points" className={isValidInputPoints ? 'input-valid' : 'input-invalid'}>Points</FormLabel>
                  <FormHelperText className={isValidInputPoints ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Delay</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <Flex gap="40px">
                  <FormControl w="50%" variant="floating" id="delay">
                    <Input value={newObject?.delay} type='number' placeholder='' autoComplete="off" onChange={handleDelay}  className={isValidInputDelay ? 'input-valid' : 'input-invalid'}  />
                    <FormLabel htmlFor="delay" className={isValidInputDelay ? 'input-valid' : 'input-invalid'}>Delay (in hour)</FormLabel>
                    <FormHelperText className={isValidInputDelay ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                  </FormControl>

                  <Flex mt="16px" w="50%" gap="16px" style={ newObject?.delay > 0 ? {} : styleDelay}>
                    <Text variant="title">Point Reset</Text>
                    <Switch mt="2px" isChecked={newObject?.pointReset} onChange={handlePointReset} />
                  </Flex>
                </Flex>
              </Td>

            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Order</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="order">
                  <Input value={newObject?.order} type='number' placeholder='' autoComplete="off" onChange={handleOrder}  className={isValidInputOrder ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="order" className={isValidInputOrder ? 'input-valid' : 'input-invalid'}>Order</FormLabel>
                  <FormHelperText className={isValidInputOrder ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Slug</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="slug">
                  <Input disabled value={newObject?.slug} type='text' placeholder='' autoComplete="off" onChange={handleSlug}  className={isValidInputSlug ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="slug" className={isValidInputSlug ? 'input-valid' : 'input-invalid'}>Slug</FormLabel>
                  <FormHelperText className={isValidInputSlug ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>

            <Tr>
              <Td verticalAlign="top" p={paddingTd} w={{sm: '30%'}}>
                <Text variant="title">Type</Text>
              </Td>
              <Td verticalAlign="top" p={paddingTd}>
                <FormControl variant="floating" id="type">
                  <Select disabled placeholder="Type" value={newObject.type} onChange={handleType} className={isValidInputType ? 'input-valid' : 'input-invalid'}>
                    <option value="dailyRewards">Daily Rewards</option>
                    <option value="regular">Regular</option>
                  </Select>
                  <FormLabel value={newObject.type} htmlFor="type" className={isValidInputType ? 'input-valid' : 'input-invalid'}>Type</FormLabel>
                  <FormHelperText className={isValidInputType ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
                </FormControl>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>

    </Box>
  );
};

export default AchievementEditContent;